import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  followUpPermissions: [],
  commentData: {},
  commentStatus: "idle",
  overviewData: {},
  overviewStatus: "idle",
};
const cache = {};

export const getFollowUpCommentData = createAsyncThunk(
  "followUp/getFollowUpCommentData",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `admin/members/profile/followup/comments?member_id=${params.user_id}&page_size=10&page=${params?.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFollowUpOverviewData = createAsyncThunk(
  "followUp/getFollowUpOverviewData",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `admin/members/profile/followup/overview?member_id=${params.user_id}&page_size=10&page=${params?.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const profileFollowupSlice = createSlice({
  name: "ProfileFollowup",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getFollowUpCommentData.pending]: (state, action) => {
      state.commentStatus = "pending";
    },
    [getFollowUpCommentData.fulfilled]: (state, action) => {
      state.followUpPermissions = action?.payload?.data?.data?.permission;
      if (
        state.commentData?.[action.meta?.arg?.user_id] &&
        !action.meta?.arg?.clearState
      ) {
        if (action.payload?.data?.data?.data) {
          state.commentData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data,
            data: [
              ...state.commentData[action.meta?.arg?.user_id].data,
              ...action.payload?.data?.data?.data,
            ],
          };
        } else {
          state.commentData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data,
            data: [...state.commentData[action.meta?.arg?.user_id].data],
          };
        }
      } else {
        state.commentData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          page: 1,
        };
      }
      state.commentStatus = "fulfilled";
    },
    [getFollowUpCommentData.rejected]: (state, action) => {
      state.commentStatus = "failed";
    },

    [getFollowUpOverviewData.pending]: (state, action) => {
      state.overviewStatus = "pending";
    },
    [getFollowUpOverviewData.fulfilled]: (state, action) => {
      state.followUpPermissions = action?.payload?.data?.data?.permission;
      if (
        state.overviewData?.[action.meta?.arg?.user_id] &&
        !action.meta?.arg?.clearState
      ) {
        if (action.payload?.data?.data?.data) {
          state.overviewData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data,
            data: [
              ...state.overviewData[action.meta?.arg?.user_id].data,
              ...action.payload?.data?.data?.data,
            ],
          };
        } else {
          state.overviewData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data,
            data: [...state.overviewData[action.meta?.arg?.user_id].data],
          };
        }
      } else {
        state.overviewData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          page: 1,
        };
      }
      state.overviewStatus = "fulfilled";
    },
    [getFollowUpOverviewData.rejected]: (state, action) => {
      state.overviewStatus = "failed";
    },
  },
});

export const { updateConfig } = profileFollowupSlice.actions;

export default profileFollowupSlice.reducer;
