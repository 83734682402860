import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllStaffListQuery,
  useGetMembersListDataQuery,
  useUpdateMemberStaffAssignDataMutation,
  useUpdateMembersListDataMutation,
} from "../../../store/queries/members";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Users/membersSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateFormData } from "../../../store/slices/Users/createMemberSlice";
import { useEffect } from "react";
import { useGetOverviewDataQuery } from "../../../store/queries/Profile";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { addDays } from "date-fns";
import {
  Image,
  SelectWithIcons,
} from "@wac-ui-dashboard/wac_component_library";
import { toast } from "react-toastify";
import { getExportedData } from "./api";
import moment from "moment";
import Style from "./members.module.scss";

const useMembers = ({ dashboard }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewStaffModal, setViewStaffModal] = useState(false);
  const [staffData, setStaffData] = useState({});
  const [initFollowUp, setInitFollowUp] = useState(false);
  const currentLoginId = localStorage.getItem("USER_DETAILS");
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const membersState = useSelector((state) => state.members);
  const { showCreateModal } = useSelector((state) => state.members);
  const { showEditModal } = useSelector((state) => state.global);
  const { isEditProfile, selectedUser } = useSelector(
    (state) => state.createMembers
  );

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [updateMemberFields] = useUpdateMembersListDataMutation();
  const [updateStaffAssign] = useUpdateMemberStaffAssignDataMutation();

  const {
    data: members = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetMembersListDataQuery({
    page_size: membersState.currentPageSize,
    page: membersState.currentPage,
    filter: membersState.currentFilter,
    sort_by: membersState.sortBy,
    sort_order: membersState.sortOrder,
    start: membersState.startDate,
    end: membersState.endDate,
    search: membersState.search,
  });

  const { data: profileData, isSuccess: isUpdateDataSuccess } =
    useGetOverviewDataQuery(
      {
        user_id: selectedUser,
      },
      { skip: isEditProfile === false }
    );

  const [formattedStaffs, setFormattedStaffs] = useState([]);

  const { data: staffList } = useGetAllStaffListQuery({ type: "1,2" });

  useEffect(() => {
    var temp = [];
    staffList?.data?.map((element) => {
      temp.push({
        value: element._id,
        label: element.value.name,
        icon: (
          <img
            src={element.value.image_url}
            alt={""}
            width={"500"}
            height={"600"}
          ></img>
        ),
      });
      return element;
    });
    setFormattedStaffs(temp);
  }, [staffList]);

  useEffect(() => {
    if (membersState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [membersState.clearSelection]);

  useEffect(() => {
    if (isEditProfile && isUpdateDataSuccess) {
      dispatch(
        updateFormData((state) => {
          state.currentFormData = {
            ...profileData?.data?.data,
            user_id: selectedUser,
          };
        })
      );
    }
    // return () => {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.currentPage = 1;
    //       state.currentFilter = "all";
    //       state.currentPageSize = 10;
    //       state.startDate = null;
    //       state.endDate = null;
    //       state.sortBy = "";
    //       state.sortOrder = "";
    //       state.search = "";
    //       state.showCreateModal = false;
    //     })
    //   );
    // };
    // eslint-disable-next-line
  }, [isEditProfile, isUpdateDataSuccess, profileData]);

  const hasCreatePermission = useMemo(() => {
    let permission = members?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [members]);

  const hasAssignPermission = useMemo(() => {
    let permission = members?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_assign")
    );

    return permission?.[0]?.can_assign ?? 0;
  }, [members]);

  const hasViewPermission = useMemo(() => {
    let permission = members?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [members]);

  const hasFollowUpPermission = useMemo(() => {
    let permission = members?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_follow_up")
    );

    return permission?.[0]?.["can_follow_up"];
  }, [members]);

  const hasEditPermission = useMemo(() => {
    let permission = members?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [members]);

  const hasExportPermission = useMemo(() => {
    let permission = members?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [members]);

  const filters = useMemo(() => {
    if (members?.data?.filters) {
      const buttonGroups = members?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.startDate = null;
          state.endDate = null;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleSort = (label) => {
    if (membersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = membersState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.phoneVerified = {
          verified: false,
          status: false,
        };
      })
    );

    if (isEditProfile) {
      dispatch(
        updateFormData((state) => {
          state.currentFormData = {};
          state.activeTab = "Basic";
          state.isEditProfile = false;
          state.completedTabs = {
            ...state.completedTabs,
            Basic: false,
            Family: false,
            Job: false,
            Edu: false,
            Contact: false,
          };
          state.CompletedPercentage = {
            ...state.CompletedPercentage,
            Basic: 0,
            Family: 0,
            Job: 0,
            Edu: 0,
            Contact: 0,
          };
        })
      );
    }
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditProfile) {
      dispatch(
        updateFormData((state) => {
          state.currentFormData = {};
          state.activeTab = "Basic";
          state.isEditProfile = false;
          state.completedTabs = {
            ...state.completedTabs,
            Basic: false,
            Family: false,
            Job: false,
            Edu: false,
            Contact: false,
          };
          state.CompletedPercentage = {
            ...state.CompletedPercentage,
            Basic: 0,
            Family: 0,
            Job: 0,
            Edu: 0,
            Contact: 0,
          };
        })
      );
    }
  };

  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };
  const handleEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Basic";
        state.isEditProfile = true;
        state.selectedUser = id?.[0];
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleStaffChange = (staff, data) => {
    // let filteredStaffs = formattedStaffs.filter(
    //   (data) => data?.value !== staff?.value
    // );
    // setFormattedStaffs(filteredStaffs);
    dispatch(
      updateConfig((state) => {
        state.IDs = { memberID: data?._id, staffID: staff?.value };
      })
    );
    let obj = {
      member_id: data?._id,
      staff_id: staff?.value,
    };

    updateStaffAssign(obj).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Successfully Assigned");
      } else if (response?.error?.data?.status_code === 400) {
        dispatch(
          updateConfig((state) => {
            state.showTransferModal = true;
            state.transferNote = "";
          })
        );
      } else {
        toast.error("Something went Wrong");
      }
    });
  };


  const handleHideViewStaff = (data) => {
    setTimeout(() => {
      setViewStaffModal(false);
      setStaffData({});
    }, 2000);
  };
  const closeViewStaffModal = (data) => {
    setViewStaffModal(false);
    setStaffData({});
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({});

  const handleViewTooltip = (e, data) => {
    setStaffData(data?.staff);
    setShowTooltip(true);
    const pageWidth = window.innerWidth / 1.5;
    const pageHeight = window.innerHeight / 1.5;
    const currectElement = e?.currentTarget?.getBoundingClientRect();
    const mousePostionX = currectElement?.left;
    const mousePostionY = currectElement?.top;
    setTooltipPosition({
      x: mousePostionX,
      y: mousePostionY,
      placementY: mousePostionY <= pageHeight ? "bottom" : "top",
      placementX: mousePostionX <= pageWidth ? "left" : "right",
    });
  };

  const handleHideTooltip = () => {
    setTooltipPosition({});
    setShowTooltip(false);
    setStaffData({});
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) => (
        <div
          className={`pro-badge  ${
            data[feild] === "Active"
              ? "badge-grey-outline"
              : "badge-danger-outline"
          }`}
        >
          {data[feild]}
        </div>
      ),
      staff_id: (feild, data) => (
        <p className="pro-mb-0">
          {(membersState.currentFilter === "branch_members" ||
            members?.data?.is_admin ||
            data?.staff?.name === "Unassigned") &&
          hasAssignPermission === 1 ? (
            <SelectWithIcons
              menuPlacement="auto"
              menuPosition="auto"
              options={formattedStaffs?.filter(
                (item) =>
                  item?.value !== data?.staff?._id &&
                  item?.value !== currentLoginId
              )}
              multiSelect={false}
              onChange={(staff) => handleStaffChange(staff, data)}
              name="select_staff"
              placeholder="Unassigned"
              value={data?.staff}
              getOptionLabel={(e) => (
                <div className={`pro-d-flex ${Style.avatar_container}`}>
                  <div
                    className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
                    onMouseEnter={
                      !dashboard ? (e) => handleViewTooltip(e, data) : () => {}
                    }
                    onMouseLeave={
                      !dashboard ? () => handleHideTooltip() : () => {}
                    }
                  >
                    <Image
                      src={e?.image_url}
                      width={16}
                      height={16}
                      alt={e?.name}
                    />
                  </div>
                  <span className="pro-ms-1 pro-align-self-center">
                    {e.name}
                  </span>
                </div>
              )}
              getOptionValue={(option) => `${option._id}`}
            />
          ) : (
            <>
              <div className={`pro-d-flex ${Style.avatar_container}`}>
                <div
                  className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
                  onMouseEnter={
                    !dashboard ? (e) => handleViewTooltip(e, data) : () => {}
                  }
                  onMouseLeave={
                    !dashboard ? () => handleHideTooltip() : () => {}
                  }
                >
                  <Image
                    src={data?.staff?.image_url}
                    width={16}
                    height={16}
                    alt={data?.staff?.name}
                  />
                </div>
                <span className="pro-ms-1 pro-align-self-center">
                  {data?.staff?.name}
                </span>
              </div>
            </>
          )}

          {/* {data[feild]?.name ?? "Assign staff"} */}
        </p>
      ),
      name: (_, data) => (
        <ProfileNavigation
          userId={data?._id}
          fieldValue={data?.name}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={
            hasViewPermission === 1 && !dashboard ? true : false
          }
        />
      ),

      follow_up: (_, data) => (
        <div className="pro-d-flex pro-justify-between pro-gap-2">
          <div
            className={`pro-badge pro-align-self-center  ${
              data[feild] !== "Due" ? "" : "badge-danger-outline"
            }`}
          >
            {data[feild]}
          </div>

          {hasFollowUpPermission === 1 &&
          !dashboard &&
          data?.follow_up === "Due" &&
          data?.status !== "Blocked" &&
          data?.status !== "Deleted" ? (
            <button
              onClick={() => {
                setInitFollowUp(true);
                dispatch(
                  updateConfig((state) => {
                    state.initiate_member_id = data?._id;
                  })
                );
              }}
              className={"pro-btn pro-btn-primary min btn btn-primary"}
            >
              Initiate
            </button>
          ) : (
            <></>
          )}
        </div>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.startDate = null;
        state.endDate = null;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(members?.data?.fields),
        filter: membersState.currentFilter,
        sort_by: membersState.sortBy,
        sort_order: membersState.sortOrder,
        start: membersState.startDate,
        end: membersState.endDate,
        search: membersState.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleTransferClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showTransferModal = false;
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/users/");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter;
          state.currentPage = 1;
        })
      );
    }, 500);
  };
  return {
    members,
    membersState,
    filters,
    navigate,
    currentPage: membersState.currentPage,
    activeFilter: membersState.currentFilter,
    showCreateModal,
    showEditModal,
    hasCreatePermission,
    hasExportPermission,
    paginationOptions,
    isFetching,
    isLoading,
    isUpdateDataSuccess,
    selectedUser,
    viewStaffModal,
    staffData,
    isEditProfile,
    actionOptions,
    handleActionChange,
    handleDashboardRedirect,
    handleEditClick,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleDateChange,
    handleSort,
    handleSearch,
    handleCreateClick,
    handleHideViewStaff,
    handleEditModal,
    closeEditModal,
    closeModal,
    getRow,
    updateMemberFields,
    closeViewStaffModal,
    refetch,
    hasViewPermission,
    hasEditPermission,
    initFollowUp,
    setInitFollowUp,
    handleClearClick,
    handleTransferClose,
    date,
    showTooltip,
    tooltipPosition,
  };
};

export default useMembers;
