import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../../store/slices/Users/createMemberSlice";
import {
  emailRegExp,
  numRegExp,
  phoneRegExp,
} from "../../../../../utils/functions/table";
import { updateConfig } from "../../../../../store/slices/Users/membersSlice";
import { toast } from "react-toastify";
import {
  profile,
  useUpdateProfileDetailsMutation,
} from "../../../../../store/queries/Profile";
import countryData from "../../../../../utils/components/countryCode";
import {
  members,
  useGenerateOtpMutation,
  useValidateOtpMutation,
} from "../../../../../store/queries/members";

const useBasic = ({
  formData,
  profileRefetch,
  isEditProfile,
  isOptionDataSuccess,
  isUpdateDataSuccess,
  selectedUser,
}) => {
  const dobRef = useRef(" ");
  const parishRef = useRef(" ");
  const dioceseRef = useRef(" ");
  const profilefileInputRef = useRef(null);
  // const [imagePreview, setImagePreview] = useState([]);
  const [showPassword, setShowPassword] = useState(true);
  const [otp, setOtp] = useState("");
  const [sendRequest] = useUpdateProfileDetailsMutation();
  const [verifyPhone] = useGenerateOtpMutation();
  const [validatePhone] = useValidateOtpMutation();
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const { currentFormData, phoneVerified, backendErrors, imagePreview } =
    useSelector((state) => state.createMembers);

  const dispatch = useDispatch();

  const passwordValidation = Yup.string().min(
    8,
    "Password is too short - should be 8 chars minimum."
  );

  if (!isEditProfile) {
    passwordValidation.required("*Required");
  }

  let createMemberBasicVal = Yup.object({
    first_name: Yup.string()
      .min(2, "The first name must be at least 2 characters")
      .matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/, "Invalid name")
      .required("*Required"),
    last_name: Yup.string().required("*Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(14, "The phone number must be between 7 and 14 digits")
      .required("*Required"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    gender: Yup.object().required("*Required"),
    dob: Yup.string().required("*Required"),
    community_id: Yup.object().required("*Required"),
    diocese_id: Yup.object().required("*Required"),
    password: passwordValidation,
    no_of_children: Yup.string().matches(
      numRegExp,
      "Number of childrens must be an number."
    ),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      country_code: "+91",
      phone_number: "",
      email: "",
      password: "",
      gender: "",
      profile_images: "",
      community_id: "",
      diocese_id: "",
      parish_id: "",
      dob: "",
      no_of_children: "",
      physical_status: "",
      marital_status: "",
      mother_tongue: "",
      body_type: "",
      body_complexion: "",
      weight: "",
      height: "",
      about_me: "",
    },

    validationSchema: createMemberBasicVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          user_id: currentFormData?.user_id,
          ...values,
          profile_image_primary: activeImageIndex,
        };

        let editObj = {
          user_id: currentFormData?.user_id,
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,
          country_code: values.country_code,
          email: values.email,
          password: values.password,
          gender: values.gender?.id,
          dob: values.dob,
          community_id: values.community_id?._id,
          diocese_id: values.diocese_id?._id,
          parish_id: values.parish_id?._id,
          physical_status: values.physical_status?.id,
          weight: values.weight?._id,
          height: values.height?._id,
          no_of_children:
            values.no_of_children === "" ? 0 : values.no_of_children,
          marital_status: values.marital_status?.id,
          mother_tongue: values.mother_tongue?._id,
          body_type: values.body_type?.id,
          body_complexion: values.body_complexion?.id,
          about_me: values.about_me,
          profile_images: values.profile_images,
          profile_image_primary: activeImageIndex,
        };

        let newObj = Object.entries(editObj);

        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

        if (isEditProfile) {
          let formData = new FormData();
          formData.append("_method", "PUT");
          delete newObj.profile_image_primary;
          Object.keys(newObj).forEach((key) => {
            if (key !== "profile_images") {
              return formData.append(key, newObj[key]);
            }
          });

          if (values.profile_images?.[0]?.url) {
            delete newObj.profile_images;
          }

          sendRequest(formData).then((response) => {
            dispatch(
              updateFormData((state) => {
                state.backendErrors = "";
              })
            );

            if (response?.data?.status_code === 200) {
              resetForm();

              dispatch(
                updateFormData((state) => {
                  state.currentFormData = {};
                  state.isEditProfile = false;
                  state.activeTab = "Basic";
                  state.completedTabs = {
                    ...state.completedTabs,
                    Basic: false,
                    Family: false,
                    Job: false,
                    Edu: false,
                    Contact: false,
                  };
                  state.CompletedPercentage = {
                    ...state.CompletedPercentage,
                    Basic: 0,
                    Family: 0,
                    Job: 0,
                    Edu: 0,
                    Contact: 0,
                  };
                })
              );

              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.clearSelection = true;
                })
              );
              dispatch(members.util.invalidateTags(["Members", "Incomplete"]));
              dispatch(profile.util.invalidateTags(["Overview"]));

              toast.success("Successfully updated");
            } else if (response?.data?.status_code === 400) {
              // formik.setErrors(response?.data?.message);
              dispatch(
                updateFormData((state) => {
                  // formik.setErrors(response?.data?.message);
                  state.backendErrors = response?.data?.message;
                })
              );
            }
          });
        } else {
          if (phoneVerified?.verified) {
            dispatch(
              updateFormData((state) => {
                delete newObj.user_id;
                state.currentFormData = { ...state.currentFormData, ...obj };
                state.activeTab = "Family";
                state.completedTabs = { ...state.completedTabs, Basic: true };
              })
            );
          } else {
            formik.setFieldError("phone_number", "Verify Phone Number");
            toast.error("Verify Phone Number");
          }
        }
      } catch (error) {
        toast.error("Failed to  submit");
      }
    },
  });

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(
          key,
          currentFormData?.[key] || currentFormData?.[key]?._id
        );
      });
    }

    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormData]);

  useEffect(() => {
    if (backendErrors) {
      formik.setErrors(backendErrors);
    }
    // eslint-disable-next-line
  }, [backendErrors]);

  useEffect(() => {
    let totalFields = Object.values(formik.initialValues).length;

    const filterDataFromTab = (fullObject, tabObject) => {
      const filteredData = {};

      for (const key in tabObject) {
        if (fullObject.hasOwnProperty(key)) {
          filteredData[key] = fullObject[key];
        }
      }

      return filteredData;
    };

    const filteredData = filterDataFromTab(formik.values, formik.initialValues);

    const countNonEmptyKeys = (data) => {
      let count = 0;
      for (let key in data) {
        if (
          data.hasOwnProperty(key) &&
          data[key] !== null &&
          data[key] !== ""
        ) {
          count++;
        }
      }
      return count;
    };

    let numberOfNonEmptyKeys = countNonEmptyKeys(filteredData);

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: Math.ceil((numberOfNonEmptyKeys / totalFields) * 100),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.currentFormData = {};
        state.activeTab = "Basic";
        state.isEditProfile = false;
        state.phoneVerified = {
          verified: false,
          status: false,
        };
        state.completedTabs = {
          ...state.completedTabs,
          Basic: false,
          Family: false,
          Job: false,
          Edu: false,
          Contact: false,
        };
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Family: 0,
          Job: 0,
          Edu: 0,
          Contact: 0,
        };
      })
    );
  };

  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 1) {
      formik.setFieldValue("profile_images", e?.target?.files);

      const readerArray = [];
      const previewArray = [];

      Array.from(files).forEach((file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          previewArray.push(reader.result);
          if (previewArray.length === files.length) {
            dispatch(
              updateFormData((state) => {
                state.imagePreview = previewArray;
              })
            );
          }
        };

        readerArray.push(reader);
        reader.readAsDataURL(file);
      });

      dispatch(
        updateFormData((state) => {
          state.imagePreview = files;
        })
      );
    } else {
      toast.error("The image must be less than 5MB in size.");

      // formik.setFieldValue("profile_images", "");
      formik.setFieldError(
        "profile_images",
        "The image must be less than 5MB in size."
      );
    }
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }

    return "";
  };

  // const getFieldError = (fieldName) => {
  //   if (backendErrors && backendErrors[fieldName]) {
  //     return backendErrors[fieldName];
  //   }
  //   if (formik.touched[fieldName] && formik.errors[fieldName]) {
  //     return formik.errors[fieldName];
  //   }
  //   return "";
  // };
  const cuntryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );

  const handleThumbClick = (index) => {
    setActiveImageIndex(index);
  };

  const handleVerifyPhone = () => {
    verifyPhone({
      country_code: formik.values.country_code,
      phone_number: formik.values.phone_number,
    })
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          dispatch(
            updateFormData((state) => {
              state.phoneVerified = {
                verified: false,
                status: true,
              };
            })
          );
          setOtp(() => response?.data?.data);

          toast.success(
            `OTP sent to ${
              formik.values.country_code + " " + formik.values.phone_number
            } !`
          );
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else if (response?.data?.status_code === 400) {
          toast.error(response?.data?.message);
          formik.setErrors(response?.data?.message);
        } else if (response?.error?.data?.status_code === 400) {
          toast.error("Failed to Sent OTP!");
          formik.setErrors(response?.error?.data?.message);
        } else {
          toast.error("Failed to Sent OTP!");
        }
      })
      .catch(() => {
        toast.error("Failed to Sent OTP!");
      });
  };

  const handleValidatePhone = () => {
    validatePhone({
      country_code: formik.values.country_code,
      phone_number: formik.values.phone_number,
      otp: Number(formik.values.otp),
    })
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          dispatch(
            updateFormData((state) => {
              state.phoneVerified = {
                verified: true,
                status: true,
              };
            })
          );
          toast.success("Phone Validated Successfully!");
        } else if (response?.error?.data?.status_code === 400) {
          toast.error("Failed to Verify OTP!");
          formik.setErrors(response?.error?.data?.message);
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.message);
        } else {
          toast.error("Failed to Verify OTP!");
        }
      })
      .catch(() => {
        toast.error("Failed to Verify OTP!");
      });
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handlePhoneNumberChange = (value) => {
    if (value !== formik.values.phone_number) {
      dispatch(
        updateFormData((state) => {
          state.phoneVerified = {
            verified: false,
            status: false,
          };
        })
      );
      setOtp("");
      formik.setFieldValue("otp", "");
    }
    formik.setFieldValue("phone_number", value);
  };

  return {
    currentFormData,
    profilefileInputRef,
    parishRef,
    dioceseRef,
    otp,
    cuntryCodeOptions,
    formik,
    dobRef,
    phoneVerified,
    showPassword,
    imagePreview,
    activeImageIndex,
    handlePhoneNumberChange,
    handleShowPassword,
    handleThumbClick,
    handleProfileImage,
    handleCloseModal,
    getFieldError,
    handleVerifyPhone,
    handleValidatePhone,
  };
};

export default useBasic;
