import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { members } from "../../../../store/queries/members";
import { staff } from "../../../../store/queries/settings";
import { payment } from "../../../../store/queries/payment";
import { profile } from "../../../../store/queries/Profile";
import { faqsRtk } from "../../../../store/queries/cms";
import {
  notificationRtk,
  useGetNotificationListDataQuery,
  useUpdateNotificationDataMutation,
} from "../../../../store/queries/notifications";
import { usersRtk } from "../../../../store/queries/users";
import { useDispatch, useSelector } from "react-redux";
import {
  getGlobalSearchData,
  updateConfig,
} from "../../../../store/slices/Global";
import { updateConfig as updateMemberConfig } from "../../../../store/slices/Users/membersSlice";

import useObserver from "../../../../utils/hooks/useObserver";
import { viewStaffProfile } from "../../../../store/slices/Settings/staffSlice";
import { dashboard } from "../../../../store/queries/Dashboard";

const useHeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const activeUser = localStorage.getItem("USER_DETAILS") ?? [];
  const [isProfileViewVisible, setProfileViewVisible] = useState(false);
  const searchInputRef = useRef(null);
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useSelector((state) => state.global);
  const notificationState = useSelector((state) => state.notification);
  const staffState = useSelector((state) => state.staff);

  useEffect(() => {
    if (localStorage.getItem("USER_DETAILS") !== undefined)
      dispatch(
        viewStaffProfile({ user_id: localStorage.getItem("USER_DETAILS") })
      );
    //eslint-disable-next-line
  }, []);

  const [updateNotification] = useUpdateNotificationDataMutation();

  const NotificationRedirectType = {
    profile_view: 1,
    view_master_data_updation: 2,
    messages_list: 3,
    transactions_list: 4,
    deleted_list: 5,
    package_expired_members_list: 6,
    reported_list: 7,
    users_blocked_list: 8,
    transfers_list: 9,
    members_list: 10,
    verification_list: 11,
    followup_list: 12,
    view_verification_photo: 13,
    view_verification_document: 14,
  };

  const {
    data: notification = {},
    isFetching,
    // isLoading,
    refetch,
  } = useGetNotificationListDataQuery({
    page_size: notificationState.currentPageSize,
    page: notificationState.currentPage,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsModalOpen((state) => !state);
        focusSearchInput();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchInputRef]);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
        state.searchData = {};
      })
    );
  };

  const handleViewProfile = () => {
    setProfileViewVisible(true);
  };

  const handleCloseProfileView = () => {
    setProfileViewVisible(false);
  };

  const handleLogout = () => {
    dispatch(members.util.resetApiState());
    dispatch(staff.util.resetApiState());
    dispatch(payment.util.resetApiState());
    dispatch(profile.util.resetApiState());
    dispatch(faqsRtk.util.resetApiState());
    dispatch(notificationRtk.util.resetApiState());
    dispatch(usersRtk.util.resetApiState());
    dispatch(dashboard.util.resetApiState());
    localStorage.removeItem("USER_DETAILS");
    localStorage.removeItem("USER_ACCESS_TOKEN");
    localStorage.removeItem("dashboard_permissions");
    localStorage.removeItem("profile_permissions");
    navigate("/");
  };

  const handleSettings = () => {
    navigate("/configure/appearance");
  };

  const handleSearchData = (item) => {
    dispatch(
      updateConfig((state) => {
        state.searchKey = item;
        state.searchData = {};
      })
    );
    if (item?.length >= 2 && globalState.searchKey !== item) {
      dispatch(
        getGlobalSearchData({
          searchKey: item,
          page: 1,
          clearState: true,
          user_id: activeUser,
        })
      );
    }
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    dispatch(
      getGlobalSearchData({
        searchKey: globalState?.searchKey,
        page: page + 1,
        user_id: activeUser,
      })
    );
  };

  const { lastElement } = useObserver({
    loading: globalState?.searchStatus === "pending",
    hasMore:
      globalState?.searchData?.[activeUser]?.per_page *
        globalState?.searchData?.[activeUser]?.current_page <
      globalState?.searchData?.[activeUser]?.total,
    callback: handleLoadMore,
  });

  const handleProfileRedirect = (userId) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), userId]
      : [userId];

    const newIds = ids.filter((id) => id !== userId).join(",");

    window.open(
      `${window.location.origin}/${`profile?active=${userId}&id=${newIds}`}`
    );
  };

  const handleInputChange = (item) => {
    setIsModalOpen(true);
    focusSearchInput();
    dispatch(
      updateConfig((state) => {
        state.searchKey = item;
        state.searchData = {};
      })
    );
    if (item?.length >= 2 && globalState.searchKey !== item) {
      dispatch(
        getGlobalSearchData({
          searchKey: item,
          page: 1,
          user_id: activeUser,
        })
      );
    }
  };

  const isNewNotification = useMemo(() => {
    let notificationData = notification?.data?.data?.filter(
      (item) => item.is_read === 0
    );

    return notificationData?.length > 0;
  }, [notification?.data]);

  const findRedirectTypeKey = (redirectType) => {
    for (const key in NotificationRedirectType) {
      if (NotificationRedirectType[key] === redirectType) {
        return key;
      }
    }
    return null;
  };

  const handleRedirect = (type, id, userId) => {
    const key = findRedirectTypeKey(type);

    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("notification_id", id);

    updateNotification(formData).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch?.();
      }
    });

    switch (key) {
      case "profile_view":
        handleProfileRedirect(userId);
      
        break;
      case "view_master_data_updation":
        navigate("/support/data-updation");
        break;
      case "messages_list":
        navigate("/support/complaints");
        break;
      case "transactions_list":
        navigate("/payment/transactions");
        break;
      case "deleted_list":
        navigate("/users/deleted-accounts");
        break;
      case "package_expired_members_list":
        navigate("/users/");
        dispatch(
          updateMemberConfig((state) => {
            state.currentFilter = "expired";
            state.currentPage = 1;
          })
        );
        break;
      case "reported_list":
        navigate("/users/reported");
        break;
      case "users_blocked_list":
        navigate("/users/blocked");
        break;
      case "transfers_list":
        navigate("/support/transfers");
        break;
      case "members_list":
        navigate("/users/");
        dispatch(
          updateMemberConfig((state) => {
            state.currentFilter = "all";
            state.currentPage = 1;
          })
        );
        break;
      case "verification_list":
        handleProfileRedirect(userId);
        break;
      case "followup_list":
        navigate("/users/followup");
        break;
      case "view_verification_photo":
        // navigate("/users/verification");
        handleProfileRedirect(userId);
        break;
      case "view_verification_document":
        // navigate("/users/verification");
        handleProfileRedirect(userId);
        break;

      default:
        navigate("/");
        break;
    }
  };

  const handleNotificationRedirect = () => {
    navigate("/notifications");
  };

  return {
    isModalOpen,
    isFetching,
    searchInputRef,
    staffState,
    isNewNotification,
    notification: notification?.data,
    globalState,
    userData: globalState?.searchData?.[activeUser],
    isProfileViewVisible,
    handleNotificationRedirect,
    handleRedirect,
    lastElement,
    handleInputChange,
    setIsModalOpen,
    closeModal,
    handleLogout,
    handleProfileRedirect,
    handleSettings,
    handleSearchData,
    handleViewProfile,
    handleCloseProfileView,
    setProfileViewVisible,
  };
};

export default useHeaderActions;
