import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../../store/slices/Users/createMemberSlice";
import { updateConfig } from "../../../../../store/slices/Users/membersSlice";
import { toast } from "react-toastify";
import {
  profile,
  useUpdateProfileDetailsMutation,
} from "../../../../../store/queries/Profile";
import { members } from "../../../../../store/queries/members";

const useFamily = ({ isOptionDataSuccess, isEditProfile, profileRefetch }) => {
  const { currentFormData, backendErrors } = useSelector(
    (state) => state.createMembers
  );

  const [sendRequest] = useUpdateProfileDetailsMutation();

  let createMemberVal = Yup.object().shape({
    // sibling_details: Yup.object().shape({
    //   no_of_brothers: Yup.number()
    //     .typeError("Please enter a valid number")
    //     .positive("Please enter a positive number")
    //     .integer("Please enter a whole number"),
    //   brothers_married: Yup.number()
    //     .typeError("Please enter a valid number")
    //     .positive("Please enter a positive number")
    //     .integer("Please enter a whole number"),
    //   no_of_sisters: Yup.number()
    //     .typeError("Please enter a valid number")
    //     .positive("Please enter a positive number")
    //     .integer("Please enter a whole number"),
    //   sisters_married: Yup.number()
    //     .typeError("Please enter a valid number")
    //     .positive("Please enter a positive number")
    //     .integer("Please enter a whole number"),
    // }),
    // no_of_priests: Yup.number()
    //   .typeError("Please enter a valid number")
    //   .positive("Please enter a positive number")
    //   .integer("Please enter a whole number"),
    // no_of_nuns: Yup.number()
    //   .typeError("Please enter a valid number")
    //   .positive("Please enter a positive number")
    //   .integer("Please enter a whole number"),
  });

  const dispatch = useDispatch();

  let editRequiredData = {
    user_id: currentFormData?.user_id,
    first_name: currentFormData?.first_name,
    last_name: currentFormData?.last_name,
    email: currentFormData?.email,
    country_code: currentFormData?.country_code,
    phone_number: currentFormData?.phone_number,
    gender: currentFormData?.gender?.id,
    dob: currentFormData?.dob,
    community_id: currentFormData?.community_id?._id,
    diocese_id: currentFormData?.diocese_id?._id,
  };

  const formik = useFormik({
    initialValues: {
      father_details: {
        name: "",
        house_name: "",
        occupation: "",
        native_place: "",
      },
      mother_details: {
        name: "",
        house_name: "",
        occupation: "",
        native_place: "",
      },
      family_type: "",
      family_status: "",
      sibling_details: {
        no_of_brothers: "",
        brothers_married: "",
        no_of_sisters: "",
        sisters_married: "",
      },
      no_of_priests: "",
      no_of_nuns: "",
    },

    validationSchema: createMemberVal,

    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          ...values,
        };

        let editObj = {
          "father_details[name]": values.father_details.name,
          "father_details[house_name]": values.father_details.house_name,
          "father_details[occupation]": values.father_details.occupation?._id,
          "father_details[native_place]": values.father_details.native_place,
          "mother_details[name]": values.mother_details.name,
          "mother_details[house_name]": values.mother_details.house_name,
          "mother_details[occupation]": values.mother_details.occupation?._id,
          "mother_details[native_place]": values.mother_details.native_place,
          family_type: values.family_type?.id,
          family_status: values.family_status?.id,
          "sibling_details[no_of_brothers]":
            values.sibling_details.no_of_brothers === ""
              ? ""
              : values.sibling_details.no_of_brothers,
          "sibling_details[brothers_married]":
            values.sibling_details.brothers_married === ""
              ? ""
              : values.sibling_details.brothers_married,
          "sibling_details[no_of_sisters]":
            values.sibling_details.no_of_sisters === ""
              ? ""
              : values.sibling_details.no_of_sisters,
          "sibling_details[sisters_married]":
            values.sibling_details.sisters_married === ""
              ? ""
              : values.sibling_details.sisters_married,
          no_of_priests:
            values.no_of_priests === "" ? "" : values.no_of_priests,
          no_of_nuns: values.no_of_nuns === "" ? "" : values.no_of_nuns,
        };

        let newObj = Object.entries(editObj);

        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

        if (isEditProfile) {
          let formData = new FormData();
          formData.append("_method", "PUT");

          Object.keys(newObj).forEach((key) => {
            return formData.append(key, newObj[key]);
          });
          Object.keys(editRequiredData).forEach((key) => {
            return formData.append(key, editRequiredData[key]);
          });

          sendRequest(formData).then((response) => {
            dispatch(
              updateFormData((state) => {
                state.backendErrors = "";
              })
            );

            if (response?.data?.status_code === 200) {
              resetForm();

              dispatch(
                updateFormData((state) => {
                  state.currentFormData = {};
                  state.activeTab = "Basic";
                  state.isEditProfile = false;
                  state.completedTabs = {
                    ...state.completedTabs,
                    Basic: false,
                    Family: false,
                    Job: false,
                    Edu: false,
                    Contact: false,
                  };
                  state.CompletedPercentage = {
                    ...state.CompletedPercentage,
                    Basic: 0,
                    Family: 0,
                    Job: 0,
                    Edu: 0,
                    Contact: 0,
                  };
                })
              );

              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.clearSelection = true;
                })
              );
              dispatch(profile.util.invalidateTags(["Overview", "Incomplete"]));
              dispatch(members.util.invalidateTags(["Members"]));
              toast.success("Successfully submitted");
            } else if (response?.data?.status_code === 400) {
              formik.setErrors(response?.data?.message);
              dispatch(
                updateFormData((state) => {
                  state.backendErrors = response?.data?.message;
                })
              );
            }
          });
        } else {
          dispatch(
            updateFormData((state) => {
              state.currentFormData = { ...state.currentFormData, ...obj };
              state.activeTab = "Edu";
              state.completedTabs = { ...state.completedTabs, Family: true };
            })
          );
        }
      } catch (error) {
        toast.error("Failed to  submit");
      }
    },
  });

  useEffect(() => {
    let totalFields = Object.values(formik.initialValues).length;

    const filterDataFromTab = (fullObject, tabObject) => {
      const filteredData = {};

      for (const key in tabObject) {
        if (fullObject.hasOwnProperty(key)) {
          filteredData[key] = fullObject[key];
        }
      }

      return filteredData;
    };

    const filteredData = filterDataFromTab(formik.values, formik.initialValues);

    const countNonEmptyKeys = (data) => {
      let count = 0;
      for (let key in data) {
        if (
          data.hasOwnProperty(key) &&
          data[key] !== null &&
          data[key] !== ""
        ) {
          count++;
        }
      }
      return count;
    };

    let numberOfNonEmptyKeys = countNonEmptyKeys(filteredData);

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Family: Math.ceil((numberOfNonEmptyKeys / totalFields) * 100),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  useEffect(() => {
    if (isOptionDataSuccess) {
      let newSetObj = Object.entries(currentFormData);

      newSetObj = newSetObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      Object.keys(newSetObj || {}).forEach((key) => {
        formik.setFieldValue(key, newSetObj?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormData]);

  useEffect(() => {
    if (backendErrors) {
      formik.setErrors(backendErrors);
    }
    // eslint-disable-next-line
  }, [backendErrors]);

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.currentFormData = {};
        state.activeTab = "Basic";
        state.isEditProfile = false;
        state.phoneVerified = {
          verified: false,
          status: false,
        };
        state.completedTabs = {
          ...state.completedTabs,
          Basic: false,
          Family: false,
          Job: false,
          Edu: false,
          Contact: false,
        };
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Family: 0,
          Job: 0,
          Edu: 0,
          Contact: 0,
        };
      })
    );
  };

  const getFieldError = (fieldName) => {
    const fieldNames = fieldName.split(".");

    if (
      formik.touched[fieldNames?.[0]]?.[fieldNames?.[1]] &&
      formik.errors[fieldNames?.[0]]?.[fieldNames?.[1]]
    ) {
      return formik.errors[fieldNames?.[0]]?.[fieldNames?.[1]];
    }

    return "";
  };

  // const getFieldError = (fieldName) => {
  //   if (backendErrors && backendErrors[fieldName]) {
  //     return backendErrors[fieldName];
  //   }
  //   if (formik.errors[fieldName]) {
  //     return formik.errors[fieldName];
  //   }
  //   return "";
  // };

  return {
    formik,
    currentFormData,
    handleCloseModal,
    getFieldError,
  };
};

export default useFamily;
