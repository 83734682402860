import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetStaffListDataQuery,
  useUpdateStaffsListDataMutation,
} from "../../../store/queries/settings";
import {
  updateConfig,
  editItemFromTableByID,
  clearData,
} from "../../../store/slices/Settings/staffSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getExportedData } from "./api";
import moment from "moment";

const useStaff = () => {
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.staff);

  const { showCreateModal, selectedItemsDetails } = useSelector(
    (state) => state.staff
  );

  useEffect(() => {
    if (showCreateModal === false && selectedItemsDetails !== "") {
      dispatch(clearData());
    }
    // eslint-disable-next-line
  }, [showCreateModal]);

  const { tableFields, showEditModal } = useSelector((state) => state.global);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [updateStaffsFields] = useUpdateStaffsListDataMutation();

  const {
    data: staffs = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetStaffListDataQuery({
    page_size: staffState.currentPageSize,
    page: staffState.currentPage,
    sort_by: staffState.sortBy,
    sort_order: staffState.sortOrder,
    search: staffState.search,
  });
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = staffs?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [staffs]);

  const hasExportPermission = useMemo(() => {
    let permission = staffs?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [staffs]);

  const hasCreatePermission = useMemo(() => {
    let permission = staffs?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
    // eslint-disable-next-line
  }, [staffs]);

  const hasEditPermission = useMemo(() => {
    let permission = staffs?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? 0;
  }, [staffs])

  const hasUpdatePermission = useMemo(() => {
    let permission = staffs?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [staffs])

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (staffState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = staffState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID({ user_id: ids[0] }))
        .unwrap()
        .then((data) => {
          if (data.data.success === true) {
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = true;
              })
            );
          }
        });
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(staffs?.data?.fields),
        sort_by: staffState.sortBy,
        sort_order: staffState.sortOrder,
        search: staffState.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.selectedItemsDetails = "";
      })
    );
  };

  return {
    handleClearClick,
    staffs,
    staffState,
    currentPage: staffState.currentPage,
    showCreateModal,
    hasCreatePermission,
    paginationOptions,
    isFetching,
    isLoading,
    showEditModal,
    tableFields,
    actionOptions,
    hasExportPermission,
    hasUpdatePermission,
    hasEditPermission,
    handleActionChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    updateStaffsFields,
    handleEditAction,
    selectedItemsDetails,
  };
};

export default useStaff;
