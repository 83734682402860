import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getActivityData,
  updateConfig,
} from "../../../store/slices/Profile/ProfileActivity";
import useObserver from "../../../utils/hooks/useObserver";
import { useDispatch } from "react-redux";

const useActivity = () => {
  const [params] = useSearchParams();
  const [showScroll, setShowScroll] = useState(false);
  const dispatch = useDispatch();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const [status, setStatus] = useState({ value: 10, label: "Last 10 days" });
  const state = useSelector((state) => state.profileActivity);
  const profileActivityState = state?.activityData?.[activeProfile];
  const currentDate = new Date();
  const [page, setPage] = useState(1);
  const [selectedChart, setSelectedChart] = useState("");

  const [value, setValue] = useState([
    {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
    },
    {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
    },
    {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
    },
  ]);

  const [current, setCurrent] = useState([
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
  ]);

  const result = {
    profile_views: {
      views: [],
      favourites: [],
    },
    interests: {
      interest_sent: [],
      interest_received: [],
    },
    contacts: {
      contact_viewed_user: [],
      contact_viewed_other: [],
    },
  };
  const handleChange = (selectedOption) => {
    dispatch(
      getActivityData({
        member_id: activeProfile,
        per_page: state.currentPageSize,
        page: page,
        filter_days: selectedOption?.value,
        clearState: true,
      })
    );
    setStatus(selectedOption);
  };

  state?.activityChartData?.data?.forEach((item) => {
    if (item.profile_views) {
      item.profile_views.allWeek?.forEach(() => {
        result.profile_views.views.push(0);
        result.profile_views.favourites.push(0);
      });
      item.profile_views.views?.forEach((view) => {
        const index = item.profile_views.allWeek.indexOf(view.week);
        if (index !== -1) {
          result.profile_views.views[index] = view.view_count;
        }
      });

      item.profile_views.favourites.forEach((favorite) => {
        const index = item.profile_views.allWeek?.indexOf(favorite.week);
        if (index !== -1) {
          result.profile_views.favourites[index] = favorite.view_count;
        }
      });
    }

    if (item.interests) {
      item.interests.allWeek?.forEach(() => {
        result?.interests?.interest_sent.push(0);
        result?.interests?.interest_received.push(0);
      });
      item.interests.interest_sent?.forEach((interestSent) => {
        const index = item.interests.allWeek.indexOf(interestSent.week);
        if (index !== -1) {
          result.interests.interest_sent[index] = interestSent?.view_count;
        }
      });

      item.interests.interest_received?.forEach((interestReceived) => {
        const index = item.interests.allWeek.indexOf(interestReceived.week);
        if (index !== -1) {
          result.interests.interest_received[index] =
            interestReceived?.view_count;
        }
      });
    }

    if (item.contacts) {
      item.contacts.allWeek?.forEach(() => {
        result.contacts?.contact_viewed_user.push(0);
        result.contacts?.contact_viewed_other.push(0);
      });

      item.contacts.contact_viewed_user?.forEach((contactViewedUser) => {
        const index = item.contacts.allWeek.indexOf(contactViewedUser.week);
        if (index !== -1) {
          result.contacts.contact_viewed_user[index] =
            contactViewedUser.view_count;
        }
      });

      item.contacts.contact_viewed_other?.forEach((contactViewedOther) => {
        const index = item.contacts.allWeek?.indexOf(contactViewedOther.week);
        if (index !== -1) {
          result.contacts.contact_viewed_other[index] =
            contactViewedOther.view_count;
        }
      });
    }
  });

  let totalCount = 0;

  profileActivityState?.data?.forEach((item) => {
    totalCount += item.data.length;
  });

  const chartData = [
    {
      title: "Profiles Visited",
      datasets: [
        {
          label: "Visited",
          backgroundColor: "#D8D5D8",
          data: result?.profile_views?.views,
        },
        {
          label: "Favourites",
          backgroundColor: "#660047",
          data: result?.profile_views?.favourites,
        },
      ],
    },
    {
      title: "Interests",
      datasets: [
        {
          label: "Sent",
          backgroundColor: "#D8D5D8",
          data: result?.interests?.interest_sent,
        },
        {
          label: "Received",
          backgroundColor: "#660047",
          data: result?.interests?.interest_received,
        },
      ],
    },
    {
      title: "Contact",
      datasets: [
        {
          label: "Viewed by user",
          backgroundColor: "#D8D5D8",
          data: result?.contacts?.contact_viewed_user,
        },
        {
          label: "Viewed by others",
          backgroundColor: "#660047",
          data: result?.contacts?.contact_viewed_other,
        },
      ],
    },
  ];

  useEffect(() => {
    if (
      Object.keys(state?.activityData)?.length === 0 &&
      state?.activityStatus === "idle"
    ) {
      dispatch(
        getActivityData({
          member_id: activeProfile,
          per_page: state.currentPageSize,
          page: page,
          filter_days: 10,
        })
      );
    }
    //eslint-disable-next-line
  }, [activeProfile]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleLoadMore = () => {
    dispatch(
      updateConfig((state) => {
        //state.currentPage = state.currentPage + 1;
        state.activityData[activeProfile].page += 1;
      })
    );
    setPage((prev) => prev + 1);
    dispatch(
      getActivityData({
        member_id: activeProfile,
        per_page: state.currentPageSize,
        page: page + 1,
        filter_days: 10,
      })
    );
  };

  const { lastElement } = useObserver({
    loading: state?.activityStatus === "pending",
    hasMore: totalCount < profileActivityState?.total,
    callback: handleLoadMore,
  });

  return {
    chartData,
    state,
    activeProfile,
    value,
    status,

    handleChange,
    selectedChart,
    setSelectedChart,
    setValue,
    setCurrent,
    current,
    showScroll,
    profileActivityState,
    lastElement,
    handleScrollToTop,
  };
};

export default useActivity;
