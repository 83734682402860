import { useDispatch, useSelector } from "react-redux";
import {
  useGetIncompletedListDataQuery,
  useUpdateIncompletedListDataMutation,
} from "../../../store/queries/members";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Users/incompleteSlice";
import { updateConfig as updateMemberConfig } from "../../../store/slices/Users/membersSlice";
import {
  PhotoRow,
  BasicRow,
  CommunicationRow,
  EmploymentRow,
  EducationRow,
} from "./Rows";
import { useMemo, useState } from "react";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { useSearchParams } from "react-router-dom";
import { updateFormData } from "../../../store/slices/Users/createMemberSlice";
import {
  useGetOverviewDataQuery,
  useUpdateProfileDetailsMutation,
} from "../../../store/queries/Profile";
import { useEffect } from "react";
import Assets from "../../../assets/Assets";
import { toast } from "react-toastify";

const useIncomplete = () => {
  const dispatch = useDispatch();

  const incompleteState = useSelector((state) => state.incomplete);
  const membersState = useSelector((state) => state.members);
  const { showEditModal } = useSelector((state) => state.global);
  const { selectedUser, isEditProfile } = useSelector(
    (state) => state.createMembers
  );
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [show, setShow] = useState(false);

  const [updateProfile] = useUpdateProfileDetailsMutation();

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: incomplete = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetIncompletedListDataQuery({
    page_size: incompleteState.currentPageSize,
    page: incompleteState.currentPage,
    filter: incompleteState.currentFilter,
    sort_by: incompleteState.sortBy,
    sort_order: incompleteState.sortOrder,
    search: incompleteState.search,
  });

  const [updateIncompletedFields] = useUpdateIncompletedListDataMutation();

  const { data: profileData, isSuccess: isUpdateDataSuccess } =
    useGetOverviewDataQuery(
      {
        user_id: selectedUser,
      },
      { skip: isEditProfile === false }
    );

  useEffect(() => {
    if (isEditProfile && isUpdateDataSuccess) {
      dispatch(
        updateFormData((state) => {
          state.currentFormData = {
            ...profileData?.data?.data,
            user_id: selectedUser,
          };
        })
      );
    }

    // return () => {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.currentPage = 1;
    //       state.currentFilter = "all";
    //       state.currentPageSize = 10;
    //       state.sortBy = "";
    //       state.sortOrder = "";
    //       state.search = "";
    //     })
    //   );
    // };
    // eslint-disable-next-line
  }, [isEditProfile, isUpdateDataSuccess, profileData]);

  const hasCreatePermission = useMemo(() => {
    let permission = incomplete?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [incomplete]);

  const hasViewPermission = useMemo(() => {
    let permission = incomplete?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [incomplete]);

  const hasEditPermission = useMemo(() => {
    let permission = incomplete?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [incomplete]);

  const hasExportPermission = useMemo(() => {
    let permission = incomplete?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [incomplete]);

  const imageSlider = useMemo(() => {
    if (isUpdateDataSuccess) {
      if (
        profileData?.data?.data?.profile_images &&
        profileData?.data?.data?.profile_images?.length !== 0
      ) {
        return profileData?.data?.data?.profile_images?.map((img, i) => {
          return {
            image: img?.url,
            id: img?._id,
            isPrimary: img?.is_primary ?? 0,
          };
        });
      } else {
        return [
          {
            image:
              profileData?.data?.data?.gender?.name === "Female"
                ? Assets.FEMALEPROFILE
                : Assets.MALEPROFILE,
            id: 1,
            isPrimary: 0,
            isDefault: true,
          },
        ];
      }
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [profileData, isFetching]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleFilter = (filter) => {
    if (filter.value === "all") {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleSort = (label) => {
    if (incompleteState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            incompleteState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const closeImageCardModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    setShow((state) => (state = false));
    setTimeout(() => {
      setActiveImageIndex(() => 0);
    }, 300);
  };

  const handleUpdateClick = (tab, id) => {
    dispatch(
      updateMemberConfig((state) => {
        state.showCreateModal = true;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.activeTab = tab;
        state.isEditProfile = true;
        state.selectedUser = id;
      })
    );
  };

  const handlePhotoClick = (id) => {
    dispatch(
      updateFormData((state) => {
        state.isEditProfile = true;
        state.selectedUser = id;
      })
    );
    setShow((state) => (state = true));
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      image_details_url: (_, data) =>
        hasEditPermission === 1 ? (
          <PhotoRow data={data} onClick={handlePhotoClick} />
        ) : (
          <PhotoRow data={data} />
        ),
      basic_details_url: (_, data) =>
        hasEditPermission === 1 ? (
          <BasicRow data={data} onClick={handleUpdateClick} />
        ) : (
          <BasicRow data={data} />
        ),
      communication_url: (_, data) =>
        hasEditPermission === 1 ? (
          <CommunicationRow data={data} onClick={handleUpdateClick} />
        ) : (
          <CommunicationRow data={data} />
        ),
      employement_url: (_, data) =>
        hasEditPermission === 1 ? (
          <EmploymentRow data={data} onClick={handleUpdateClick} />
        ) : (
          <EmploymentRow data={data} />
        ),
      education_url: (_, data) =>
        hasEditPermission === 1 ? (
          <EducationRow data={data} onClick={handleUpdateClick} />
        ) : (
          <EducationRow data={data} />
        ),
      name: (_, data) => (
        <ProfileNavigation
          hasViewPermission={hasViewPermission === 1 ? true : false}
          userId={data?.id}
          fieldValue={data?.name}
          activeProfile={activeProfile}
          params={params}
        />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = "all";
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
      })
    );
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
    }
  };

  const closeModal = () => {
    dispatch(
      updateMemberConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Basic";
        state.isEditProfile = false;
        state.currentFormData = {};
      })
    );
  };

  const handleDeleteImage = () => {
    setShow(() => false);
    setShowDeleteImageModal(() => true);
  };

  const closeDeleteImageModal = () => {
    setShowDeleteImageModal(() => false);
    setShow(() => true);
  };
  const handleActionDeleteImage = () => {
    let obj = {
      user_id: activeProfile,
      first_name: profileData?.data?.data?.first_name,
      last_name: profileData?.data?.data?.last_name,
      country_code: profileData?.data?.data?.country_code,
      phone_number: profileData?.data?.data?.phone_number,
      gender: profileData?.data?.data?.gender?.id,
      dob: profileData?.data?.data?.dob,
      community_id: profileData?.data?.data?.community_id?._id,
      diocese_id: profileData?.data?.data?.diocese_id?._id,
    };
    try {
      const formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      formData.append("_method", "PUT");
      formData.append(
        "profile_images_deleted[]",
        `${
          imageSlider?.sort((a, b) => b.isPrimary - a.isPrimary)[
            activeImageIndex
          ]?.id
        }`
      );

      updateProfile(formData)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Image Deleted Successfully!");
            refetch();
            closeDeleteImageModal();
          } else {
            toast.error("Failed to Delete image!");
          }
        })
        .catch(() => {
          toast.error("Failed to Delete image!");
        });
    } catch (e) {
      toast.error("Failed to Delete Image!");
    }
  };

  return {
    incomplete,
    currentPage: incompleteState.currentPage,
    activeFilter: incompleteState.currentFilter,
    incompleteState,
    isFetching,
    isLoading,
    hasExportPermission,
    isEditProfile,
    membersState,
    selectedUser,
    actionOptions,
    profileData: profileData?.data,
    imageSlider,
    show,
    showDeleteImageModal,
    activeImageIndex,
    closeImageCardModal,
    setActiveImageIndex,
    setShow,
    setShowDeleteImageModal,
    handleDeleteImage,
    handleActionDeleteImage,
    closeDeleteImageModal,
    refetch,
    handleActionChange,
    handleSort,
    getRow,
    handleFilter,
    handlePageSize,
    handlePagination,
    handleSearch,
    paginationOptions,
    handleEditClick,
    closeEditModal,
    closeModal,
    showEditModal,
    updateIncompletedFields,
    hasCreatePermission,
    hasViewPermission,
    hasEditPermission,
    handleClearClick,
  };
};

export default useIncomplete;
