import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useObserver from "../../../utils/hooks/useObserver";
import { useDispatch, useSelector } from "react-redux";
import {
  getMatchDetails,
  getPartnerPreferenceFilter,
  updateConfig,
} from "../../../store/slices/Profile/Matches";
import {
  profile,
  useGetFilterOptionsDataQuery,
  useGetOverviewDataQuery,
  useShortListProfileMutation,
} from "../../../store/queries/Profile";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { updateConfig as updateConfigShortlisted } from "../../../store/slices/Profile/ShortListed";

const useMatches = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMore, setShowmore] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [page, setPage] = useState(1);
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const matches = useSelector((state) => state.matches);
  const matchesPreference = useSelector((state) => state.matches);
  const matchesState = matches?.matchesData?.[activeProfile];
  const matchesPreferenceState =
    matches?.matchesPreferenceData?.[activeProfile];
  let pageX = 1;
  let pageY = 1;
  const { data: optionsData, isSuccess: isOptionDataSuccess } =
    useGetFilterOptionsDataQuery();

  const {
    data: overViewData,
    // refetch,
  } = useGetOverviewDataQuery({
    user_id: activeProfile,
  });

  const [sendRequest] = useShortListProfileMutation();

  const formik = useFormik({
    initialValues: {
      user_search: "",
      age: { min: 21, max: 30 },
      marital_status: [],
      height: { min: 137, max: 214 },
      body_complexion: [],
      weight: { min: 41, max: 136 },
      language: [],
      community: [],
      employment_sector: [],
      diocese: [],
      physical_status: [],
      body_type: [],
      country: [],
      state: [],
      district: [],
      occupation: [],
      family_status: [],
      education: [],
    },

    enableReinitialize: true,

    onSubmit: (values) => {
      let newObj = {
        ...values,
        country: values.country?.map((country) =>
          country?._id ? country?._id : country
        ),
        state: values.state?.map((state) => (state?._id ? state?._id : state)),
        mother_tongue: values.language,
      };

      let selectedFilters = Object.entries(newObj);
      selectedFilters = selectedFilters
        .filter((item) => (item[1].max ? true : false || item[1].length !== 0))
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      dispatch(
        updateConfig((state) => {
          state.isFiltersEdited = true;
          state.matchesSelectedFilters = { ...selectedFilters };
        })
      );
      delete selectedFilters.height;
      handleSubmit({
        member_id: activeProfile,
        preferences: {
          ...selectedFilters,
        },
      });
    },
  });

  const hasShortlistPermission = useMemo(() => {
    let permission = matches?.matchesPermissions?.filter((p) =>
      Object.keys(p).includes("can_shortlist")
    );

    return permission?.[0]?.can_shortlist ?? 0;
  }, [matches]);

  const hasSearchPermission = useMemo(() => {
    let permission = matches?.matchesPermissions?.filter((p) =>
      Object.keys(p).includes("can_search")
    );

    return permission?.[0]?.can_search ?? 0;
  }, [matches]);

  const handleAgeRangeChange = (e) => {
    formik.setFieldValue("age", { min: e[0], max: e[1] });
  };
  const handleWeightRangeChange = (e) => {
    formik.setFieldValue("weight", { min: e[0], max: e[1] });
  };

  const handleHeightRangeChange = (e) => {
    formik.setFieldValue("height", { min: e[0], max: e[1] });
  };

  useEffect(() => {
    if (matches.matchesStatus === "idle") {
      dispatch(
        getMatchDetails({
          member_id: activeProfile,
          page: pageX,
          clearState: true,
        })
      );
    }
    if (
      matches?.selectedProfiles?.length ===
      matchesState?.data
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?._id)?.length
    ) {
      dispatch(
        updateConfig((state) => {
          state.selectedProfiles = matchesState?.data
            ?.filter((item) => Boolean(item.short_listed))
            ?.map((item) => item?._id);
        })
      );
    }

    return () => {
      dispatch(
        updateConfig((state) => {
          state.matchesStatus = "idle";
          state.matchesData = {};
          state.matchesPreferenceStatus = "idle";
          state.matchesPreferenceData = {};
        })
      );

      dispatch(
        updateConfigShortlisted((state) => {
          state.shortListedStatus = "idle";
          state.shortListedData = {};
        })
      );
    };

    //eslint-disable-next-line
  }, [activeProfile]);

  

  useEffect(() => {
    if (isOptionDataSuccess && selectedPreferenceFilter.length === 0) {
      setShowFilter(true);
    }
    //eslint-disable-next-line
  }, [isOptionDataSuccess]);

  const filterData = useMemo(() => {
    const filterNames = {
      physical_status: "Physical Status",
      age: "Age",
      annual_income: "Annual Income",
      height: "Height",
      weight: "Weight",
      marital_status: "Marital Status",
      language: "Language",
      community: "Community",
      diocese: "Diocese",
      body_type: "Body Type",
      body_complexion: "Body Complexion",
      family_status: "Family Status",
      education: "Education",
      employment_sector: "Employment Sector",
      occupation: "Occupation",
      country: "Country",
      state: "State",
      district: "District",
    };
    let filteredData = [];

    if (isOptionDataSuccess && matches.matchesStatus === "fulfilled") {
      Object.keys(optionsData.data).forEach((option, i) => {
        if (
          matches.matchesSelectedFilters[option]?.length > 0 ||
          matches.matchesSelectedFilters[option]?.max
        ) {
          filteredData.push({ id: i, title: filterNames[option] });
        }
      });
    }

    return filteredData;
    //eslint-disable-next-line
  }, [isOptionDataSuccess, matches.matchesStatus]);

  const selectedPreferenceFilter = Object.values(
    filterData.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
  );

  useEffect(() => {
    if (matches.matchesSelectedFilters) {
      Object.keys(matches.matchesSelectedFilters || {}).forEach((key) => {
        formik.setFieldValue(key, matches.matchesSelectedFilters?.[key]);
      });
    }
    //eslint-disable-next-line
  }, [matches.matchesStatus, matches.matchesSelectedFilters]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleEditClick = () => {
    setShowFilter(true);
  };

  const handleCloseClick = () => {
    setShowFilter(false);
  };

  const handleSearch = () => {
    setShowFilter(false);
  };

  const handleLoadMore = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage += 1;
        state.matchesData[activeProfile].page += 1;
      })
    );

    dispatch(
      getMatchDetails({
        member_id: activeProfile,

        page: pageX + 1,
      })
    );

    pageX += 1;
  };

  const handlePreferenceLoadMore = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage += 1;
        state.matchesPreferenceData[activeProfile].page += 1;
      })
    );

    setPage((prev) => prev + 1);

    dispatch(
      getPartnerPreferenceFilter({
        ...matches?.matchesFilters,
        page_size: 21,
        page: page + 1,
        // page: pageY + 1,
      })
    );

    pageY += 1;
  };

  const lastElement1 = useObserver({
    loading: matches?.matchStatus === "pending",
    hasMore: matchesState?.data?.length < matchesState?.total,
    callback: handleLoadMore,
  });

  const lastElement2 = useObserver({
    loading: matchesPreference?.matchesPreferenceStatus === "pending",
    hasMore:
      matchesPreferenceState?.data?.length < matchesPreferenceState?.total,
    callback: handlePreferenceLoadMore,
  });

  const handleSelect = (id, checked, name) => {
    if (checked) {
      sendRequest({
        user_id: activeProfile,
        member_id: id,
        status: 1,
      })
        .then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Profile Shortlisted Successfully!");
            dispatch(profile.util.invalidateTags(["Overview"]));
            dispatch(
              updateConfigShortlisted((state) => {
                state.shortListedStatus = "idle";
              })
            );
          } else if (response?.error?.data?.status_code === 422) {
            toast.error("Failed to Shortlist Profile!");
          } else {
            toast.error("Failed to Shortlist Profile!");
          }
        })
        .catch(() => {
          toast.error("Failed to Shortlist Profile!");
        });
      dispatch(
        updateConfig((state) => {
          state.selectedProfiles = [...state.selectedProfiles, id];
          // state.selectedProfileNames = [...state.selectedProfileNames, name];
        })
      );
    } else {
      sendRequest({
        user_id: activeProfile,
        member_id: id,
        status: 0,
      })
        .then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Shortlist Removed Successfully!");
            dispatch(profile.util.invalidateTags(["Overview"]));
            dispatch(
              updateConfigShortlisted((state) => {
                state.shortListedStatus = "idle";
              })
            );
          } else if (response?.error?.data?.status_code === 422) {
            toast.error("Failed to Remove Shortlist!");
          } else {
            toast.error("Failed to  Shortlist!");
          }
        })
        .catch(() => {
          toast.error("Failed to Remove Shortlist!");
        });
      dispatch(
        updateConfig((state) => {
          state.selectedProfiles = state.selectedProfiles.filter(
            (prevID) => prevID !== id
          );
          // state.selectedProfileNames = state.selectedProfileNames.filter(
          //   (prevID) => prevID !== name
          // );
        })
      );
    }
  };

  const handleProfileRedirect = (id) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), activeProfile]
      : [activeProfile];

    const newIds = ids.filter((item) => item !== id).join(",");

    navigate({
      pathname: "/profile",
      search: `active=${id}&id=${newIds}`,
    });
  };

  const handleSubmit = (values) => {
    pageX = 1;

    dispatch(
      updateConfig((state) => {
        if (state.matchesPreferenceData?.[activeProfile]?.data) {
          state.matchesPreferenceData[activeProfile].data = [];
          state.matchesPreferenceData[activeProfile].page = 1;
        }
        if (state.matchesData?.[activeProfile]?.data) {
          state.matchesData[activeProfile].data = [];
          state.matchesData[activeProfile].page = 1;
        }
        state.matchesFilters = values;
      })
    );

    dispatch(
      getPartnerPreferenceFilter({ ...values, page_size: 21, page: pageY })
    );
  };

  const handleReset = () => {
    formik.handleReset();
    dispatch(
      updateConfig((state) => {
        state.matchesPreferenceData[activeProfile].data = [];
        state.matchesPreferenceData[activeProfile].page = 1;
        state.selectedProfiles = [];
      })
    );
  };

  const handleHasmore = () => {
    setShowmore(() => (showMore ? false : true));
  };

  return {
    matches,
    activeProfile,
    showFilter,
    matchesState,
    matchesPreference,
    matchesPreferenceState,
    formik,
    overViewData,
    optionsData: optionsData?.data,
    isOptionDataSuccess,
    selectedPreferenceFilter,
    showMore,
    showScroll,
    hasSearchPermission,
    hasShortlistPermission,
    handleScrollToTop,
    handleHasmore,
    handleAgeRangeChange,
    handleWeightRangeChange,
    handleHeightRangeChange,
    handleSubmit,
    handleReset,
    handleCloseClick,
    handleEditClick,
    lastElement1,
    lastElement2,
    handleSearch,
    handleSelect,
    handleProfileRedirect,
  };
};

export default useMatches;
