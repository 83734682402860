import React from "react";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";


const RowTwo = ({
  formik,
  optionsData,
  getFieldError,
  permDistrictRef,
  permStateRef,

}) => {
  return (
    <>
      <div className="pro-check-box pro-pb-2">
        <input
          type="checkbox"
          className="pro-check "
          id="permanent_address_as_present_address"
          name="permanent_address_as_present_address"
          checked={
            Boolean(formik.values?.permanent_address_as_present_address) ??
            false
          }
          onChange={(e) =>
            formik.setFieldValue(
              "permanent_address_as_present_address",
              !Boolean(formik.values?.permanent_address_as_present_address) ??
                false
            )
          }
        />
        <label
          htmlFor="permanent_address_as_present_address"
          className="pro-check-label pro-pb-2"
        >
          {" "}
          Same as present address{" "}
        </label>
      </div>

      {!formik.values?.permanent_address_as_present_address && (
        <>
          <h6 className="pro-ttl h6 pro-pt-3">Permanent Address</h6>

          <Input
            label={"House No. / name"}
            id="permanent_address[house_name]"
            type="text"
            name="permanent_address[house_name]"
            className={`pro-input lg  ${
              getFieldError("permanent_address.house_name") && " error"
            }`}
            {...formik.getFieldProps("permanent_address[house_name]")}
            error={getFieldError("permanent_address.house_name")}
            errorMessage={getFieldError("permanent_address.house_name")}
          />
          <Input
            label={"Street"}
            id="permanent_address[street]"
            type="text"
            name="permanent_address[street]"
            className={`pro-input lg  ${
              getFieldError("permanent_address.street") && " error"
            }`}
            {...formik.getFieldProps("permanent_address[street]")}
            error={getFieldError("permanent_address.street")}
            errorMessage={getFieldError("permanent_address.street")}
          />

          <Input
            label={"Pincode"}
            type="number"
            id="permanent_address[pincode]"
            name="permanent_address[pincode]"
            className={`pro-input lg  ${
              getFieldError("permanent_address.pincode") && " error"
            }`}
            {...formik.getFieldProps("permanent_address[pincode]")}
            error={getFieldError("permanent_address.pincode")}
            errorMessage={getFieldError("permanent_address.pincode")}
          />

          <div className={"input-wrap pro-mb-5"}>
            <label
              htmlFor="current_location"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Country
            </label>
            <Select
              id="permanent_address[country]"
              isClearable={true}
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("permanent_address.country") && " error"
              }`}
              classNamePrefix="pro-input"
              options={optionsData?.country}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              value={optionsData?.country?.filter(
                (m) => formik.values.permanent_address?.country?._id === m?._id
              )}
              onChange={(value) => {
                formik.setFieldValue(
                  "permanent_address[country]",
                  value || null
                );
                permStateRef.current.setValue([], "clear");
                permDistrictRef.current.setValue([], "clear");
              }}
            />
          </div>
          {getFieldError("permanent_address.country") && (
            <span className="error-text">
              {getFieldError("permanent_address.country")}
            </span>
          )}
          <div className={"input-wrap pro-mb-5"}>
            <label
              htmlFor="current_location"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              State
            </label>
            <Select
              id="permanent_address[state]"
              ref={permStateRef}
              isClearable={true}
              isDisabled={
                formik.values.permanent_address?.country?._id ? false : true
              }
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("permanent_address.state") && " error"
              }`}
              classNamePrefix="pro-input"
              options={optionsData?.state?.filter(
                (p) =>
                  p?.country_id ===
                  formik.values.permanent_address?.country?.unique_country_id
              )}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              value={optionsData?.state?.filter(
                (m) => formik.values.permanent_address?.state?._id === m?._id
              )}
              onChange={(value) => {
                formik.setFieldValue("permanent_address[state]", value || null);
                permDistrictRef.current.setValue([], "clear");
              }}
            />
          </div>
          {getFieldError("permanent_address.state") && (
            <span className="error-text">
              {getFieldError("permanent_address.state")}
            </span>
          )}
          <div className={"input-wrap pro-mb-5"}>
            <label
              htmlFor="current_location"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              District
            </label>
            <Select
              id="permanent_address[district]"
              isClearable={true}
              isDisabled={
                formik.values.permanent_address?.state?._id ? false : true
              }
              ref={permDistrictRef}
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("permanent_address.district") && " error"
              }`}
              classNamePrefix="pro-input"
              options={optionsData?.district?.filter(
                (p) =>
                  p?.state_id ===
                  formik.values.permanent_address?.state?.unique_state_id
              )}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              value={optionsData?.district?.filter(
                (m) => formik.values.permanent_address?.district?._id === m?._id
              )}
              onChange={(value) =>
                formik.setFieldValue(
                  "permanent_address[district]",
                  value || null
                )
              }
            />
          </div>
          {getFieldError("permanent_address.district") && (
            <span className="error-text">
              {getFieldError("permanent_address.district")}
            </span>
          )}
        </>
      )}
    </>
  );
};

export default RowTwo;
