import React from "react";
import useExportProfileDetails from "./useExportProfileDetails";
import Assets from "../../../../assets/Assets";

const ExportProfileDetails = React.forwardRef((props, ref) => {
  const { profileData, getOrdinalNumbers } = useExportProfileDetails(props);

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      <table
        border={0}
        cellSpacing={0}
        role="presentation"
        cellPadding={0}
        style={{
          maxWidth: "1150px",
          margin: "auto",
          borderCollapse: "collapse",
          paddingTop: "30px",
        }}
      >
        <tbody
          style={{
            backgroundImage: `url(${Assets.TABLEBG})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "188px 300px",
            backgroundPosition: "100% 100%",
            paddingBottom: "60px",
            display: "block",
          }}
        >
          <tr>
            <td
              style={{
                textAlign: "center",
                height: "134px",
                width: "1089px",
                maxHeight: "134px",
              }}
            >
              <img
                src={Assets.SYROLOGO}
                alt="Logo"
                style={{ width: "100%", margin: "auto" }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                width="1150px"
                style={{
                  width: "1150px",
                  maxWidth: "1150px",
                  minWidth: "1150px",
                  margin: "auto",
                  borderCollapse: "collapse",
                }}
              >
                <tbody style={{}}>
                  <tr>
                    <td style={{ paddingTop: "18px" }}>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="653px"
                        style={{
                          width: "653px",
                          maxWidth: "653px",
                          minWidth: "653px",
                          margin: "auto",
                          borderCollapse: "collapse",
                          paddingTop: "18px",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                background: `url(${Assets.PROFILEBG})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "240px",
                                paddingLeft: "20px",
                                paddingTop: "15px",
                              }}
                            >
                              <img
                                src={
                                  profileData?.data?.data?.profile_images?.[0]
                                    ?.url ??
                                  (profileData?.data?.data?.gender?.name ===
                                  "Female"
                                    ? Assets.FEMALEPROFILE
                                    : Assets.MALEPROFILE)
                                }
                                alt="user-img"
                                style={{
                                  display: "inline-block",
                                  width: "207px",
                                  height: "279px",
                                  borderRadius: "190px",
                                  overflow: "hidden",
                                }}
                              />
                            </td>
                            <td>
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  marginRight: "auto",
                                  borderBottom:
                                    "1px solid #D8D8D8 border-collapse: collapse",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "24px",
                                        fontWeight: 600,
                                        paddingBottom: "6px",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      {profileData?.data?.data?.first_name ??
                                        "Not specified" +
                                          " " +
                                          profileData?.data?.data?.last_name ??
                                        "Not specified"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        paddingBottom: "20px",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      {`Customer ID :
                                        ${
                                          profileData?.data?.data?.profile_id ??
                                          "Not specified"
                                        }`}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                width="100%"
                                style={{
                                  borderCollapse: "collapse",
                                  paddingTop: "20px",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Age
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      {` :
                                        ${
                                          profileData?.data?.data?.age ??
                                          "Not specified"
                                        }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="14px" />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Height
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      {` :
                                        ${
                                          profileData?.data?.data?.height
                                            ?.name ?? "Not specified"
                                        }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="14px" />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      District
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      {` :
                                        ${
                                          profileData?.data?.data
                                            ?.present_address?.district?.name ??
                                          "Not specified"
                                        }`}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="35px" />
                  </tr>
                  <tr>
                    <td>
                      <table
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="653px"
                        style={{
                          border: "1.5px solid #EDEDED",
                          borderRadius: "4px",
                          width: "653px",
                          maxWidth: "653px",
                          minWidth: "653px",
                          borderCollapse: "separate",
                          overflow: "hidden",
                          margin: "auto",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              bgcolor="#F8F8F8"
                              style={{
                                padding: "10px 10px 10px 20px",
                                border: 0,
                              }}
                            >
                              <img
                                src={Assets.ABOUTICON}
                                alt="about-icon"
                                style={{
                                  width: "27px",
                                  paddingRight: "10px",
                                  height: "17px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              />
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  fontFamily:
                                    "Inter Roboto Noto-Sans, sans-serif",
                                }}
                              >
                                About
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "20px",
                                border: 0,
                                borderTop: "1.5px solid #EDEDED",
                              }}
                            >
                              <table
                                bgcolor="#FFFFFF"
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                width="653px"
                                style={{
                                  width: "653px",
                                  maxWidth: "653px",
                                  minWidth: "653px",
                                  margin: "auto",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Date of Birth
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` :
                                        ${
                                          profileData?.data?.data?.dob ??
                                          "Not specified"
                                        } `}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Weight
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.weight?.name ??
                                        "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr> */}
                                  {/* <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                      
                                    >
                                      Blood Group
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                      
                                    >
                                      {` :${profileData?.data?.data?.weight?.name ?? "Not specified"}`  }
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Complexion
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.body_complexion
                                          ?.name ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Community
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.community_id
                                          ?.name ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Diocese
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.diocese_id
                                          ?.name ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="30px" />
                  </tr>
                  <tr>
                    <td>
                      <table
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="653px"
                        style={{
                          border: "1.5px solid #EDEDED",
                          borderRadius: "4px",
                          width: "653px",
                          maxWidth: "653px",
                          minWidth: "653px",
                          borderCollapse: "separate",
                          overflow: "hidden",
                          margin: "auto",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              bgcolor="#F8F8F8"
                              style={{
                                padding: "10px 10px 10px 20px",
                                border: 0,
                              }}
                            >
                              <img
                                src={Assets.FAMILYICON}
                                alt="family-icon"
                                style={{
                                  width: "27px",
                                  paddingRight: "10px",
                                  height: "17px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              />
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  fontFamily:
                                    "Inter Roboto Noto-Sans, sans-serif",
                                }}
                              >
                                Family
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "20px",
                                border: 0,
                                borderTop: "1.5px solid #EDEDED",
                              }}
                            >
                              <table
                                bgcolor="#FFFFFF"
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                width="653px"
                                style={{
                                  width: "653px",
                                  maxWidth: "653px",
                                  minWidth: "653px",
                                  margin: "auto",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Father Name
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.father_details
                                          ?.name ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Father Occupation
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.father_details
                                          ?.occupation?.name ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Mother Name
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.mother_details
                                          ?.name ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Mother Occupation
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.mother_details
                                          ?.occupation?.name ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Siblings Info
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                        verticalAlign: "top",
                                      }}
                                    >
                                      :
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          display: "inline-block",
                                          verticalAlign: "text-top",
                                        }}
                                      >
                                        {` No of Brother${getOrdinalNumbers(
                                          profileData?.data?.data
                                            ?.sibling_details?.no_of_brothers ??
                                            ""
                                        )}
                                      -
                                      ${
                                        profileData?.data?.data?.sibling_details
                                          ?.no_of_brothers ?? " Not specified"
                                      }`}
                                        <br />
                                        {`
                                      Brother${getOrdinalNumbers(
                                        profileData?.data?.data?.sibling_details
                                          ?.no_of_brothers ?? ""
                                      )}
                                      Married-
                                      ${
                                        profileData?.data?.data?.sibling_details
                                          ?.brothers_married ?? " Not specified"
                                      }

                                        `}
                                        <br />
                                        {` No of Sister${getOrdinalNumbers(
                                          profileData?.data?.data
                                            ?.sibling_details?.no_of_sisters ??
                                            ""
                                        )}
                                      -
                                      ${
                                        profileData?.data?.data?.sibling_details
                                          ?.no_of_sisters ?? " Not specified"
                                      } `}
                                        <br />
                                        {`
                                      Sister${getOrdinalNumbers(
                                        profileData?.data?.data?.sibling_details
                                          ?.no_of_sisters ?? ""
                                      )}
                                      Married-
                                      ${
                                        profileData?.data?.data?.sibling_details
                                          ?.sisters_married ?? " Not specified"
                                      }

                                        `}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="30px" />
                  </tr>
                  <tr>
                    <td>
                      <table
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="653px"
                        style={{
                          border: "1.5px solid #EDEDED",
                          borderRadius: "4px",
                          width: "653px",
                          maxWidth: "653px",
                          minWidth: "653px",
                          borderCollapse: "separate",
                          overflow: "hidden",
                          margin: "auto",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              bgcolor="#F8F8F8"
                              style={{
                                padding: "10px 10px 10px 20px",
                                border: 0,
                              }}
                            >
                              <img
                                src={Assets.EDUICON}
                                alt="edu-logo"
                                style={{
                                  width: "27px",
                                  paddingRight: "10px",
                                  height: "17px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              />
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  fontFamily:
                                    "Inter Roboto Noto-Sans, sans-serif",
                                }}
                              >
                                Education Details
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "20px",
                                border: 0,
                                borderTop: "1.5px solid #EDEDED",
                              }}
                            >
                              <table
                                border-top="1px solid #EDEDED"
                                bgcolor="#FFFFFF"
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                width="653px"
                                style={{
                                  width: "653px",
                                  maxWidth: "653px",
                                  minWidth: "653px",
                                  margin: "auto",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Education Category
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data
                                          ?.education_category?.name ??
                                        "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Qualification
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.education
                                          ?.name ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="30px" />
                  </tr>
                  <tr>
                    <td>
                      <table
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="653px"
                        style={{
                          border: "1.5px solid #EDEDED",
                          borderRadius: "4px",
                          width: "653px",
                          maxWidth: "653px",
                          minWidth: "653px",
                          borderCollapse: "separate",
                          overflow: "hidden",
                          margin: "auto",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              bgcolor="#F8F8F8"
                              style={{
                                padding: "10px 10px 10px 20px",
                                border: 0,
                              }}
                            >
                              <img
                                src={Assets.JOBICON}
                                alt="job-icon"
                                style={{
                                  width: "27px",
                                  paddingRight: "10px",
                                  height: "17px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              />
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  fontFamily:
                                    "Inter Roboto Noto-Sans, sans-serif",
                                }}
                              >
                                Job Details
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "20px",
                                borderTop: "1.5px solid #EDEDED",
                                border: 0,
                              }}
                            >
                              <table
                                border-top="1px solid #EDEDED"
                                bgcolor="#FFFFFF"
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                width="653px"
                                style={{
                                  width: "653px",
                                  maxWidth: "653px",
                                  minWidth: "653px",
                                  margin: "auto",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Occupation Category
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data
                                          ?.occupation_category?.name ??
                                        "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Work Location
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.work_state
                                          ?.name ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="30px" />
                  </tr>
                  {/*
                  <tr>
                    <td>
                      <table
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="653px"
                        style={{
                          border: "1.5px solid #EDEDED",
                          borderRadius: "4px",
                          width: "653px",
                          maxWidth: "653px",
                          minWidth: "653px",
                          borderCollapse: "separate",
                          overflow: "hidden",
                          margin: "auto",
                        }}
                      >
                         <tbody>
                          <tr>
                            <td
                              bgcolor="#F8F8F8"
                              style={{
                                padding: "10px 10px 10px 20px",
                                border: 0,
                              }}
                            >
                              <img
                                src={Assets.CONTACTICON}
                                alt="contact-icon"
                                style={{
                                  width: "27px",
                                  paddingRight: "10px",
                                  height: "17px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              />
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  fontFamily:
                                    "Inter Roboto Noto-Sans, sans-serif",
                                }}
                              >
                                Contact Details
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "20px",
                                borderTop: "1.5px solid #EDEDED",
                                border: 0,
                              }}
                            >
                              <table
                                border-top="1px solid #EDEDED"
                                bgcolor="#FFFFFF"
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                width="653px"
                                style={{
                                  width: "653px",
                                  maxWidth: "653px",
                                  minWidth: "653px",
                                  margin: "auto",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Phone Number
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                      width="50% font-weight:600;"
                                    >
                                      :
                                      <a
                                        href="tel:9585856525"
                                        style={{
                                          fontWeight: 660,
                                          textDecoration: "none",
                                          color: "#000",
                                          display:
                                            "inline-block table-column-group",
                                        }}
                                      >
                                        {`  ${
                                          profileData?.data?.data
                                            ?.phone_number ?? "Not specified"
                                        }`}
                                      </a>
                                      ,
                                      <a
                                        href="tel:8585968565"
                                        style={{
                                          fontWeight: 600,
                                          textDecoration: "none",
                                          color: "#000",
                                          display: "inline-block",
                                        }}
                                      >
                                        8585968565
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ height: "14px" }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        color: "#565F6C",
                                         width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                      }}
                                    >
                                      Address
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        width: "50%",
                                        fontFamily:
                                          "Inter Roboto Noto-Sans, sans-serif",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {` : ${
                                        profileData?.data?.data?.present_address
                                          ?.house_name ?? "Not specified"
                                      },
                                          ${
                                            profileData?.data?.data
                                              ?.present_address?.street ?? "Not specified"
                                          },
                                          ${
                                            profileData?.data?.data
                                              ?.present_address?.street ?? "Not specified"
                                          },
                                          ${
                                            profileData?.data?.data
                                              ?.present_address?.district
                                              ?.name ?? "Not specified"
                                          },${
                                        profileData?.data?.data?.present_address
                                          ?.pincode ?? "Not specified"
                                      }`}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody> 
                      </table>
                    </td>
                  </tr>
                  */}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  );
});

export default ExportProfileDetails;
