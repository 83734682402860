import { getAxiosInstance } from "../../../api";

export const getPackagesData = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get("admin/packages/all");
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getTypesData = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get("admin/packages/data");
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const addPayment = async (formData) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/payments/create", formData);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getPaymentModes = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get("admin/payment/modes");
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getUserDetails = async ({ search }) => {

  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/payments/users?search=${search}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
