import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateVerificationDataStatus } from "../../../../store/slices/Users/verificationSlice";

const useProfileCard = ({
  data,
  activeID,
  refetch,
  setShowImageVerificationModal,
}) => {
  const dispatch = useDispatch();
  const [showReasonSelect, setShowReasonSelect] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeReason, setActiveReason] = useState({});
  const [reasonError, setReasonError] = useState(false);

  const reasons = useMemo(() => {
    return data.profileRejectResponse.map((r) => {
      return { label: r.reason, value: r._id };
    });
  }, [data]);

  const handleReject = () => {
    setShowReasonSelect(true);
  };

  const cancelRejectClick = () => {
    setShowReasonSelect(false);
  };

  const handleThumbClick = (index) => {
    setActiveImageIndex(index);
  };

  const handleReasonChange = (reason) => {
    setReasonError(false);
    setActiveReason(reason);
  };

  const handleApprove = () => {
    dispatch(
      updateVerificationDataStatus({
        user_id: activeID,
        upload_id: data.profileData[activeID][activeImageIndex]._id,
        upload_type: 1,
        status: 1,
      })
    )
      .unwrap()
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setShowImageVerificationModal(false);
          refetch?.();
        }
      });
  };

  const handleRejectSubmit = () => {
    if (activeReason?.value) {
      dispatch(
        updateVerificationDataStatus({
          user_id: activeID,
          upload_id: data.profileData[activeID][activeImageIndex]._id,
          reason: activeReason.value,
          upload_type: 1,
          status: 2,
        })
      )
        .unwrap()
        .then((result) => {
          if (result?.data?.status_code === 200) {
            setShowImageVerificationModal(false);
            refetch?.();
          }
        });
    } else {
      setReasonError(true);
    }
  };

  return {
    showReasonSelect,
    activeImageIndex,
    activeReason,
    reasons,
    reasonError,
    handleReject,
    cancelRejectClick,
    handleThumbClick,
    handleReasonChange,
    handleApprove,
    handleRejectSubmit,
  };
};

export default useProfileCard;
