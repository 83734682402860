import { useState } from "react";
import Select from "react-select";
import Style from "../selectStatusButton.module.scss";

const StatusButton = ({
  data,
  setshowStatusUpdateModal,
  setSelectedId,
  setCurrentStatus,
}) => {
  
  const [status, setStatus] = useState(
    data?.status === "Open"
      ? { value: 1, label: "Open" }
      : { value: 0, label: `${data?.status === "Close" ? "Closed" : "Close"}` }
  );

  const options = [
    { value: 1, label: "Open" },
    { value: 0, label: "Close" },
  ];

  const handleChange = (selectedOption, id) => {
    setCurrentStatus(selectedOption);
    //   setStatus(selectedOption);
    setshowStatusUpdateModal(() => true);
    setSelectedId(id);
  };

  return (
    <div className="col-12 no-border" style={{ minWidth: "222px" }}>
      <Select
        options={options?.filter((item) => item.value !== status.value)}
        value={status}
        onChange={(e) => handleChange(e, data?._id)}
        className={Style.reactSelectContainer}
        classNamePrefix="pro-input"
      />
    </div>
  );
};

export default StatusButton;
