import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  matchesStatus: "idle",
  matchesData: {},
  matchesPreferenceStatus: "idle",
  matchesPreferenceData: {},
  matchesSelectedFilters: {},
  matchesPermissions: [],
  matchesFilters: {},
  selectedProfiles: [],
  isFiltersEdited: false,
  currentPage: 1,
};

export const getMatchDetails = createAsyncThunk(
  "match/getMatchDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `admin/members/profile/matches?member_id=${params.member_id}&page_size=21&page=${params?.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPartnerPreferenceFilter = createAsyncThunk(
  "partnerPreference/getPartnerPreferenceFilter",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `admin/members/profile/partner-preferences`,
        {
          ...params,
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const matchesSlice = createSlice({
  name: "matches",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getMatchDetails.pending]: (state, action) => {
      state.matchesStatus = "pending";
    },
    [getMatchDetails.fulfilled]: (state, action) => {
      if (!state.isFiltersEdited) {
        state.matchesSelectedFilters = {
          ...action?.payload?.data?.data?.partner_preferences,
        };
      }
      state.matchesPermissions = action?.payload?.data?.data?.permission;

      let newArry = state.matchesData[action.meta?.arg?.member_id]
        ? current(state.matchesData[action.meta?.arg?.member_id]?.data ?? [])
        : [];

      let tempArry = [
        ...newArry,
        ...(action?.payload?.data?.data?.profiles?.data
          ? action?.payload?.data?.data?.profiles?.data
          : []),
      ];

      state.selectedProfiles = tempArry
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?._id);

      if (
        state.matchesData?.[action.meta?.arg?.member_id] &&
        !action.meta?.arg?.clearState
      ) {
        state.matchesData[action.meta?.arg?.member_id] = {
          ...action.payload?.data?.data?.profiles,
          data: [
            ...state.matchesData[action.meta?.arg?.member_id].data,
            ...action.payload?.data?.data?.profiles?.data,
          ],
        };
      } else {
        state.matchesData[action.meta?.arg?.member_id] = {
          ...action.payload?.data?.data?.profiles,
          page: 1,
        };
      }
      state.matchesStatus = "fulfilled";
    },
    [getMatchDetails.rejected]: (state) => {
      state.matchesStatus = "failed";
    },
    [getPartnerPreferenceFilter.pending]: (state) => {
      state.matchesPreferenceStatus = "pending";
    },
    [getPartnerPreferenceFilter.fulfilled]: (state, action) => {
      let newArray = state.matchesPreferenceData[action.meta?.arg?.member_id]
        ? current(
            state.matchesPreferenceData[action.meta?.arg?.member_id]?.data ?? []
          )
        : [];

      let tempArry = [
        ...newArray,
        ...(action?.payload?.data?.data?.data
          ? action?.payload?.data?.data?.data
          : []),
      ];

      state.selectedProfiles = tempArry
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?._id);

      let newArry =
        action.payload?.data?.data?.length === 0
          ? []
          : action.payload?.data?.data?.data;

      if (state.matchesPreferenceData?.[action.meta?.arg?.member_id]) {
        state.matchesPreferenceData[action.meta?.arg?.member_id] = {
          ...action.payload?.data?.data,
          data: state.matchesPreferenceData?.[action.meta?.arg?.member_id]?.data
            ? [
                ...state.matchesPreferenceData?.[action.meta?.arg?.member_id]
                  ?.data,
                ...newArry,
              ]
            : [...newArry],
        };
      } else {
        state.matchesPreferenceData[action.meta?.arg?.member_id] = {
          ...action.payload?.data?.data,
          page: 1,
        };
      }

      state.matchesPreferenceStatus = "fulfilled";
    },
    [getPartnerPreferenceFilter.rejected]: (state) => {
      state.matchesPreferenceStatus = "failed";
    },
  },
});

export const { updateConfig } = matchesSlice.actions;

export default matchesSlice.reducer;
