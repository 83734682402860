import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
  OffCanvasLayout,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useIncomplete from "./useIncomplete";
import MemberCreationForm from "../Members/MemberCreationForm";
import EmptyData from "../../Global/EmptyData";
import { ProfileCard } from "../../Global/ProfileCard";

const Incomplete = () => {
  const {
    incomplete,
    incompleteState,
    currentPage,
    activeFilter,
    isFetching,
    isLoading,
    membersState,
    actionOptions,
    profileData,
    imageSlider,
    closeImageCardModal,
    handleActionChange,
    hasExportPermission,
    hasEditPermission,
    selectedUser,
    activeImageIndex,
    setActiveImageIndex,
    handleDeleteImage,
    handleActionDeleteImage,
    closeDeleteImageModal,
    show,

    setShow,
    showDeleteImageModal,
    setShowDeleteImageModal,
    refetch,
    handleSort,
    closeModal,
    getRow,
    handleFilter,
    handlePageSize,
    handlePagination,
    handleSearch,
    paginationOptions,
    handleEditClick,
    closeEditModal,
    showEditModal,
    updateIncompletedFields,
    handleClearClick,
    // hasCreatePermission,
    // hasViewPermission,
    // hasEditPermission,
  } = useIncomplete();

  return (
    <>
      <HeadingGroup title={"Incomplete"} className={`pro-mb-4`} />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={incomplete?.data?.filters}
          activeFilter={activeFilter}
          handleButtonGroupChange={handleFilter}
          onSearchInput={handleSearch}
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: incompleteState.search }}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          actionOptions={actionOptions?.filter((item) =>
            hasExportPermission !== 1 ? item.value === 0 : true
          )}
          onActionOptionChange={handleActionChange}
        />
        {incomplete?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={incomplete?.data?.data?.data}
            uniqueID={"user_id"}
            fields={incomplete?.data?.fields}
            showCheckBox={false}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={incompleteState.currentPageSize}
          />
        )}

        {incomplete?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={incomplete?.data?.data?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === incompleteState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={membersState.showCreateModal}
          handleClose={closeModal}
          title={`${"Update Member Details"}`}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <MemberCreationForm />
        </OffCanvasLayout>

        <ModalLayout show={show} handleClose={closeImageCardModal}>
          <ProfileCard
            data={imageSlider?.sort((a, b) => b.isPrimary - a.isPrimary)}
            profileData={profileData}
            handleClose={closeImageCardModal}
            userId={selectedUser}
            deletable={false}
            activeImageIndex={activeImageIndex}
            setActiveImageIndex={setActiveImageIndex}
            refetch={refetch}
            handleDeleteImage={handleDeleteImage}
            closeDeleteImageModal={closeDeleteImageModal}
            setShow={setShow}
            showDeleteImageModal={showDeleteImageModal}
            setShowDeleteImageModal={setShowDeleteImageModal}
            hasUpdatePermission={hasEditPermission}
          />
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteImageModal}
          handleClose={closeDeleteImageModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete this Image?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={closeDeleteImageModal}
              submitAction={() => handleActionDeleteImage()}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={incomplete?.data?.fields}
              moduleId={incomplete?.data?.module_id}
              updateData={updateIncompletedFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Incomplete;
