import { Button, HeadingGroup } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddPaymentForm from "./useAddPaymentForm";
import PaymentFields from "../AddPaymentForm/PaymentFields/index";

const AddPaymentForm = ({
  profilePayment = false,
  activeProfile,
  refetch,
  selectedUser,
  setSkip,
  setSelectedUser,
}) => {
  const {
    formik,
    loading,
    selectedUserId,
    userData,
    handleCloseModal,
    handleProofImage,
    handleUserId,
    imageFileInputRef,
    packagesOptions,
    paymentsOptions,
    useDataOptions,
    typesOptions,
    handleInputChange,
  } = useAddPaymentForm({
    activeProfile,
    profilePayment,
    refetch,
    selectedUser,
    setSelectedUser,
    setSkip,
  });

  return (
    <div className={`pro-w-100`}>
      <div className="row pro-pb-4">
        {profilePayment ? (
          <div className="col-8">
            <HeadingGroup title={"Add Payment"}></HeadingGroup>
          </div>
        ) : (
          <></>
        )}
        {profilePayment ? (
          <div className={`pro-d-flex pro-justify-end col-4`}>
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCloseModal()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              {loading ? (
                <i
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              Add payment
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row">
        <div className={`${profilePayment ? "col-8" : "col-12"}`}>
          <PaymentFields
            formik={formik}
            handleProofImage={handleProofImage}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            selectedUserId={selectedUserId}
            userData={userData}
            handleUserId={handleUserId}
            setSkip={setSkip}
            imageFileInputRef={imageFileInputRef}
            packagesOptions={packagesOptions}
            paymentsOptions={paymentsOptions}
            typesOptions={typesOptions}
            useDataOptions={useDataOptions}
            profilePayment={profilePayment}
            handleInputChange={handleInputChange}
          />
        </div>

        {!profilePayment ? (
          <div
            className={`pro-d-flex pro-justify-end  ${
              !profilePayment ? "col-12" : "col-6"
            }`}
          >
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCloseModal()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              {loading ? (
                <i
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              Add payment
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AddPaymentForm;
