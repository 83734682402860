import React from "react";
import { UserCard } from "../../Global/UserCard";
import useShortlisted from "./useShortlisted";
import ErrorMessage from "../../Global/ErrorMessage";
import ProfileCardShimmer from "../../Global/Shimmers/ProfileCardShimmer";

const Shortlisted = () => {
  const {
    state,
    activeProfile,
    shortListedState,
    showScroll,
    overViewData,
    hasRemovePermission,
    handleScrollToTop,
    lastElement,
    handleSelect,
    handleProfileRedirect,
  } = useShortlisted();

  return (
    <>
      {state?.shortListedStatus === "pending" && !shortListedState?.data ? (
        <div className="pro-p-4">
          <ProfileCardShimmer />
        </div>
      ) : (
        <>
          <div className="pro-m-5 pro-d-flex pro-flex-wrap">
            {showScroll && (
              <button
                className={`scoll-top`}
                onClick={() => handleScrollToTop()}
              >
                <span className="material-symbols-outlined">expand_less</span>
              </button>
            )}
            {shortListedState?.data?.map((value, index) => {
              return (
                <UserCard
                  key={index}
                  isCheckBox={
                    hasRemovePermission === 1 &&
                    overViewData?.data?.data?.assigned_profile === 1
                      ? true
                      : false
                  }
                  isPremium={Boolean(Number(value?.profile_verified_status))}
                  age={value?.age}
                  checked={state?.selectedProfiles?.filter?.(
                    (a) => a !== activeProfile
                  )}
                  data={value || {}}
                  handleProfileRedirect={handleProfileRedirect}
                  handleSelect={handleSelect}
                  src={value?.profile_image}
                  title={value?.first_name}
                  subtitle={value?.last_name}
                  shortlisteCard={true}
                  isShortlisted={false}
                  lastElement={
                    index === shortListedState?.data?.length - 10
                      ? lastElement
                      : false
                  }
                  ViewCount={value?.view_count}
                  graphIcon={
                    <span className="material-symbols-outlined">
                      auto_graph
                    </span>
                  }
                  GraphPercentage={value?.profile_percentage}
                  ViewIcon={
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  }
                />
              );
            })}

            {(shortListedState?.data?.length === undefined ||
              shortListedState?.data?.length === 0) &&
            state?.shortListedStatus !== "pending" ? (
              <ErrorMessage />
            ) : (
              <></>
            )}
          </div>
        </>
      )}

      {state?.shortListedStatus === "pending" && shortListedState?.data && (
       <div className="pro-w-100 pro-mb-4">
          <ProfileCardShimmer loading />
        </div>
      )}
    </>
  );
};

export default Shortlisted;
