import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import AddFormContainer from "./AddForm";
import { useSelector } from "react-redux";
import useDeletedAccounts from "./useDeletedAccounts";
import EmptyData from "../../Global/EmptyData";
import Style from "./deleted.module.scss";

const Deleted = ({ dashboard = false }) => {
  const {
    deletedAccountList,
    deletedAccounts,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasExportPermission,
    handleActionChange,
    refetch,
    handlePagination,
    handleDashboardRedirect,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateDeletedAccountsListFields,
    handleClearClick,
  } = useDeletedAccounts();


  const { editDiocese } = useSelector((state) => state.dioceseListingPage);

  return (
    <>
      {!dashboard ? (
        <HeadingGroup
          title={"Deleted Accounts"}
          className={`pro-mb-4`}
          buttonTitle={""}
          handleClick={() => {
            setShowform(true);
          }}
        />
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard ? (
          <SearchFilters
            data={deletedAccountList?.data?.filters}
            onDateChange={handleDateChange}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            showClearFilters
            handleClear={handleClearClick}
            searchInputProps={{ value: deletedAccounts?.search }}
            actionOptions={actionOptions?.filter((item) =>
              hasExportPermission !== 1 ? item.value === 0 : true
            )}
            onActionOptionChange={handleActionChange}
          />
        ) : (
          <></>
        )}

        {deletedAccountList?.data?.data?.data?.length === 0 ? (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : Style.dashboardTable}
          >
            <EmptyData />

            {/* ith empty data aanu table alla.... */}
          </div>
        ) : (
          <Table
            multiSelect={false}
            data={deletedAccountList?.data?.data?.data || []}
            uniqueID={"_id"}
            fields={deletedAccountList?.data?.fields}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={deletedAccounts?.currentPageSize}
            assignable={false}
            handleEdit={handleEditAction}
            handleDelete={handleDelete}
            deletable={true}
            showCheckBox={false}
            extraClasssName={'dashboard_no_padding'}
          />
        )}

        {deletedAccountList?.data?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              deletedAccountList?.data?.data?.total / deletedAccounts?.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === deletedAccounts?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        {!dashboard ? (
          <>
            {" "}
            <ModalLayout show={showEditModal} handleClose={closeEditModal}>
              <div className="pro-m-5">
                <OrderColumn
                  title={"Choose which columns you see"}
                  refetchData={refetch}
                  tableFields={deletedAccountList?.data?.fields}
                  moduleId={deletedAccountList?.data?.module_id}
                  updateData={updateDeletedAccountsListFields}
                />
              </div>
            </ModalLayout>
            <ModalLayout
              centered={false}
              show={showDeleteConfirm}
              handleClose={setShowDeleteConfirm}
            >
              <div className="pro-m-5">
                <ConfirmationBox
                  title={`Are you sure you want to delete this item?`}
                  isRight={true}
                  cancelText={`No`}
                  submitText={`Yes`}
                  cancelAction={setShowDeleteConfirm}
                  submitAction={handleDeleteAction}
                >
                  ConfirmationBox
                </ConfirmationBox>

                {true && <span className="error-message">{true}</span>}
              </div>
            </ModalLayout>
            <ModalLayout
              centered={false}
              show={showform}
              handleClose={() => {
                setShowform(false);
              }}
              title={
                editDiocese === true ? "Edit Testimonial" : "Add Testimonial"
              }
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              <AddFormContainer setShowform={setShowform} refetch={refetch} />
            </ModalLayout>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Deleted;
