import React from "react";
import Style from "./addStaffForm.module.scss";
import AdminProfileCard from "../../../Global/AdminProfileCard";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const AddFormFields = ({
  formikEditUserData,
  formikEditPassword,
  isDataLoading,
  userData,
  setProfileViewVisible,
  showExistingPassword,
  showNewPassword,
  handleShowExistingPassword,
  handleShowNewPassword,
}) => {
 
  // isDataLoading === "success" ? 
  return (
    <div className={Style.root}>
      <div className={Style.item}>
        <AdminProfileCard userData={userData} />
      </div>
      <div className={Style.item}>
        <h5>Contact info</h5>
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label
                htmlFor="email"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Email
              </label>
              <input
                type="email"
                className={`pro-input lg ${
                  formikEditUserData.errors.email &&
                  formikEditUserData.touched.email &&
                  "error"
                }`}
                id="email"
                name="email"
                {...formikEditUserData.getFieldProps("email")}
              />
              {formikEditUserData.touched.email &&
                formikEditUserData.errors.email && (
                  <span className="error-text">
                    {formikEditUserData.errors.email}
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="input-wrap">
              <label
                htmlFor="phoneNumber"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Phone number
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formikEditUserData.errors.phoneNumber &&
                  formikEditUserData.touched.phoneNumber &&
                  "error"
                }`}
                id="phoneNumber"
                name="phoneNumber"
                {...formikEditUserData.getFieldProps("phoneNumber")}
              />
              {formikEditUserData.touched.phoneNumber &&
                formikEditUserData.errors.phoneNumber && (
                  <span className="error-text">
                    {formikEditUserData.errors.phoneNumber}
                  </span>
                )}
            </div>
          </div>
          <div className={`col-12 pro-mt-4 pro-d-flex pro-justify-end`}>
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => setProfileViewVisible(false)}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formikEditUserData.handleSubmit}
              type="submit"
            >
              {true ? (
                <i
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              Update
            </Button>
          </div>
        </div>
      </div>

      <div className={Style.item}>
        <h5>Change Password</h5>
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap p-re">
              <label
                htmlFor="existingPassword"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Existing password
              </label>
              <input
                type="password"
                className={`pro-input lg ${
                  formikEditPassword.errors.existingPassword &&
                  formikEditPassword.touched.existingPassword &&
                  "error"
                }`}
                id="existingPassword"
                name="existingPassword"
                {...formikEditPassword.getFieldProps("existingPassword")}
              />
              {showExistingPassword ? (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowExistingPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowExistingPassword(e)}
                >
                  <span className="material-symbols-outlined"> visibility </span>
                </button>
              )}
              {formikEditPassword.touched.existingPassword &&
                formikEditPassword.errors.existingPassword && (
                  <span className="error-text">
                    {formikEditPassword.errors.existingPassword}
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="input-wrap p-re">
              <label
                htmlFor="newPassword"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                New password
              </label>
              <input
                type="password"
                className={`pro-input lg ${
                  formikEditPassword.errors.newPassword &&
                  formikEditPassword.touched.newPassword &&
                  "error"
                }`}
                id="newPassword"
                name="newPassword"
                {...formikEditPassword.getFieldProps("newPassword")}
              />
              {showNewPassword ? (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowNewPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowNewPassword(e)}
                >
                  <span className="material-symbols-outlined"> visibility </span>
                </button>
              )}
              {formikEditPassword.touched.newPassword &&
                formikEditPassword.errors.newPassword && (
                  <span className="error-text">
                    {formikEditPassword.errors.newPassword}
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  //  : isDataLoading === "loading" ? (
  //   <div>Loading...</div>
  // ) : (
  //   ""
  // );
};

export default AddFormFields;
