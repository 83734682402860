import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/payment/transactionsSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetTransactionsListDataQuery,
  useUpdateTransactionsListDataMutation,
} from "../../../store/queries/payment";
import { getFormatedDate } from "../../../utils/functions/table";
import { addDays } from "date-fns";
import { useGetProfilePaymentListDataQuery } from "../../../store/queries/Profile";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { getExportedData } from "./api";
import { ProfileNavigation } from "../../Global/ProfileNavigation";

const useTransactions = (dashboard) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const TransactionsState = useSelector((state) => state.transactions);
  const [selectedUser, setSelectedUser] = useState([]);
  const [activeTab, setActiveTab] = useState("payment");
  const { showCreateModal, transactionId, showInvoiceModal, selectedUserId } =
    useSelector((state) => state.transactions);
  const [skip, setSkip] = useState(true);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const {
    data: transactions = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetTransactionsListDataQuery({
    sort_by: TransactionsState.sortBy,
    sort_order: TransactionsState.sortOrder,
    search: TransactionsState.search,
    start: TransactionsState.startDate,
    end: TransactionsState.endDate,
    page_size: TransactionsState.currentPageSize,
    page: TransactionsState.currentPage,
  });

  const { data: profilePaymentHistory = {} } =
    useGetProfilePaymentListDataQuery(
      {
        user_id: selectedUser,
        page: 1,
      },
      { skip }
    );

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const [updateTransactionFields] = useUpdateTransactionsListDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = transactions?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [transactions]);

  const hasExportPermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [transactions]);

  const hasCreatePermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [transactions]);

  const hasViewPermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [transactions]);

  const handleSort = (label) => {
    if (TransactionsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder =
            TransactionsState.sortOrder === "asc" ? "dsc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    setActiveTab("payment");
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.selectedUserId = "";
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      profile_id: (_, data) => (
        <ProfileNavigation
          userId={data?.user_id}
          fieldValue={data?.profile_id}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={hasViewPermission === 1 ? true : false}
        />
      ),
      action: (feild, data) =>
        hasViewPermission === 1 && !dashboard ? (
          <p
            className="pro-mb-0 pro-pnt"
            onClick={() => handleInvoiceClick(feild, data)}
          >
            Print Invoice
          </p>
        ) : (
          <></>
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleInvoiceClick = (feild, data) => {
    if (hasViewPermission) {
      dispatch(
        updateConfig((state) => {
          state.showInvoiceModal = true;
          state.invoiceLoading = true;
          state.transactionId = data?._id;
        })
      );
    }
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeInvoiceModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showInvoiceModal = false;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(transactions?.data?.fields),
        filter: TransactionsState.currentFilter,
        sort_by: TransactionsState.sortBy,
        sort_order: TransactionsState.sortOrder,
        start: TransactionsState.startDate,
        end: TransactionsState.endDate,
        search: TransactionsState.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = null;
        state.endDate = null;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.transactionId = "";
        state.invoiceLoading = false;
      })
    );
  };

  const handleActiveTab = (switchTab) => {
    if (switchTab === "history" && selectedUserId) {
      setActiveTab(switchTab);
    }
    if (switchTab === "payment") {
      setActiveTab(switchTab);
      // setSelectedUser([]);
      // dispatch(
      //   updateConfig((state) => {
      //     state.selectedUserId = [];
      //   })
      // );
    }
  };

  let TransactionItems = {
    total_collection: {
      label: "total_collection",
      title: "Total Collection",
      icon: "calendar_month",
    },
    today_collection: {
      label: "today_collection",
      title: "Today",
      icon: "headset_mic",
    },
    weekly_collection: {
      label: "weekly_collection",
      title: "This week",
      icon: "edit_document",
    },
    monthly_collection: {
      label: "monthly_collection",
      title: "This month",
      icon: "manage_accounts",
    },
  };
  const handleDashboardRedirect = (filter) => {
    navigate("/payment/transactions");
  };

  return {
    transactions,
    TransactionsState,
    TransactionItems,
    currentPage: TransactionsState.currentPage,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    activeTab,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    showInvoiceModal,
    transactionId,
    profilePaymentHistory: profilePaymentHistory?.data,
    selectedUser,
    actionOptions,
    hasExportPermission,
    setSkip,
    handleDashboardRedirect,
    handleActionChange,
    setSelectedUser,
    refetch,
    updateTransactionFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleActiveTab,
    closeInvoiceModal,
    handleClearClick,
  };
};

export default useTransactions;
