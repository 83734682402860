import { useCallback, useRef } from "react";

/**
 * @param Object loading, hasMore, callback
 * @returns lastElement
 */
const useObserver = ({ loading, hasMore, callback }) => {
  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            callback?.();
          }
        },
        { threshold: 0.9 }
      );
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line
    [loading, hasMore]
  );
  return {
    lastElement,
  };
};

export default useObserver;
