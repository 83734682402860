import { Button, HeadingGroup } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import FirstPart from "./FirstPart";
import SecondPart from "./SecondPart";
import usePreferenceForm from "./usePreferenceForm";
import MatchPrefecenceShimmer from "../../Global/Shimmers/MatchPrefecenceShimmer";

const PreferenceForm = ({
  optionsData,
  isOptionDataSuccess,
  selectedPreferenceFilter,
  handleSearch,
  formik,

  handleAgeRangeChange,
  handleHeightRangeChange,
  handleWeightRangeChange,
  onCloseIconClick,
  handleSubmit,
  handleReset,
}) => {
  //  #FIXME ashwin add shimmer here

  const { filteredStateOptions, filteredDistrictOptions,filteredDioceses } = usePreferenceForm({
    formik,
    optionsData,
  });
  return (
    <div>
      {isOptionDataSuccess ? (
        <>
          <HeadingGroup
            title={"Partner Preferences"}
            // extraClassName={`${style.fixed_position}`}
          >
            <div className="pro-check-box pro-items-center">
              <Button
                className={"pro-btn-link pro-px-5 pro-mx-2"}
                onClick={() => handleReset()}
              >
                Reset
              </Button>
              <Button
                type="submit"
                // className={`pro-btn pro-btn-primary ${
                //   props?.state.PreferenceFormStatus === "pending" ? "loading" : ""
                // }`}
                className={"pro-btn pro-btn-primary"}
                onClick={formik.handleSubmit}
              >
                Search
              </Button>
              {selectedPreferenceFilter?.length !== 0 && (
                <div className={""}>
                  <div className={""}>
                    <button
                      className={`pro-btn-link pro-p-2`}
                      onClick={() => onCloseIconClick()}
                    >
                      <span className="material-symbols-outlined">close</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </HeadingGroup>
          <div style={{ marginTop: 30 }}>
            <FirstPart
              optionsData={optionsData}
              formik={formik}
              handleAgeRangeChange={handleAgeRangeChange}
              handleHeightRangeChange={handleHeightRangeChange}
              handleWeightRangeChange={handleWeightRangeChange}
              filteredDioceses={filteredDioceses}
            />
            <SecondPart
              optionsData={optionsData}
              formik={formik}
              filteredStateOptions={filteredStateOptions}
              filteredDistrictOptions={filteredDistrictOptions}
            />
          </div>
        </>
      ) : (
        <MatchPrefecenceShimmer profileCard={false} />
      )}
    </div>
  );
};

export default PreferenceForm;
