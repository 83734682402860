import React from "react";
import {
  SimpleReport,
  DataContainer,
} from "@wac-ui-dashboard/wac_component_library";
import useFavorites from "./useFavorites";
import { UserCard } from "../../Global/UserCard";
import ErrorMessage from "../../Global/ErrorMessage";
import SimpleReportShimmer from "../../Global/Shimmers/SimpleReportShimmer";
import ProfileCardShimmer from "../../Global/Shimmers/ProfileCardShimmer";

const Favorites = () => {
  const {
    state,
    reportData,
    activeProfile,
    favouritesState,
    handleSelect,
    showScroll,
    handleScrollToTop,
    handleProfileRedirect,
    lastElement,
  } = useFavorites();

  return (
    <div className="pro-m-4 pro-mx-5">
      {state?.favouritesStatus === "pending" && !favouritesState?.data ? (
        <>
          <div className="pro-p-5">
            <SimpleReportShimmer />
            <ProfileCardShimmer />
          </div>
        </>
      ) : (
        <>
          {showScroll && (
            <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
              <span className="material-symbols-outlined">expand_less</span>
            </button>
          )}

          <DataContainer>
            <SimpleReport
              data={reportData}
              // showButton
              // buttonText={<span className="material-symbols-outlined">tune</span>}
            />
          </DataContainer>

          <div className="pro-m-5 pro-d-flex pro-flex-wrap">
            {favouritesState?.data?.map((value, index) => {
              return (
                <UserCard
                  key={index}
                  isCheckBox={false}
                  isPremium={Boolean(Number(value?.profile_verified_status))}
                  age={value?.age}
                  checked={state?.selectedProfiles?.filter?.(
                    (a) => a !== activeProfile
                  )}
                  data={value || {}}
                  handleProfileRedirect={handleProfileRedirect}
                  handleSelect={handleSelect}
                  src={value?.profile_image ?? ""}
                  title={value?.first_name ?? ""}
                  subtitle={value?.last_name ?? ""}
                  isShortlisted={Boolean(Number(value?.short_listed ?? 0))}
                  lastElement={
                    index === favouritesState?.data?.length - 10
                      ? lastElement
                      : false
                  }
                  ViewCount={value?.view_count ?? 0}
                  graphIcon={
                    <span className="material-symbols-outlined">
                      auto_graph
                    </span>
                  }
                  GraphPercentage={value?.profile_percentage}
                  ViewIcon={
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  }
                />
              );
            })}
          </div>
        </>
      )}
      {(favouritesState?.data?.length === undefined ||
        favouritesState?.data?.length === 0) &&
      state?.favouritesStatus !== "pending" ? (
        <ErrorMessage />
      ) : (
        <></>
      )}

      {state?.favouritesStatus === "pending" && favouritesState?.data && (
       <div className="pro-w-100 pro-mb-4">
          <ProfileCardShimmer loading />
        </div>
      )}
    </div>
  );
};

export default Favorites;
