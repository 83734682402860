import Assets from "../../../assets/Assets";
import Style from "./adminProfileCard.module.scss";

const AdminProfileCard = ({ userData }) => {
  // const data = userData.data.data;

  return (
    <div className={Style.root}>
      <div className={Style.img_wrap}>
        <figure className="pro-mb-0">
          {userData?.image_url ? (
            <img src={userData?.image_url} alt="staff_image" />
          ) : userData?.image_url === null && userData?.gender === null ? (
            <img src={Assets.ACCOUNT_CIRCLE} alt="staff_image" />
          ) : (
            <img
              src={
                userData?.gender === 2
                  ? Assets.FEMALEPROFILE
                  : Assets.MALEPROFILE
              }
              alt="staff_image"
            />
          )}
        </figure>
      </div>
      <div className="pro-ps-4 pro-pt-1 pro-pb-2 pro-d-flex pro-flex-column">
        {userData?.first_name && (
          <h4 className="pro-ttl h4 pro-mb-1">
            {(userData?.first_name ?? " ") + " " + (userData?.last_name ?? "")}
          </h4>
        )}
        {<p className="pro-mb-2">{userData?.role}</p>}
        <ul
          className={`pro-d-flex pro-align-center ${
            userData?.branch?.name ? Style.list : ""
          }`}
        >
          <li>{userData?.profile_id ?? ""}</li>
          <li>{userData?.branch?.name ?? ""}</li>
        </ul>
      </div>
    </div>
  );
};

export default AdminProfileCard;
