import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Users/messagesSlice";
import { getFormatedDate } from "../../../utils/functions/table";

import {
  useGetMessagesListDataQuery,
  useUpdateMessagesListFieldsDataMutation,
} from "../../../store/queries/users";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import StatusButton from "./StatusButton";

import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { useSearchParams } from "react-router-dom";

const useMessages = (dashboard) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const messages = useSelector((state) => state.messages);
  const [showStatusUpdateModal, setshowStatusUpdateModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const { showEditModal } = useSelector((state) => state.global);

  const [params] = useSearchParams();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [updateMessagesListFields] = useUpdateMessagesListFieldsDataMutation();

  const {
    data: messagesList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetMessagesListDataQuery({
    page_size: messages.currentPageSize,
    page: messages.currentPage,
    filter: messages.currentFilter,
    sort_by: messages.sortBy,
    sort_order: messages.sortOrder,
    start: messages.startDate,
    end: messages.endDate,
    search: messages.search,
  });

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const filters = useMemo(() => {
    if (messagesList?.data?.filters) {
      const buttonGroups = messagesList?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const hasUpdatePermission = useMemo(() => {
    let permission = messagesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [messagesList]);

  const hasViewPermission = useMemo(() => {
    let permission = messagesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [messagesList]);

  const hasExportPermission = useMemo(() => {
    let permission = messagesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [messagesList]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.startDate = null;
          state.endDate = null;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleSort = (label) => {
    if (messages.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = messages.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  // const handleAction = (id) => {
  //   setshowStatusUpdateModal(() => true);
  //   setSelectedId(id);
  // };

  

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) =>
        data?.status === "Closed" ? (
          <p className="pro-mb-0">{data[feild] ?? ""}</p>
        ) : (
          <div className="col-12 no-border" style={{ minWidth: "222px" }}>
            {hasUpdatePermission === 1 && !dashboard ? (
              // <Select
              //   options={options}
              //   menuPlacement={`auto`}
              //   menuPosition={"auto"}
              //   placeholder={"Action"}
              //   onChange={() => handleAction(data?._id)}
              //   className={Style.reactSelectContainer}
              //   classNamePrefix="pro-input"
              // />
              <StatusButton
                data={data}
                setshowStatusUpdateModal={setshowStatusUpdateModal}
                setSelectedId={setSelectedId}
                setCurrentStatus={setCurrentStatus}
              />
            ) : (
              <p className="pro-mb-0">{data[feild] ?? ""}</p>
            )}
          </div>
        ),
      name: (_, data) => (
        <ProfileNavigation
          userId={data?.member_id}
          fieldValue={data?.name}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={hasViewPermission === 1 ? true : false}
        />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.showEditModal = false;
        state.selectedItemsDetails = "";
        state.search = "";
      })
    );
  };
  const handleDashboardRedirect = (filter) => {
    navigate("/support/complaints");
  };

  const closeActionModal = () => {
    setshowStatusUpdateModal(() => false);
  };

  return {
    messagesList,
    messages,
    showStatusUpdateModal,
    currentPage: messages.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    filters,
    selectedId,
    currentStatus,
    hasExportPermission,
    activeFilter: messages.currentFilter,
    handleFilter,
    handleDashboardRedirect,
    closeActionModal,
    handleActionChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    updateMessagesListFields,
    handleClearClick,
  };
};

export default useMessages;
