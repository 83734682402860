import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  favouritesStatus: "idle",
  favouritesData: {},
  selectedProfiles: [],
  currentPage: 1,
};

export const getFavouritesDetails = createAsyncThunk(
  "favourites/getFavouritesDetails",
  async (params, { rejectWithValue }) => {

    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/members/profile/favourites?user_id=${params.user_id}&page_size=21&page=${params?.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const favouritesSlice = createSlice({
  name: "favourites",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getFavouritesDetails.pending]: (state, action) => {
      state.favouritesStatus = "pending";
    },
    [getFavouritesDetails.fulfilled]: (state, action) => {
      let newArry = state.favouritesData[action.meta?.arg?.user_id]
        ? current(state.favouritesData[action.meta?.arg?.user_id]?.data ?? [])
        : [];

      let tempArry = [
        ...newArry,
        ...(action?.payload?.data?.data?.data
          ? action?.payload?.data?.data?.data
          : []),
      ];

      state.selectedProfiles = tempArry
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?._id);

      if (state.favouritesData?.[action.meta?.arg?.user_id]) {
        state.favouritesData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          data: [
            ...state.favouritesData[action.meta?.arg?.user_id].data,
            ...action.payload?.data?.data?.data,
          ],
        };
      } else {
        state.favouritesData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          page: 1,
        };
      }
      state.favouritesStatus = "fulfilled";
    },
    [getFavouritesDetails.rejected]: (state) => {
      state.favouritesStatus = "failed";
    },
  },
});

export const { updateConfig } = favouritesSlice.actions;

export default favouritesSlice.reducer;
