import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useFAQs from "./useFAQs";
import AddFAQForm from "./AddForm";
import EmptyData from "../../Global/EmptyData";

const FAQs = () => {
  const {
    faqsList,
    faqs,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasCreatePermission,
    hasDeletePermission,
    hasEditPermission,
    hasUpdatePermission,
    hasExportPermission,
    handleActionChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    handleEditFAQClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    closeTableEditModal,
    showTableDeleteModal,
    ActionDelete,
    showFAQform,
    setShowFAQform,
    updateFAQFields,
    handleClearClick,
  } = useFAQs();

  return (
    <>
      <HeadingGroup
        title={"FAQs"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission === 1 ? "Add new" : ""}
        handleClick={() => {
          setShowFAQform(true);
        }}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={faqsList?.data?.filters}
          onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: faqs?.search }}
          actionOptions={actionOptions?.filter((item) =>
            hasExportPermission !== 1 ? item.value === 0 : true
          )}
          onActionOptionChange={handleActionChange}
        />
        {faqsList?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={faqsList?.data?.data?.data || []}
            uniqueID={"_id"}
            fields={faqsList?.data?.fields}
            showCheckBox={
              hasEditPermission === 1
                ? true
                : false || hasDeletePermission === 1
                ? true
                : false
            }
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            handleSelect={true}
            clear={faqs.clearSelection}
            multiSelect={false}
            loading={isFetching}
            perpage={faqs.currentPageSize}
            editable={hasEditPermission === 1 ? true : false}
            assignable={false}
            handleEdit={handleEditFAQClick}
            handleDelete={handleDelete}
            deletable={hasDeletePermission === 1 ? true : false}
          />
        )}

        {faqsList?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === faqs?.currentPageSize
            )}
            totalPageCount={faqsList?.data?.data?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              // refetchData={refetch}
              updateData={updateFAQFields}
              tableFields={faqsList?.data?.fields}
              moduleId={faqsList?.data?.module_id}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showFAQform}
          handleClose={() => {
            setShowFAQform(false);
          }}
          title={`${faqs.formEditMode === true ? "Edit FAQ" : "Add FAQ"}`}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <AddFAQForm
            setShowFAQform={setShowFAQform}
            refetch={refetch}
            hasUpdatePermission={hasUpdatePermission}
          />
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showTableDeleteModal}
          handleClose={closeTableEditModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete this item?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={closeTableEditModal}
              submitAction={ActionDelete}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default FAQs;
