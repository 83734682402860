import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getFollowUpCommentData,
  getFollowUpOverviewData,
  updateConfig,
} from "../../../store/slices/Profile/ProfileFollowup";
import { useDispatch, useSelector } from "react-redux";
import useObserver from "../../../utils/hooks/useObserver";

const useProfileFollowUp = () => {
  const [activeFilter, setActiveFilter] = useState(2);
  const [params] = useSearchParams();
  const [page, setPage] = useState(1);
  const [showScroll, setShowScroll] = useState(false);
  const dispatch = useDispatch();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const state = useSelector((state) => state.profileFollowup);
  const profileFollowupCommentState = state?.commentData?.[activeProfile];
  const profileFollowupOverviewState = state?.overviewData?.[activeProfile];

  const filters = [
    {
      type: "buttonGroup",
      buttons: [
        { name: "Overview", value: 2 },
        { name: "Comments", value: 1 },
      ],
    },
  ];

  useEffect(() => {
    if (Object.keys(state?.commentData)?.length === 0 && activeFilter === 1) {
      dispatch(
        getFollowUpCommentData({
          user_id: activeProfile,
          page: page,
          hasOverviewPermission:hasOverviewPermission
        })
      );
    }
    if (Object.keys(state?.overviewData)?.length === 0 && activeFilter === 2) {
      dispatch(
        getFollowUpOverviewData({
          user_id: activeProfile,
          page: page,
          hasOverviewPermission:hasOverviewPermission
        })
      );
    }

    //eslint-disable-next-line
  }, [activeProfile, activeFilter]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const hasOverviewPermission = useMemo(() => {
    let permission = state?.followUpPermissions?.filter((p) =>
      Object.keys(p).includes("can_overview")
    );

    return permission?.[0]?.can_overview ?? 0;
  }, [state]);
  const hasCommentsPermission = useMemo(() => {
    let permission = state?.followUpPermissions?.filter((p) =>
      Object.keys(p).includes("can_comments")
    );

    return permission?.[0]?.can_comments ?? 0;
  }, [state]);

  
  if (hasOverviewPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 2
    );
  }
  if (hasCommentsPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 1
    );
  }


  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleLoadMore = () => {
    if (activeFilter === 1) {
      dispatch(
        updateConfig((state) => {
          //state.currentPage = state.currentPage + 1;
          state.commentData[activeProfile].page += 1;
        })
      );
      setPage((prev) => prev + 1);
      dispatch(
        getFollowUpCommentData({
          user_id: activeProfile,
          page: page + 1,
          hasOverviewPermission:hasOverviewPermission
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          //state.currentPage = state.currentPage + 1;
          state.overviewData[activeProfile].page += 1;
        })
      );
      setPage((prev) => prev + 1);
      dispatch(
        getFollowUpOverviewData({
          user_id: activeProfile,
          page: page + 1,
          hasOverviewPermission:hasOverviewPermission
        })
      );
    }
  };

  const { lastElement } = useObserver({
    loading:
      activeFilter === 1
        ? state?.commentStatus === "pending"
        : state?.overviewStatus === "pending",
    hasMore:
      activeFilter === 1
        ? profileFollowupCommentState?.per_page *
            profileFollowupCommentState?.current_page <
          profileFollowupCommentState?.total
        : profileFollowupOverviewState?.per_page *
            profileFollowupOverviewState?.current_page <
          profileFollowupOverviewState?.total,
    callback: handleLoadMore,
  });

  

  const handleTabChange = (item) => {
    if (item.value !== activeFilter) {
      setActiveFilter(item.value);
    }
  };

  return {
    filters,
    state,
    activeFilter,
    showScroll,
    profileFollowupCommentState,
    profileFollowupOverviewState,
    lastElement,
    handleTabChange,
    handleScrollToTop,
  };
};

export default useProfileFollowUp;
