import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  selectedItemsDetails:"",
  showCreateModal: false,
  magazineId: "",
  clearSelection: false,
};

/**
 * @example {
 *  "sort_by":"name"
 *  "sort_order":"asc"

 *  }
 */

const magazineSlice = createSlice({
  name: "magazine",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = magazineSlice.actions;

export default magazineSlice.reducer;
