import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/Settings/faqsSlice";

import {
  updateStaffDetails,
  passwordReset,
} from "../../../../store/slices/Settings/staffSlice";

const useProfileEdit = ({ setProfileViewVisible, userData }) => {
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState("");
  // const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(true);

  const [showExistingPassword, setShowExistingPassword] = useState(true);

  const imageFileInputRef = useRef(null);

  const dispatch = useDispatch();

  var validationSchemaProfileEdit = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
  });

  var validationSchemaPasswordChange = Yup.object({
    existingPassword: Yup.string().required("Existing password is required"),
    newPassword: Yup.string().required("New password is required"),
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const formikEditUserData = useFormik({
    initialValues: {
      email: userData?.email,
      phoneNumber: userData?.phone_number,
    },

    validationSchema: validationSchemaProfileEdit,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const formikEditPassword = useFormik({
    initialValues: {
      email: userData?.email,
      phoneNumber: userData?.phone_number,
      existingPassword: "",
      newPassword: "",
    },

    validationSchema: validationSchemaPasswordChange,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleSubmitPasswordChange(values, resetForm, setFieldError);
    },
  });

  // useEffect(() => {
  //   if (localStorage.getItem("USER_DETAILS") !== undefined)
  //     setIsDataLoading("loading");
  //   dispatch(
  //     editItemFromTableByID({ user_id: localStorage.getItem("USER_DETAILS") })
  //   )
  //     .unwrap()
  //     .then((response) => {
  //       setIsDataLoading("success");
  //       setUserData(response);
  //     });
  //   // eslint-disable-next-line
  // }, []);

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      let obj = {
        first_name: userData.data.data.first_name,
        last_name: userData.data.data.last_name,
        phone_number: values.phoneNumber,
        email: values.email,
        role_id: userData.data.data.role_id,
        user_id: userData.data.data._id,
        country_code: userData.data.data.country_code,
      };

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      formData.append("_method", "PUT");

      dispatch(updateStaffDetails(formData))
        .unwrap()
        .then((data) => {
          setProfileViewVisible(false);
          resetForm();
          toast.success("Your details has been updated successfully.");
        })
        .catch((err) => {
          if (err?.message?.groom_name?.[0] !== "") {
            setFieldError("groomName", err?.message?.groom_name?.[0]);
          }
        });
    } catch (error) {}
  };

  const handleSubmitPasswordChange = async (
    values,
    resetForm,
    setFieldError
  ) => {
    try {
      try {
        let obj = {
          password: values.existingPassword,
          confirm_password: values.newPassword,
        };

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        dispatch(passwordReset(formData))
          .unwrap()
          .then((data) => {
            if (data?.data?.success) {
              setProfileViewVisible(false);
              resetForm();
              toast.success("Your password has been updated successfully.");
            }
          })
          .catch((err) => {
            if (err?.message?.password?.[0] !== "") {
              setFieldError("existingPassword", err?.message?.password?.[0]);
            }
          });
      } catch (error) {}
    } catch (error) {
      toast.success("Something went wrong!");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleShowExistingPassword = (e) => {
    e.preventDefault();
    setShowExistingPassword(!showExistingPassword);
    var x = document.getElementById("existingPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowNewPassword = (e) => {
    e.preventDefault();
    setShowNewPassword(!showNewPassword);
    var x = document.getElementById("newPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    message,
    setLoading,
    setMessage,
    setOptions,
    formikEditUserData,
    formikEditPassword,
    loading,
    handleCloseModal,
    showExistingPassword,
    showNewPassword,
    handleShowExistingPassword,
    handleShowNewPassword,
    imageFileInputRef,
    selectedOption,
    setSelectedOption,
    options,
    isDataLoading,
    setIsDataLoading,
    userData,
  };
};

export default useProfileEdit;
