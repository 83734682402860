import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useObserver from "../../../utils/hooks/useObserver";
import { useDispatch, useSelector } from "react-redux";
import {
  getViewedDetails,
  updateConfig,
} from "../../../store/slices/Profile/Viewed";

const useViewed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState(1);
  const [showScroll, setShowScroll] = useState(false);
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const state = useSelector((state) => state.viewed);
  const viewedState = state?.viewedData?.[activeProfile];

  const filters = [
    {
      type: "buttonGroup",
      buttons: [
        { name: "Viewed By User", value: 1 },
        { name: "Viewed By Others ", value: 0 },
      ],
    },
  ];

  const reportData = [
    {
      label: "Today",
      value: `${state.viewedData[activeProfile]?.daily_count ?? 0}`,
    },
    {
      label: "This week",
      value: `${state.viewedData[activeProfile]?.weekly_count ?? 0}`,
    },
    {
      label: "This Month",
      value: `${state.viewedData[activeProfile]?.monthly_count ?? 0}`,
    },
    {
      label: "All time",
      value: `${state.viewedData[activeProfile]?.total_count ?? 0}`,
    },
  ];

  const hasviewedByUserPermission = useMemo(() => {
    let permission = state?.viewedPermissions?.filter((p) =>
      Object.keys(p).includes("viewed_by_user")
    );

    return permission?.[0]?.viewed_by_user ?? 0;
  }, [state]);
  const hasViewedByOtherPermission = useMemo(() => {
    let permission = state?.viewedPermissions?.filter((p) =>
      Object.keys(p).includes("viewed_by_other")
    );

    return permission?.[0]?.viewed_by_other ?? 0;
  }, [state]);

  if (hasviewedByUserPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 0
    );
  }
  if (hasViewedByOtherPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 1
    );
  }

  useEffect(() => {
    if (Object.keys(state?.viewedData)?.length === 0) {
      dispatch(
        getViewedDetails({
          user_id: activeProfile,
          activeFilter: activeFilter,
          page: page,
          hasviewedByUserPermission:hasviewedByUserPermission
        })
      );
    }

    if (
      state?.selectedProfiles?.length ===
      viewedState?.data
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?.member_id)?.length
    ) {
      dispatch(
        updateConfig((state) => {
          state.selectedProfiles = viewedState?.data
            ?.filter((item) => Boolean(item.short_listed))
            ?.map((item) => item?.member_id);
        })
      );
    }

    return () => {
      dispatch(
        updateConfig((state) => {
          state.viewedStatus = "idle";
          state.viewedData = {};
          state.selectedProfiles = [];
        })
      );
    };

    //eslint-disable-next-line
  }, [activeProfile]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleLoadMore = () => {
    dispatch(
      updateConfig((state) => {
        //state.currentPage = state.currentPage + 1;
        state.viewedData[activeProfile].page += 1;
      })
    );
    setPage((prev) => prev + 1);
    dispatch(
      getViewedDetails({
        user_id: activeProfile,
        activeFilter: activeFilter,
        page: page + 1,
        hasviewedByUserPermission:hasviewedByUserPermission
      })
    );
  };

  const { lastElement } = useObserver({
    loading: state?.viewedStatus === "pending",
    hasMore:
      viewedState?.per_page * viewedState?.current_page < viewedState?.total,
    callback: handleLoadMore,
  });

  const handleProfileRedirect = (id) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), activeProfile]
      : [activeProfile];

    const newIds = ids.filter((item) => item !== id).join(",");

    navigate({
      pathname: "/profile",
      search: `active=${id}&id=${newIds}`,
    });
  };

  const handleTabChange = (item) => {
    if (item.value !== activeFilter) {
      dispatch(
        updateConfig((state) => {
          state.viewedData[activeProfile].data = [];
          state.viewedData[activeProfile].page = 1;
        })
      );
      dispatch(
        getViewedDetails({
          user_id: activeProfile,
          activeFilter: item.value,
          page: page,
          hasviewedByUserPermission:hasviewedByUserPermission
        })
      );
      setActiveFilter(item.value);
    }
  };

  return {
    filters,
    reportData,
    state,
    activeProfile,
    viewedState,
    showScroll,
    activeFilter,
    handleScrollToTop,
    lastElement,
    handleProfileRedirect,
    handleTabChange,
  };
};

export default useViewed;
