import Style from "./DocumentCard.module.scss";
import useDocumentCard from "./useDocumentCard";
import { DataContainer } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

export function DocumentCard({
  data,
  activeID,
  refetch,
  setImageData,
  hasUploadViewPermission,
  setViewId,
  setShowDocumentVerificationModal,
}) {
  const {
    showReasonSelect,
    activeDocumentIndex,
    activeReason,
    reasons,
    reasonError,
    checked,
    DocumentType,
    handleViewIdProofModal,
    handleReject,
    cancelRejectClick,
    handleThumbClick,
    handleReasonChange,
    handleApprove,
    handleRejectSubmit,
  } = useDocumentCard({
    data,
    activeID,
    refetch,
    setImageData,
    setViewId,
    setShowDocumentVerificationModal,
  });

  return (
    <div className={`${Style.root} pro-p-2`}>
      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {data.profileData[activeID]
            ? data.profileData[activeID].map((profile, index) => {
                return (
                  <div className={`col-12 ${Style.id_container}`} key={index}>
                    <DataContainer>
                      <div className="pro-check-box">
                        <input
                          type="checkbox"
                          className="pro-check"
                          id="checkbox"
                          name="pro-checkbox"
                          onChange={() => handleThumbClick(index, checked)}
                          checked={
                            checked?.[index.toString()]?.index === index
                              ? checked?.[index.toString()]?.checked
                              : checked?.[index.toString()]?.checked
                          }
                        />
                        <label htmlFor="checkbox" className="pro-check-label">
                          {" "}
                          {
                            DocumentType.filter(
                              (doc) => doc.type === profile?.document_type
                            )?.[0]?.name
                          }
                        </label>
                        {profile?.extension === "pdf" ? (
                          <a
                            className="pro-mb-0 pro-pnt"
                            href={`${profile?.url ?? ""}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View Uploads
                          </a>
                        ) : (
                          <span
                            className="pro-pnt"
                            onClick={() => handleViewIdProofModal(profile?.url)}
                          >
                            View Uploads
                          </span>
                        )}
                      </div>
                    </DataContainer>
                  </div>
                );
              })
            : [...Array(1)].map((_, i) => (
                <div className={`col-12`} key={i}>
                  <div
                    className={` pro-d-flex pro-gap-4 pro-p-3 pro-px-4 pro-rounded-3 ${Style.id_proof_shimmer_root}`}
                  >
                    <div className="pro-p-3 blink"></div>
                    <div
                      className={`shimmer pro-px-6 pro-align-self-center pro-pb-3`}
                    />
                  </div>
                </div>
              ))}
        </div>
      </div>

      {showReasonSelect ? (
        <div className={`${Style.root_input} pro-m-2 $`}>
          <div className={"input-wrap disabled"}>
            <Select
              placeholder={`Reason for rejection`}
              value={activeReason?.label ? activeReason : ""}
              options={reasons}
              className={`pro-input ${reasonError ? "error" : ""} `}
              classNamePrefix="pro-input"
              onChange={handleReasonChange}
            />
            {reasonError && (
              <span className="error-text">Enter a reason for rejection</span>
            )}
          </div>
          <div
            className={`${Style.buttons} pro-d-flex pro-mt-2 pro-justify-end`}
          >
            <button
              onClick={cancelRejectClick}
              className="pro-btn pro-btn-outline"
            >
              Cancel
            </button>
            <button
              onClick={handleRejectSubmit}
              className={`pro-btn pro-btn-primary ${
                data.updateProfileStatus === "pending" ? "loading" : ""
              }`}
              disabled={hasUploadViewPermission === 1 ? false : true}
            >
              Submit
            </button>
          </div>
        </div>
      ) : data.profileData?.[activeID]?.[activeDocumentIndex] ? (
        <div
          className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end`}
        >
          {!data.profileData[activeID][activeDocumentIndex].status && (
            <button
              onClick={handleReject}
              className="pro-btn pro-btn-outline full"
              disabled={hasUploadViewPermission === 1 ? true : false}
            >
              Reject
            </button>
          )}
          <button
            onClick={() =>
              !data.profileData[activeID][activeDocumentIndex].status &&
              handleApprove()
            }
            className={`pro-btn pro-btn-primary full ${
              data.updateProfileStatus === "pending" ? "loading" : ""
            }`}
          >
            {data.profileData[activeID][activeDocumentIndex].status === 1
              ? "Approved"
              : data.profileData[activeID][activeDocumentIndex].status === 2
              ? "Rejected"
              : "Approve"}
          </button>
        </div>
      ) : (
        <div
          className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end `}
        >
          {/** #FIXME Aneesh, haddle shine for buttons(border radius is high)   */}
          <button className={`pro-btn pro-btn-primary full shine`} disabled>
            Reject
          </button>
          <button className={`pro-btn pro-btn-primary full shine`} disabled>
            Approve
          </button>
        </div>
      )}

      {/* <ModalLayout show={viewId} handleClose={closeModal}>
        <ProfileCard data={imageData} handleClose={closeModal} />
      </ModalLayout> */}
    </div>
  );
}
