import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/payment/transactionsSlice";
import {
  addPayment,
  getPackagesData,
  getPaymentModes,
  getTypesData,
  getUserDetails,
} from "./api.js";
import { useSelector } from "react-redux";

const useAddPaymentForm = ({
  activeProfile,
  profilePayment,
  refetch,
  setSkip,
  selectedUser,
  setSelectedUser,
}) => {
  const [packageData, setPackageData] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [types, setTypes] = useState([]);
  // const [userData, setUserData] = useState([]);

  useEffect(() => {
    getPackagesData().then((response) => {
      setPackageData(response?.data?.data);
    });
    getPaymentModes().then((response) => {
      setPaymentModes(response?.data);
    });
    getTypesData().then((response) => {
      setTypes(response?.data?.data?.type);
    });
  }, []);

  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const imageFileInputRef = useRef(null);
  const { selectedUserId, userData } = useSelector(
    (state) => state.transactions
  );

  const dispatch = useDispatch();

  let addPaymentVal = Yup.object({
    amount: Yup.string().required("*Required"),
    payment_mode: Yup.string().required("*Required"),
    package_id: Yup.string().required("*Required"),
    user_id: Yup.string().required("*Required"),
    subscription_type: Yup.string().required("*Required"),
    payment_proofs: Yup.string().required("*Required"),
  });
  const formik = useFormik({
    initialValues: {
      user_id: "",
      package_id: "",
      payment_mode: "",
      amount: "",
      payment_proofs: "",
      note: "",
      subscription_type: "",
    },

    validationSchema: addPaymentVal,

    enableReinitialize: true,

    onSubmit: (values) => {
      try {
        let obj = {
          ...values,
          user_id: profilePayment ? activeProfile : values.user_id,
        };

        let formData = new FormData();
        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        addPayment(formData).then((response) => {
          setMessage("");
          setErrors("");
          if (response?.data?.status_code === 200) {
            refetch?.();

            submitForm();
          } else if (response?.status_code === 400) {
            formik.setErrors(response?.message);

            setMessage(response?.message);
          }
        });
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("user_id", selectedUserId);

    if (!selectedUserId) {
      dispatch(
        updateConfig((state) => {
          state.userData = [];
        })
      );
    }
    //eslint-disable-next-line
  }, [selectedUserId]);

  useEffect(() => {
    if (profilePayment) {
      formik.setFieldValue("user_id", activeProfile);
    }
    //eslint-disable-next-line
  }, [activeProfile]);

  const submitForm = () => {
    formik.resetForm();

    setLoading(false);

    if (!profilePayment) {
      dispatch(
        updateConfig((state) => {
          state.showCreateModal = false;
          state.selectedUserId = "";
        })
      );
    } else {
      formik.setFieldValue("package_id", "");
      formik.setFieldValue("payment_mode", "");
      formik.setFieldValue("amount", "");
      formik.setFieldValue("payment_proofs", "");
      formik.setFieldValue("note", "");
      formik.setFieldValue("subscription_type", "");
      dispatch(
        updateConfig((state) => {
          state.selectedUserId = activeProfile;
        })
      );
    }

    toast.success("Successfully submitted");
  };

  const handleCloseModal = () => {
    if (profilePayment) {
      formik.setFieldValue("package_id", "");
      formik.setFieldValue("payment_mode", "");
      formik.setFieldValue("amount", "");
      formik.setFieldValue("payment_proofs", "");
      formik.setFieldValue("note", "");
      formik.setFieldValue("subscription_type", "");
    }
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.selectedUserId = "";
      })
    );
  };
  const packagesOptions = useMemo(
    () =>
      packageData?.map((opt) => {
        return {
          value: opt.name,
          label: opt.name,
          id: opt._id,
          type: opt.type,
          price: opt.price,
        };
      }),
    [packageData]
  );

  const paymentsOptions = useMemo(
    () =>
      paymentModes?.map((opt) => {
        return { value: opt.name, label: opt.name, id: opt.id };
      }),
    [paymentModes]
  );

  const typesOptions = useMemo(
    () =>
      types?.map((opt) => {
        return { value: opt.name, label: opt.name, id: opt.id };
      }),
    [types]
  );
  const useDataOptions = useMemo(
    () =>
      userData?.map((opt) => {
        return { value: opt.value, label: opt.value, id: opt._id };
      }),
    [userData]
  );

  const handleInputChange = (item) => {
    if (item?.length >= 2) {
      getUserDetails({ search: item }).then((response) => {
        // setUserData(response?.data?.data);
        dispatch(
          updateConfig((state) => {
            state.userData = response?.data?.data;
          })
        );
      });
    }
  };

  const handleUserId = (value) => {
    formik.setFieldValue("user_id", value?.id);
    setSelectedUser(value?.id);
    setSkip(() => false);
    dispatch(
      updateConfig((state) => {
        state.selectedUserId = value?.id;
      })
    );
  };
  const handleProofImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("payment_proofs", e?.target?.files?.[0]);
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "payment_proofs",
        "The image must be less than 5MB in size."
      );
    }
  };
  return {
    errors,
    message,
    formik,
    loading,
    userData,
    selectedUserId,
    imageFileInputRef,
    packagesOptions,
    paymentsOptions,
    useDataOptions,
    typesOptions,
    handleProofImage,
    handleUserId,
    handleCloseModal,
    handleInputChange,
  };
};

export default useAddPaymentForm;
