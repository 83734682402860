import { useSelector } from "react-redux";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { saveUserTab } from "../../store/slices/Profile";

const useProfile = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { checkIfActiveRoute } = useRouteUtils();
  const { profileOverviewData } = useSelector((state) => state.profile);
  const globalState = useSelector((state) => state.global);
  const profile = useSelector((state) => state.profile);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  useEffect(() => {
    return () => {
      dispatch(saveUserTab({ id: activeProfile, path: location.pathname }));
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (profile?.prevTab?.[activeProfile]) {
      navigate(`${profile?.prevTab?.[activeProfile]}${window.location.search}`);
    }
    // eslint-disable-next-line
  }, [activeProfile]);

  const navigation = [
    {
      label: "Overview",
      title: "Overview",
      link: `/profile${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile", true),
    },
    {
      label: "Matches",
      title: "Matches",
      link: `/profile/matches${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/matches"),
    },
    {
      label: `Shortlisted  (${profileOverviewData?.shortlisted_count ?? 0})`,
      title: "Shortlisted",
      link: `/profile/shortlisted${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/shortlisted"),
    },
    {
      label: `Favourites (${profileOverviewData?.favourite_count ?? 0})`,
      title: "Favourites",
      link: `/profile/favorites${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/favorites"),
    },
    {
      label: `Viewed (${profileOverviewData?.profile_views ?? 0})`,
      title: "Viewed",
      link: `/profile/viewed${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/viewed"),
    },
    {
      label: `Interest (${profileOverviewData?.interests?.count ?? 0})`,
      title: "Interests",
      link: `/profile/interested${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/interested"),
    },
    {
      label: `Contact Viewed (${profileOverviewData?.contacts?.count ?? 0})`,
      title: "Contacts",
      link: `/profile/contact-viewed${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/contact-viewed"),
    },
    {
      label: "Payment",
      title: "Payment",
      link: `/profile/profile-payment${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/profile-payment"),
    },
    {
      label: "Followup",
      title: "Followup",
      link: `/profile/followup${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/followup"),
    },
    {
      label: "Activity",
      title: "Activity",
      link: `/profile/activity${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/activity"),
    },
    {
      label: "Settings",
      title: "Settings",
      link: `/profile/settings${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/settings"),
    },
  ];

  const profileTabPermission = useMemo(() => {
    let menus = globalState.profile_permissions?.flatMap?.((menu) => [
      menu.label,
    ]);

    return navigation
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });

    //eslint-disable-next-line
  }, [
    globalState?.profile_permissions,
    location.pathname,
    profileOverviewData,
  ]);

  return { navigation: profileTabPermission };
};

export default useProfile;
