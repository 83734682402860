import React from "react";
import {
  // DatePickerWithInput,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import { format } from "date-fns";
// import { getFormatedDate } from "../../../../../../utils/functions/table";
// import { DatePickerWithInput } from "../../../../../Global/DatePickerWithInput";
// import InputDatePicker from "../../../../../Global/InputDatePicker";

const RowTwo = ({
  formik,
  dobRef,
  getFieldError,
  optionsData,
  currentFormData,
}) => {
  let maxDate = new Date();
  maxDate.setFullYear(
    maxDate.getFullYear() - (formik.values.gender?.id === 1 ? 21 : 18)
  );

  const reverseDateString = (dateString) => {
    const parts = dateString.split("-");
    const reversedDateString = parts[2] + "-" + parts[1] + "-" + parts[0];
    return reversedDateString;
  };
  let dateStr = reverseDateString(formik.values.dob);

  return (
    <>
      <div className="input-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of Birth*
        </label>
        <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
          <Input
            value={formik.values.dob === "" ? "yyyy-MM-dd" : dateStr}
            type={"date"}
            max={maxDate.toLocaleDateString("fr-ca")}
            className={`pro-input lg ${
              formik.touched.dob && formik.errors.dob && "error"
            }`}
            onChange={(e) =>
              formik.setFieldValue(
                "dob",
                format(new Date(e.target.value), "dd-MM-yyyy")
              )
            }
            onBlur={formik.handleBlur("dob")}
            error={formik.touched.dob && formik.errors.dob}
            errorMessage={formik.errors.dob}
          />

          {/* <DatePickerWithInput
            dobRef={dobRef}
            initialValue={
              maxDate
            }
            CalendarOutlineIcon={
              <span className="material-symbols-outlined ">
                {" "}
                calendar_month{" "}
              </span>
            }
            id="dob"
            name={"dob"}
            placeholder={
              currentFormData?.dob ? currentFormData?.dob : "Select Date"
            }
            className={`pro-input lg ${getFieldError("dob") && " error"}`}
            onDateChange={(value) => {
              formik.setFieldValue("dob", getFormatedDate(value));
            }}
            onBlur={formik.handleBlur("dob")}
            maxDate={maxDate}
            isError={getFieldError("dob")}
            errorMessage={getFieldError("dob")}
          /> */}

          {/* <InputDatePicker
            errorMsg={formik.errors.dob}
            className={`${getFieldError("dob") && " error"}`}
            name="dob"
            id="dob"
            autoComplete="false"
            handleDateChange={(e) => formik?.setFieldValue("dob", e)}
            value={formik?.values?.dob}
            disableFuture
            minDate={
              formik?.values?.gender?.id === 1
                ? disableLast21Years
                : disableLast18Years
            }
            shouldDisableDate={
              formik?.values?.gender?.id === 1
                ? disableLast21Years
                : disableLast18Years
            }
            disabled={formik?.values?.gender ? false : true}
          /> */}
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Height
        </label>
        <Select
          id="height"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("height") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.height}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.height?.filter(
            (m) => formik.values.height?._id === m?._id
          )}
          onChange={(value) => formik.setFieldValue("height", value || null)}
        ></Select>
        {getFieldError("height") && (
          <span className="error-text">{getFieldError("height")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Weight
        </label>
        <Select
          id="weight"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("weight") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.weight}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.kgs}
          value={optionsData?.weight?.filter(
            (m) => formik.values.weight?._id === m?._id
          )}
          onChange={(value) => formik.setFieldValue("weight", value || null)}
        ></Select>
        {getFieldError("weight") && (
          <span className="error-text">{getFieldError("weight")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Marital Status*
        </label>
        <Select
          id="marital_status"
          
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("marital_status") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.marital_status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.marital_status?.filter(
            (m) => formik.values.marital_status?.id === m?.id
          )}
          onBlur={formik.handleBlur("marital_status")}
          onChange={(value) =>
            formik.setFieldValue("marital_status", value || null)
          }
        ></Select>
        {getFieldError("marital_status") && (
          <span className="error-text">{getFieldError("marital_status")}</span>
        )}
      </div>
      {formik.values.marital_status !== "" &&
      formik.values.marital_status?.name !== "Unmarried" ? (
        <Input
          label={"Number of Children"}
          type="text"
          id="no_of_children"
          name="no_of_children"
          className={`pro-input lg ${
            getFieldError("no_of_children") && " error"
          }`}
          {...formik.getFieldProps("no_of_children")}
          error={getFieldError("no_of_children")}
          errorMessage={getFieldError("no_of_children")}
        />
      ) : (
        <></>
      )}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Physical Status
        </label>
        <Select
          id="physical_status"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("physical_status") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.physical_status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.physical_status?.filter(
            (m) => formik.values.physical_status?.id === m?.id
          )}
          onChange={(value) =>
            formik.setFieldValue("physical_status", value || null)
          }
        ></Select>
        {getFieldError("physical_status") && (
          <span className="error-text">{getFieldError("physical_status")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Body Type
        </label>
        <Select
          id="body_type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("body_type") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.body_type}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.body_type?.filter(
            (m) => formik.values.body_type?.id === m?.id
          )}
          onChange={(value) => formik.setFieldValue("body_type", value || null)}
        ></Select>
        {getFieldError("body_type") && (
          <span className="error-text">{getFieldError("body_type")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Complexion
        </label>
        <Select
          id="body_complexion"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("body_complexion") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.body_complexion}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.body_complexion?.filter(
            (m) => formik.values.body_complexion?.id === m?.id
          )}
          onChange={(value) =>
            formik.setFieldValue("body_complexion", value || null)
          }
        ></Select>
        {getFieldError("body_complexion") && (
          <span className="error-text">{getFieldError("body_complexion")}</span>
        )}
      </div>
    </>
  );
};

export default RowTwo;
