import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAxiosInstance } from "../../../../api";
import { updateConfig as updateGlobalConfig } from "../../../../store/slices/Global";
import {
  getAllPermissions,
  getPermission,
} from "../../../../store/slices/Settings/roleSlice";

const useManageRoles = () => {
  const [params] = useSearchParams();
  const role = params.get("role");
  const update = params.get("update");

  const dispatch = useDispatch();
  const state = useSelector((state) => state.role);

  const [name, setName] = useState("");
  const [permissionModules, setPermissionModules] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(
      updateGlobalConfig((state) => {
        state.menuDrawer = {
          show: true,
          backTo: "/configure/roles",
          text: "Back to role",
        };
      })
    );
    if (state.allPermissionsStatus === "idle") {
      dispatch(getAllPermissions());
    }
    if (role) {
      dispatch(getPermission({ id: role }))
        .unwrap()
        .then((r) => {
          if (update === "true") {
            setName(r.data.data.name);
          }
          let obj;
          r.data.data.modules.forEach((m) => {
            obj = { ...obj, [m.module_id]: m.permissions };
          });
          setPermissionModules(obj);
        });
    }

    return () => {
      dispatch(
        updateGlobalConfig((state) => {
          state.menuDrawer = {};
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (error) {
      setError("");
    }
  };

  const handlePermissionChange = (item, moduleId) => {
    if (item.e.target.checked) {
      setPermissionModules((prev) => {
        return {
          ...prev,
          [moduleId]: prev?.[moduleId]
            ? [...prev[moduleId], { id: item.id }]
            : [{ id: item.id }],
        };
      });
    } else {
      setPermissionModules((prev) => {
        return {
          ...prev,
          [moduleId]: prev[moduleId].filter(
            (permissions) => permissions.id !== item.id
          ),
        };
      });
    }
  };

  const handleSave = async () => {
    if (name.length === 0) {
      setError("*Name cannot be empty");
    } else {
      const api = await getAxiosInstance();
      const modules = Object.keys(permissionModules).map((mId) => {
        return { module_id: mId, permissions: permissionModules[mId] };
      });
      const filteredData = modules.filter((obj) => obj.permissions.length > 0);
      if (!update) {
        api
          .post("/admin/roles/create", { name, modules: filteredData })
          .then((r) => {
            if (r.status === 200) {
              toast.success("Role created successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              toast.error("Failed to Create Role");
            }
          })
          .catch((err) => {
            // if (err.response.status === 400) {
            toast.error("Failed to Create Role");
            // }
          });
      } else {
        api
          .put("/admin/roles/update", {
            role_id: role,
            name,
            modules: filteredData,
          })
          .then((r) => {
            if (r.status === 200) {
              toast.success("Role updated successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              toast.error("Failed to Update Role");
            }
          });
      }
    }
  };

  return {
    state,
    role,
    update,
    name,
    error,
    handleNameChange,
    handlePermissionChange,
    handleSave,
  };
};

export default useManageRoles;
