import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useSettings = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Staff",
      permission: "Staff",
      link: "/configure/staff",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/configure/staff", true),
    },
    {
      title: "Roles",
      permission: "Roles",
      link: "/configure/roles",
      iconAsset: "DrawerIcon3",
      icon: (
        <span className="material-symbols-outlined x4">workspace_premium</span>
      ),
      active: checkIfActiveRoute("/configure/roles"),
    },
    {
      title: "Packages",
      permission: "Packages",
      link: "/configure/packages",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">package</span>,
      active: checkIfActiveRoute("/configure/packages"),
    },

    {
      title: "Branches",
      permission: "Branches",
      link: "/configure/branches",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">location_away</span>,
      active: checkIfActiveRoute("/configure/branches"),
    },
    {
      title: "Diocese",
      permission: "Diocese",
      link: "/configure/diocese",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">church</span>,
      active: checkIfActiveRoute("/configure/diocese"),
    },
    {
      title: "Add-ons",
      permission:"Add-on",
      link: "/configure/adons",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">note_add</span>,
      active: checkIfActiveRoute("/configure/adons"),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
    ]);

    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.permission))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  const newDrawerMenuPermission = [

    {
      title: "Appearance",
      link: "/configure/appearance",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">receipt_long</span>,
      active: checkIfActiveRoute("/configure/appearance"),
    },
  ];

  const combinedMenuPermissions = [
    ...drawerMenuPermission,
    ...newDrawerMenuPermission,
  ];

  return {
    drawerMenu: combinedMenuPermissions,
  };
};

export default useSettings;
