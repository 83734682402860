import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useSubscribers from "./useSubscribers";
import EmptyData from "../../Global/EmptyData";

const Subscribers = () => {
  const {
    subscribers,
    subscribersState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    handleActionChange,
    refetch,
    hasExportPermission,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    updateSubscribersFields,
    handleClearClick,
  } = useSubscribers();

  return (
    <>
      <HeadingGroup title={"Newsletter Subscriptions"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={subscribers?.data?.filters}
          onSearchInput={handleSearch}
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: subscribersState?.search }}
          actionOptions={actionOptions?.filter((item) =>
            hasExportPermission !== 1 ? item.value === 0 : true
          )}
          onActionOptionChange={handleActionChange}
        />
        {subscribers?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={subscribers?.data?.data?.data || []}
            uniqueID={"_id"}
            fields={subscribers?.data?.fields}
            showCheckBox={false}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={subscribersState?.currentPageSize}
            assignable={false}
          />
        )}

        {subscribers?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={subscribers?.data?.data?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === subscribersState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={subscribers?.data?.fields}
              moduleId={subscribers?.data?.module_id}
              updateData={updateSubscribersFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Subscribers;
