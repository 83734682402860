import React from "react";
import Style from "./payment.module.scss";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import { limitStrLength } from "../../../../utils/functions/table";

const PaymentFields = ({
  formik,
  imageFileInputRef,
  packagesOptions,
  paymentsOptions,
  typesOptions,
  useDataOptions,
  profilePayment,
  setSelectedUser,
  handleUserId,
  handleProofImage,
  setSkip,
  handleInputChange,
}) => {
  const noOptionsMessage = () =>
    useDataOptions?.length > 0 ? "No matching options" : "Search for a user...";

  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          {!profilePayment ? (
            <div className={`${profilePayment ? "col-6" : "col-12"} pro-mb-4`}>
              <div className={`input-wrap ${Style.input_wrap}`}>
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  User ID
                </label>
                <Select
                  id="user_id"
                  placeholder="Search for a user..."
                  name="user_id"
                  noOptionsMessage={noOptionsMessage}
                  isClearable={true}
                  options={useDataOptions}
                  value={useDataOptions?.find(
                    (item) => item?.id === formik?.values?.user_id
                  )}
                  onInputChange={(value) => handleInputChange(value)}
                  className={`pro-input multi-select lg ${
                    formik.errors.user_id && formik.touched.user_id && " error"
                  }`}
                  classNamePrefix="pro-input"
                  onBlur={formik.handleBlur("user_id")}
                  onChange={(value) => handleUserId(value)}
                ></Select>

                {/* <input
                  type="text"
                  placeholder="Search for a user..."
                  className={`pro-input lg ${
                    formik.errors.user_id && formik.touched.user_id && " error"
                  }`}
                  id="user_id"
                  onChange={(e) => handleInputChange(e.target.value)}
                  name="user_id"
                  onBlur={formik.handleBlur("user_id")}
                />
                <div className={`${Style.custom_dropdown}`}>
                  <ul>
                    {useDataOptions?.map((item) => {
                      return (
                        <>
                        <li>
                          <a
                          className="pro-d-block"
                            onClick={() => {
                              formik.setFieldValue("user_id", item?.id);
                              setSelectedUser(item?.id);
                              setSkip(() => false);
                            }}
                          >
                            {item?.value}
                          </a>
                        </li>
                        </>
                      );
                    })}
                    
                  </ul>
                </div> */}

                {formik.touched.user_id && formik.errors.user_id && (
                  <span className="error-text">{formik.errors.user_id}</span>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

     

          <div className={`${profilePayment ? "col-6" : "col-12"} pro-mb-4`}>
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Type
              </label>
              <Select
                id="subscription_type"
                name="subscription_type"
                isClearable={true}
                options={typesOptions}
                value={
                  typesOptions?.find(
                    (item) => item?.id === formik?.values?.subscription_type
                  )
                    ? typesOptions?.find(
                        (item) => item?.id === formik?.values?.subscription_type
                      )
                    : null
                }
                className={`pro-input multi-select lg ${
                  formik.errors.subscription_type &&
                  formik.touched.subscription_type &&
                  " error"
                }`}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("subscription_type")}
                onChange={(value) =>
                  formik.setFieldValue("subscription_type", value?.id)
                }
              ></Select>
              {formik.touched.subscription_type &&
                formik.errors.subscription_type && (
                  <span className="error-text">
                    {formik.errors.subscription_type}
                  </span>
                )}
            </div>
          </div>

          <div className={`${profilePayment ? "col-6" : "col-12"} pro-mb-4`}>
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Package
              </label>
              <Select
                id="package_id"
                name="package_id"
                isClearable={true}
                isDisabled={formik?.values?.subscription_type ? false : true}
                options={packagesOptions?.filter(
                  (item) => item?.type === formik.values.subscription_type
                )}
                value={
                  packagesOptions?.find(
                    (item) => item?.id === formik?.values?.package_id
                  )
                    ? packagesOptions?.find(
                        (item) => item?.id === formik?.values?.package_id
                      )
                    : null
                }
                className={`pro-input multi-select lg ${
                  formik?.values?.subscription_type &&
                  formik.errors.package_id &&
                  formik.touched.package_id &&
                  " error"
                }`}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("package_id")}
                onChange={(value) => {
                  formik.setFieldValue("package_id", value?.id);
                  formik.setFieldValue("amount", value?.price);
                }}
              ></Select>
              {formik?.values?.subscription_type &&
                formik.touched.package_id &&
                formik.errors.package_id && (
                  <span className="error-text">{formik.errors.package_id}</span>
                )}
            </div>
          </div>
          <div className={`${profilePayment ? "col-6" : "col-12"} pro-mb-4`}>
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Mode of payment
              </label>
              <Select
                id="payment_mode"
                name="payment_mode"
                isClearable={true}
                options={paymentsOptions}
                value={
                  paymentsOptions?.find(
                    (item) => item?.id === formik?.values?.payment_mode
                  )
                    ? paymentsOptions?.find(
                        (item) => item?.id === formik?.values?.payment_mode
                      )
                    : null
                }
                className={`pro-input multi-select lg ${
                  formik.errors.payment_mode &&
                  formik.touched.payment_mode &&
                  " error"
                }`}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("payment_mode")}
                onChange={(value) =>
                  formik.setFieldValue("payment_mode", value?.id)
                }
              ></Select>
              {formik.touched.payment_mode && formik.errors.payment_mode && (
                <span className="error-text">{formik.errors.payment_mode}</span>
              )}
            </div>
          </div>
          <div className={`${profilePayment ? "col-6" : "col-12"} pro-mb-4`}>
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Amount
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.amount &&
                  formik.touched.amount &&
                  formik?.values?.package_id &&
                  " error"
                }`}
                disabled={true}
                id="amount"
                name="amount"
                {...formik.getFieldProps("amount")}
              />
              {formik.touched.amount &&
                formik.errors.amount &&
                formik?.values?.package_id && (
                  <span className="error-text">{formik.errors.amount}</span>
                )}
            </div>
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Proof of Payment
            </label>
            <div className="input-drag">
              <input
                multiple
                type="file"
                accept=".jpeg, .jpg , .png"
                ref={imageFileInputRef}
                className={`pro-input ${
                  formik.errors.payment_proofs &&
                  formik.touched.payment_proofs &&
                  " error"
                }`}
                id="payment_proofs"
                name="payment_proofs"
                onBlur={formik.handleBlur("payment_proofs")}
                onChange={(e) => handleProofImage(e)}
              />

              <span className="input-drag-box">
                <IconText
                  title={
                    formik?.values?.payment_proofs?.name !== undefined
                      ? limitStrLength(formik?.values?.payment_proofs?.name, 30)
                      : `Drop files to attach or browse`
                  }
                />
              </span>

              {formik.touched.payment_proofs &&
                formik.errors.payment_proofs && (
                  <span className="error-text">
                    {formik.errors.payment_proofs}
                  </span>
                )}
            </div>
          </div>
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Note
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.note && formik.touched.note && " error"
                }`}
                id="note"
                name="note"
                {...formik.getFieldProps("note")}
              />
              {formik.touched.note && formik.errors.note && (
                <span className="error-text">{formik.errors.note}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFields;
