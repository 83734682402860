import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useTestimonials from "./useTestimonials";
import AddFormContainer from "./AddForm";
import EmptyData from "../../Global/EmptyData";

const Testimonials = () => {
  const {
    testimonialsList,
    testimonials,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasDeletePermission,
    hasUpdatePermission,
    handleActionChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    hasExportPermission,
    showEditModal,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    // hasViewPermission,
   hasEditPermission,
    handleClearClick,
  } = useTestimonials();

  return (
    <>
      <HeadingGroup
        title={"Testimonials"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission === 1 ? "Add new" : ""}
        handleClick={() => {
          setShowform(true);
        }}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={testimonialsList?.data?.filters}
          onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          searchInputProps={{ value: testimonials?.search }}
          handleClear={handleClearClick}
          showClearFilters
          actionOptions={actionOptions?.filter((item) =>
            hasExportPermission !== 1 ? item.value === 0 : true
          )}
          onActionOptionChange={handleActionChange}
        />
        {testimonialsList?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={testimonialsList?.data?.data?.data || []}
            uniqueID={"_id"}
            fields={testimonialsList?.data?.fields}
            isSelectBox
            clear={testimonials.clearSelection}
            showCheckBox={
              hasEditPermission === 1
                ? true
                : false || hasDeletePermission === 1
                ? true
                : false
            }
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={testimonials.currentPageSize}
            assignable={false}
            editable={hasEditPermission === 1 ? true : false}
            handleEdit={handleEditAction}
            handleDelete={handleDelete}
            deletable={hasDeletePermission === 1 ? true : false}
          />
        )}

        {testimonialsList?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={testimonialsList?.data?.data?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === testimonials?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={testimonialsList?.data?.fields}
              moduleId={testimonialsList?.data?.module_id}
              updateData={updateTestimonialsFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete this item?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showform}
          handleClose={() => {
            setShowform(false);
          }}
          title={
            testimonials?.selectedItemsDetails
              ? "Edit Testimonial"
              : "Add Testimonial"
          }
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <AddFormContainer
            setShowform={setShowform}
            refetch={refetch}
            hasUpdatePermission={hasUpdatePermission}
          />
        </ModalLayout>
      </div>
    </>
  );
};

export default Testimonials;
