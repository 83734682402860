import React, { useState } from "react";
import Select from "react-select";
import { Input, IconText } from "@wac-ui-dashboard/wac_component_library";
import { limitStrLength } from "../../../../../../utils/functions/table";

const RowThree = ({
  formik,
  optionsData,
  getFieldError,
  countryCodeOptions,
  handleProofFile,
  DocumentType,
  idFileInputRef,
}) => {
  const [menuIsOpenArr, setMenuIsOpenArr] = useState(
    Array(formik.values.communication_details.length).fill(false)
  );
  const handleMenuOpen = (index) => {
    setMenuIsOpenArr((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleMenuClose = (index) => {
    setMenuIsOpenArr((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  return (
    <>
      {[...Array(3)].map((_, index) => {
        return (
          <>
            <p className=" h6 pro-pt-4">{`Contact Person ${index + 1}`} </p>
            <div className="input-country-wrap pro-mb-5">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Mobile number
              </label>
              <div className="input-main-wrap verify-btn-wrap">
                <div className="code-wrap pro-mb-4 ">
                  <Select
                    id={`communication_details[${index}].country_code`}
                    options={countryCodeOptions}
                    getOptionValue={(option) => `${option.value}`}
                    onMenuOpen={() => handleMenuOpen(index)}
                    onMenuClose={() => handleMenuClose(index)}
                    menuIsOpen={menuIsOpenArr[index]}
                    formatOptionLabel={({ label, title }) =>
                      menuIsOpenArr[index] ? title : label
                    }
                    className={` multi-select country-select ${
                      formik.touched.communication_details?.[index]
                        ?.country_code &&
                      formik.errors.communication_details?.[index]
                        ?.country_code &&
                      " error"
                    }`}
                    value={countryCodeOptions?.filter(
                      (p) =>
                        p.value?.toString() ===
                        formik.values.communication_details?.[
                          index
                        ]?.country_code?.toString()
                    )}
                    classNamePrefix="pro-input"
                    onBlur={() =>
                      formik.handleBlur(
                        `communication_details[${index}].country_code`
                      )
                    }
                    onChange={(value) =>
                      formik.setFieldValue(
                        `communication_details[${index}].country_code`,
                        value?.value
                      )
                    }
                  ></Select>
                </div>
                <input
                  type="text"
                  id={`communication_details[${index}].phone_number`}
                  name={`communication_details[${index}].phone_number`}
                  className={`pro-input lg ${
                    getFieldError(
                      `communication_details[${index}].phone_number`
                    ) && " error"
                  }`}
                  {...formik.getFieldProps(
                    `communication_details[${index}].phone_number`
                  )}
                />
              </div>
              {getFieldError(
                `communication_details[${index}].phone_number`
              ) && (
                <span className="error-text">
                  {getFieldError(
                    `communication_details[${index}].phone_number`
                  )}
                </span>
              )}
            </div>

            <Input
              label={"Contact Person Name"}
              id={`communication_details[${index}].name`}
              type="text"
              {...formik.getFieldProps(`communication_details[${index}].name`)}
              name={`communication_details[${index}].name`}
              className={`pro-input lg  ${
                getFieldError(`communication_details[${index}].name`) &&
                " error"
              }`}
              error={getFieldError(`communication_details[${index}].name`)}
              errorMessage={getFieldError(
                `communication_details[${index}].name`
              )}
            />
            <div className="input-wrap pro-mb-5">
              <label
                htmlFor={`communication_details[${index}].relationship`}
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Relationship
              </label>
              <Select
                id={`communication_details[${index}].relationship`}
                placeholder="Select"
                className={`pro-input multi-select lg ${
                  getFieldError(
                    `communication_details[${index}].relationship`
                  ) && " error"
                }`}
                classNamePrefix="pro-input"
                options={optionsData?.relationship}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.relationship?.filter(
                  (m) =>
                    formik.values.communication_details?.[index]?.relationship
                      ?.id === m?.id
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    `communication_details[${index}].relationship`,
                    value || null
                  )
                }
              />
              {getFieldError(
                `communication_details[${index}].relationship`
              ) && (
                <span className="error-text">
                  {getFieldError(
                    `communication_details[${index}].relationship`
                  )}
                </span>
              )}
            </div>
          </>
        );
      })}
      <p className=" h6 pro-pt-4">{`Document`} </p>
      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Id Type
        </label>
        <Select
          id="id_type"
          placeholder={"Select"}
          className={`pro-input multi-select lg ${
            getFieldError("id_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.id_proof_types}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.id_proof_types?.filter(
            (m) => formik.values.id_type === m?.id
          )}
          onChange={(value) =>
            formik.setFieldValue("id_type", value?.id || null)
          }
        />
        {getFieldError("id_type") && (
          <span className="error-text">{getFieldError("id_type")}</span>
        )}
      </div>

      <div>
        <div className="input-wrap pro-mb-2">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Id Proof
          </label>
          <div className="input-drag">
            <input
              type="file"
              placeholder="placeholder"
              accept=".jpg,.jpeg, .png, .pdf"
              ref={idFileInputRef}
              className={`pro-input ${
                formik.touched.id_proofs && formik.errors.id_proofs && " error"
              }`}
              id="id_proofs"
              name="id_proofs"
              onBlur={formik.handleBlur("id_proofs")}
              onChange={
                (e) => handleProofFile(e)
                // formik.setFieldValue("id_proofs", e?.target?.files[0])
              }
            />

            <span className="input-drag-box">
              {formik.values.id_proofs?.length === 0 ? (
                <IconText title={`Drop files to attach or browse`} />
              ) : formik.values.id_proofs?.[0]?.url ? (
                <IconText
                  title={`${
                    DocumentType.filter(
                      (doc) =>
                        doc.type === formik.values.id_proofs?.[0]?.id_type
                    )?.[0]?.name
                  }`}
                />
              ) : formik.values.id_proofs?.name ? (
                <span>
                  {limitStrLength(formik.values.id_proofs?.name, 30)}{" "}
                </span>
              ) : (
                <></>
              )}
            </span>
            {/* {getFieldError("id_proofs") && (
              <span className="error-text">{getFieldError("id_proofs")}</span>
            )} */}
            {formik.touched.id_proofs && formik.errors.id_proofs && (
              <span className="error-text">{formik.errors.id_proofs}</span>
            )}
          </div>
        </div>
      </div>
      <span className="pro-font-sm pro-py-5">Max upload file size 5MB</span>
    </>
  );
};

export default RowThree;
