import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useObserver from "../../../utils/hooks/useObserver";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavouritesDetails,
  updateConfig,
} from "../../../store/slices/Profile/Favourites";

const useFavorites = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [params] = useSearchParams();
  const [showScroll, setShowScroll] = useState(false);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const state = useSelector((state) => state.favourites);
  const favouritesState = state?.favouritesData?.[activeProfile];

  const reportData = [
    {
      label: "Today",
      value: `${state.favouritesData[activeProfile]?.daily_count ?? 0}`,
    },
    {
      label: "This week",
      value: `${state.favouritesData[activeProfile]?.weekly_count ?? 0}`,
    },
    {
      label: "This Month",
      value: `${state.favouritesData[activeProfile]?.monthly_count ?? 0}`,
    },
    {
      label: "All time",
      value: `${state.favouritesData[activeProfile]?.total_count ?? 0}`,
    },
  ];

  useEffect(() => {
    if (Object.keys(state?.favouritesData)?.length === 0) {
      dispatch(
        getFavouritesDetails({
          user_id: activeProfile,
          page: page,
        })
      );
    }

    if (
      state?.selectedProfiles?.length ===
      favouritesState?.data
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?.member_id)?.length
    ) {
      dispatch(
        updateConfig((state) => {
          state.selectedProfiles = favouritesState?.data
            ?.filter((item) => Boolean(item.short_listed))
            ?.map((item) => item?.member_id);
        })
      );
    }

    return () => {
      dispatch(
        updateConfig((state) => {
          state.favouritesStatus = "idle";
          state.favouritesData = {};
          state.selectedProfiles = [];
        })
      );
    };

    //eslint-disable-next-line
  }, [activeProfile]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleLoadMore = () => {
    dispatch(
      updateConfig((state) => {
        //state.currentPage = state.currentPage + 1;
        state.favouritesData[activeProfile].page += 1;
      })
    );
    setPage((prev) => prev + 1);
    dispatch(
      getFavouritesDetails({
        user_id: activeProfile,
        page: page + 1,
      })
    );
  };

  const { lastElement } = useObserver({
    loading: state?.favouritesStatus === "pending",
    hasMore:
      favouritesState?.per_page * favouritesState?.current_page <
      favouritesState?.total,
    callback: handleLoadMore,
  });

  const handleProfileRedirect = (id) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), activeProfile]
      : [activeProfile];

    const newIds = ids.filter((item) => item !== id).join(",");

    navigate({
      pathname: "/profile",
      search: `active=${id}&id=${newIds}`,
    });
  };

  return {
    reportData,
    state,
    activeProfile,
    favouritesState,
    showScroll,
    handleScrollToTop,
    lastElement,
    handleProfileRedirect,
  };
};

export default useFavorites;
