import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  viewedStatus: "idle",
  viewedData: {},
  viewedPermissions: [],
  selectedProfiles: [],
  currentPage: 1,
};

const cache = {};

export const getViewedDetails = createAsyncThunk(
  "viewed/getViewedDetails",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        params.activeFilter === 1 && params.hasviewedByUserPermission === 1
          ? `/admin/members/profile/view/user?user_id=${params.user_id}&page_size=21&page=${params?.page}`
          : `/admin/members/profile/view/others?user_id=${params.user_id}&page_size=21&page=${params?.page}`
      );
      cache[cacheKey] = response;

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const viewedSlice = createSlice({
  name: "viewed",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getViewedDetails.pending]: (state, action) => {
      state.viewedStatus = "pending";
    },
    [getViewedDetails.fulfilled]: (state, action) => {
      state.viewedPermissions = action?.payload?.data?.data?.permission;
      let newArry = state.viewedData[action.meta?.arg?.user_id]
        ? current(state.viewedData[action.meta?.arg?.user_id]?.data ?? [])
        : [];

      let tempArry = [
        ...newArry,
        ...(action?.payload?.data?.data?.data
          ? action?.payload?.data?.data?.data
          : []),
      ];

      state.selectedProfiles = tempArry
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?._id);

      if (state.viewedData?.[action.meta?.arg?.user_id]) {
        state.viewedData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          data: [
            ...state.viewedData[action.meta?.arg?.user_id].data,
            ...action.payload?.data?.data?.data,
          ],
        };
      } else {
        state.viewedData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          page: 1,
        };
      }
      state.viewedStatus = "fulfilled";
    },
    [getViewedDetails.rejected]: (state) => {
      state.viewedStatus = "failed";
    },
  },
});

export const { updateConfig } = viewedSlice.actions;

export default viewedSlice.reducer;
