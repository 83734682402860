import { Image } from "@wac-ui-dashboard/wac_component_library";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Style from "./ProfileCard.module.scss";
import useProfileCard from "./useProfileCard";
import ImageCrop from "./ImageCrop";

export const ProfileCard = ({
  data,
  userId,
  setShow,
  handleDeleteImage,
  activeImageIndex,
  setActiveImageIndex,
  setShowDeleteImageModal,
  hasUpdatePermission,
  profileData,
  deletable = true,
  handleClose,
  refetch,
}) => {
  const {
    showCrop,
    crop,
    image,
    imgRef,
    handleImageLimit,
    setCrop,
    handleThumbClick,
    handleCropClick,
    handleCropCancel,
    handlePrimary,
    handleUpload,
    handleNewImage,
  } = useProfileCard({
    data,
    userId,
    profileData,
    setShowDeleteImageModal,
    activeImageIndex,
    setActiveImageIndex,
    setShow,
    refetch,
    handleClose,
  });
  
  return (
    <div className={`${Style.root} pro-p-2`}>
      {!showCrop && !data?.[0]?.isDefault ? (
        <TransformWrapper>
          {({ zoomIn, zoomOut }) => (
            <>
              <div className={Style.action_icon}>
                {profileData?.data?.assigned_profile === 1 ? (
                  <button
                    className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                    onClick={() => handleCropClick()}
                  >
                    <span className="material-symbols-outlined">crop</span>
                  </button>
                ) : (
                  <></>
                )}
                <button
                  className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                  onClick={() => zoomIn()}
                >
                  <span className="material-symbols-outlined">zoom_in</span>
                </button>
                <button
                  className={`${Style.icon} pro-btn pro-p-0`}
                  onClick={() => zoomOut()}
                >
                  <span className="material-symbols-outlined">zoom_out</span>
                </button>
                {hasUpdatePermission === 1 &&
                deletable &&
                profileData?.data?.assigned_profile === 1 ? (
                  <button
                    className={`${Style.icon} pro-btn pro-p-0 pro-mt-1`}
                    onClick={() => handleDeleteImage()}
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <TransformComponent>
                <div className={`${Style.root_image} pro-mb-1`}>
                  <div className={Style.root_figure}>
                    <figure className={`${Style.image}`}>
                      <img
                        src={data?.[activeImageIndex]?.image ?? image}
                        alt={"profile"}
                        heigh={`100%`}
                        width={`100%`}
                      />
                    </figure>
                  </div>
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      ) : (
        <div className={`${Style.root_image} pro-mb-1`}>
          <div className={Style.root_figure}>
            <figure className={`${Style.image}`}>
              <ImageCrop
                ref={imgRef}
                image={data?.[activeImageIndex]?.image ?? image}
                crop={crop}
                setCrop={setCrop}
              />
            </figure>
          </div>
        </div>
      )}
      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {!data?.[0]?.isDefault &&
            data?.map?.((profile, index) => (
              <div
                className={`col-2`}
                key={index}
                onClick={() => !showCrop && handleThumbClick(index)}
              >
                <div
                  className={`${Style.root_image_inner} ${
                    activeImageIndex === index ? Style.active : ""
                  } ${profile.isPrimary === 1 ? Style.checked : ""}`}
                >
                  <Image
                    width={100}
                    height={100}
                    src={profile?.image}
                    alt={`image - 01`}
                  />
                </div>
              </div>
            ))}
          {image && (
            <div
              className={`col-2`}
              onClick={() => !showCrop && handleThumbClick(data.length)}
            >
              <div className={`${Style.root_image_inner} ${Style.active}`}>
                <Image
                  width={400}
                  height={100}
                  src={image}
                  alt={`image - 01`}
                />
              </div>
            </div>
          )}
          {hasUpdatePermission === 1 &&
          profileData?.data?.assigned_profile === 1 ? (
            <>
              {" "}
              {data?.length < 7 ? (
                <div className={`col-2`}>
                  <label
                    htmlFor="add_image"
                    className={`${Style.root_image_inner} ${Style.add_image} `}
                  >
                    <span className={Style.add_image_box}>
                      <p>+</p>
                      <input
                        type="file"
                        id="add_image"
                        accept=".jpeg, .jpg"
                        onChange={handleNewImage}
                      />
                    </span>
                  </label>
                </div>
              ) : (
                <div className={`col-2`} onClick={() => handleImageLimit()}>
                  <label
                    htmlFor="add_image"
                    className={`${Style.root_image_inner} ${Style.add_image} `}
                  >
                    <span className={Style.add_image_box}>
                      <p>+</p>
                    </span>
                  </label>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-between  `}
      >
        {hasUpdatePermission === 1 &&
        profileData?.data?.assigned_profile === 1 ? (
          <button
            className={`pro-btn pro-btn-primary`}
            onClick={showCrop ? handleUpload : handlePrimary}
          >
            {showCrop ? "Crop & Upload" : "Set as primary"}
          </button>
        ) : (
          <></>
        )}

        <button
          className={`pro-btn pro-btn-secondary ${
            profileData?.data?.assigned_profile === 1 ? "" : "pro-ms-auto"
          }`}
          onClick={showCrop ? handleCropCancel : handleClose}
        >
          {showCrop ? "Cancel" : "Close"}
        </button>
      </div>
    </div>
  );
};
