import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useReported from "./useReported";
import BlockModal from "../Blocked/BlockModal";
import DeleteModal from "../Blocked/DeleteModal";
import EmptyData from "../../Global/EmptyData";

const Reported = () => {
  const {
    reportedList,
    reportedState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    showBlockedModal,
    selectedUser,
    actionOptions,
    showDeleteModal,
    hasExportPermission,
    closeDeleteActionModal,
    handleActionChange,
    handleSelectedUser,
    closeActionModal,
    setShowBlockedModal,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    updateReportedFields,
    handleClearClick,
    date,
  } = useReported();

  return (
    <>
      <HeadingGroup title={"Reported"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={reportedList?.data?.filters}
          showDateRange
          onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          initialDateRange={date}
          searchInputProps={{ value: reportedState?.search }}
          actionOptions={actionOptions?.filter((item) =>
            hasExportPermission !== 1 ? item.value === 0 : true
          )}
          onActionOptionChange={handleActionChange}
        />
        {reportedList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={reportedList?.data?.data || []}
            uniqueID={"_id"}
            isSelectBox
            fields={reportedList?.data?.fields}
            handleSelect={handleSelectedUser}
            showCheckBox={false}
            multiSelect={false}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={reportedState.currentPageSize}
            assignable={false}
            deletable={false}
            editable={false}
          />
        )}

        {reportedList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              reportedList.data.total / reportedState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === reportedState?.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={reportedList?.data?.fields}
              moduleId={reportedList?.data?.module_id}
              updateData={updateReportedFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showBlockedModal} handleClose={closeActionModal}>
          <div className="pro-m-5">
            <BlockModal
              setShowBlockedModal={setShowBlockedModal}
              selectedUser={selectedUser}
              handleClose={closeActionModal}
              block
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={showDeleteModal}
          handleClose={closeDeleteActionModal}
        >
          <div className="pro-m-5">
            <DeleteModal
              selectedUser={selectedUser}
              handleClose={closeDeleteActionModal}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Reported;
