import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  showInvoiceModal: false,
  transactionId: "",
  selectedUserId: "",
  userData: [],
  invoiceLoading: false,
};

/**
 * @example {
 *  "sort_by":"name"
 *  "sort_order":"asc"

 *  }
 */

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = transactionsSlice.actions;

export default transactionsSlice.reducer;
