import React from "react";
import {
  DataContainer,
  SearchFilters,
  SimpleReport,
} from "@wac-ui-dashboard/wac_component_library";
import useContactViewed from "./useContactViewed";
import { UserCard } from "../../Global/UserCard";
import ErrorMessage from "../../Global/ErrorMessage";
import ButtonGroupShimmer from "../../Global/Shimmers/ButtonGroupShimmer";
import SimpleReportShimmer from "../../Global/Shimmers/SimpleReportShimmer";
import ProfileCardShimmer from "../../Global/Shimmers/ProfileCardShimmer";

const ContactViewed = () => {
  const {
    filters,
    state,
    activeProfile,
    contactViewedState,
    activeFilter,
    lastElement,
    handleSelect,
    showScroll,
    handleScrollToTop,
    handleTabChange,
    handleProfileRedirect,
    reportData,
  } = useContactViewed();

  return (
    <div>
      {state?.contactViewedStatus === "pending" && !contactViewedState?.data ? (
        <>
          <div className="pro-p-5">
            <ButtonGroupShimmer />
            <SimpleReportShimmer />
          </div>
          <div className="pro-p-4">
            <ProfileCardShimmer />
          </div>
        </>
      ) : (
        <>
          <div className="pro-m-4 pro-mx-5">
            <SearchFilters
              searchable={false}
              showActions={false}
              data={filters}
              activeFilter={activeFilter}
              handleButtonGroupChange={handleTabChange}
            />
          </div>
          <div className="pro-m-4 pro-mx-5">
            <DataContainer>
              <SimpleReport
                data={reportData}
                // showButton
                // buttonText={<span className="material-symbols-outlined">tune</span>}
              />
            </DataContainer>
          </div>
          <div style={{ display: "flex", margin: "20px", flexWrap: "wrap" }}>
            {showScroll && (
              <button
                className={`scoll-top`}
                onClick={() => handleScrollToTop()}
              >
                <span className="material-symbols-outlined">expand_less</span>
              </button>
            )}
            {contactViewedState?.data?.map((value, index) => {
              return (
                <UserCard
                  key={index}
                  isCheckBox={false}
                  isPremium={Boolean(Number(value?.profile_verified_status))}
                  age={value?.age}
                  checked={state?.selectedProfiles?.filter?.(
                    (a) => a !== activeProfile
                  )}
                  data={value || {}}
                  handleProfileRedirect={handleProfileRedirect}
                  handleSelect={handleSelect}
                  src={value?.profile_image ?? ""}
                  title={value?.first_name ?? ""}
                  subtitle={value?.last_name ?? ""}
                  isShortlisted={Boolean(Number(value?.short_listed ?? 0))}
                  lastElement={
                    index === contactViewedState?.data?.length - 10
                      ? lastElement
                      : false
                  }
                  ViewCount={value?.view_count ?? 0}
                  graphIcon={
                    <span className="material-symbols-outlined">
                      auto_graph
                    </span>
                  }
                  GraphPercentage={value?.profile_percentage}
                  ViewIcon={
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  }
                />
              );
            })}
          </div>
        </>
      )}
      {(contactViewedState?.data?.length === undefined ||
        contactViewedState?.data?.length === 0) &&
      state?.contactViewedStatus !== "pending" ? (
        <ErrorMessage />
      ) : (
        <></>
      )}
      {state?.contactViewedStatus === "pending" && contactViewedState?.data && (
        <div className="pro-w-100 pro-mb-4">
          <ProfileCardShimmer loading />
        </div>
      )}
    </div>
  );
};

export default ContactViewed;
