import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Users/deletedAccountsSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import {
  useGetDeletedAccountsListDataQuery,
  useUpdateDeletedAccountsListFieldsDataMutation,
} from "../../../store/queries/users";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { useNavigate } from "react-router-dom";
import { getExportedData } from "./api";
import moment from "moment";
import { useSearchParams } from "react-router-dom/dist";

const useDeletedAccounts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deletedAccounts = useSelector((state) => state.deletedAccounts);

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  useEffect(() => {
    if (deletedAccounts.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  const [updateDeletedAccountsListFields] =
    useUpdateDeletedAccountsListFieldsDataMutation();

  const {
    data: deletedAccountList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetDeletedAccountsListDataQuery({
    page_size: deletedAccounts.currentPageSize,
    page: deletedAccounts.currentPage,
    sort_by: deletedAccounts.sortBy,
    sort_order: deletedAccounts.sortOrder,
    start: deletedAccounts.startDate,
    end: deletedAccounts.endDate,
    search: deletedAccounts.search,
  });

  const hasExportPermission = useMemo(() => {
    let permission = deletedAccountList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [deletedAccountList]);

  const hasViewPermission = useMemo(() => {
    let permission = deletedAccountList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [deletedAccountList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleSort = (label) => {
    if (deletedAccounts.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            deletedAccounts.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      name: (_, data) => (
        <ProfileNavigation
          userId={data?._id}
          fieldValue={data?.name}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={hasViewPermission === 1 ? true : false}
        />
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.showEditModal = false;
        state.selectedItemsDetails = "";
        state.search = "";
      })
    );
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(deletedAccountList?.data?.fields),
        filter: deletedAccounts.currentFilter,
        sort_by: deletedAccounts.sortBy,
        sort_order: deletedAccounts.sortOrder,
        start: deletedAccounts.startDate,
        end: deletedAccounts.endDate,
        search: deletedAccounts.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleDashboardRedirect = () => {
    navigate("/users/deleted-accounts");
  };

  return {
    deletedAccountList,
    deletedAccounts,
    currentPage: deletedAccounts.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    hasExportPermission,
    handleDashboardRedirect,
    handleSearch,
    handlePagination,
    handleDateChange,
    actionOptions,
    handleActionChange,
    handlePageSize,
    handleSort,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    updateDeletedAccountsListFields,
    selectedItemID,
    setSelectedItemID,
    handleClearClick,
  };
};

export default useDeletedAccounts;
