import style from "./dashboardCard.module.scss";
const DashboardCard = ({ title, data, icon, active, transactions }) => {
  return (
    <div className={`${style.root} ${active ? `active` : ""}`}>
      <div className={`${style.inner}`}>
        <div className={`${style.inner_heading} pro-mb-3`}>
          <div className={`${style.icon}`}>
            <span className="material-symbols-outlined">{icon}</span>
          </div>
          <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium pro-ps-2">
            {data?.month ? `${title}(${data?.month}) ` : title}
          </h6>
        </div>
        <div className={`${style.body} pro-d-flex`}>
          <h3 className={`pro-ttl pro-mb-0 pro-fw-bold ${style.total_value}`}>
            {transactions ? (
              <span class="material-symbols-outlined">currency_rupee</span>
            ) : (
              <></>
            )}
            {data?.total ? data?.total : data}
          </h3>
          {data?.growth_percentage && (
            <span
              className={`${
                data?.growth_type === 1
                  ? style.increment
                  : data?.growth_type === 2
                  ? style.decrement
                  : ""
              } ${
                style.growth_percentage
              } pro-align-self-center pro-font-xs pro-fw-medium`}
            >
              {data?.growth_percentage}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
