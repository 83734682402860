import { useDispatch, useSelector } from "react-redux";
import {
  useGetTestimonialsListDataQuery,
  useTestimonialsDeleteMultipleMutation,
  useTestimonialsDeleteSingleMutation,
  useUpdateTestimonialsListDataMutation,
} from "../../../store/queries/settings";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import Style from "./testimonials.module.scss";
import {
  deleteItemFromTable,
  editItemFromTableByID,
  updateConfig,
} from "../../../store/slices/Settings/testimonialsSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { changeStatus } from "./api";
import { toast } from "react-toastify";

const StatusButton = ({ data, refetch }) => {
  const [status, setStatus] = useState(
    data?.status === "Live"
      ? { value: 1, label: "Live" }
      : { value: 0, label: "Unpublish" }
  );

  const options = [
    { value: 1, label: "Live" },
    { value: 0, label: "Unpublish" },
  ];

  const handleChange = (selectedOption) => {
    setStatus(selectedOption);
    let obj = {
      testimonial_id: data?._id,
      status: selectedOption?.value,
    };
    changeStatus(obj).then((res) => {
      if (res?.data?.status_code === 200) {
        refetch();
        toast.success("Status Updated Successfully");
      } else {
        toast.error("Failed to Update Status");
      }
    });
  };

  return (
    <div className="col-12 no-border" style={{ minWidth: "222px" }}>
      <Select
        options={options?.filter((item) => item.value !== status.value)}
        value={status}
        onChange={handleChange}
        className={Style.reactSelectContainer}
        classNamePrefix="pro-input"
      />
    </div>
  );
};

const useTestimonials = () => {
  const dispatch = useDispatch();

  const testimonials = useSelector((state) => state.testimonials);

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    if (testimonials.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  useEffect(() => {
    if (testimonials.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [testimonials.clearSelection]);

  const [updateTestimonialsFields] = useUpdateTestimonialsListDataMutation();

  const [testimonialsDeleteSingle, { isLoading1 }] =
    useTestimonialsDeleteSingleMutation();

  const [testimonialsDeleteMultiple, { isLoading2 }] =
    useTestimonialsDeleteMultipleMutation();

  const {
    data: testimonialsList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetTestimonialsListDataQuery({
    page_size: testimonials.currentPageSize,
    page: testimonials.currentPage,
    sort_by: testimonials.sortBy,
    sort_order: testimonials.sortOrder,
    start: testimonials.startDate,
    end: testimonials.endDate,
    search: testimonials.search,
  });

  const hasCreatePermission = useMemo(() => {
    let permission = testimonialsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [testimonialsList]);

  const hasUpdatePermission = useMemo(() => {
    let permission = testimonialsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [testimonialsList]);

  const hasViewPermission = useMemo(() => {
    let permission = testimonialsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [testimonialsList]);

  const hasEditPermission = useMemo(() => {
    let permission = testimonialsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? 0;
  }, [testimonialsList]);

  const hasDeletePermission = useMemo(() => {
    let permission = testimonialsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? 0;
  }, [testimonialsList]);

  const hasStatusUpdatePermission = useMemo(() => {
    let permission = testimonialsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_status")
    );

    return permission?.[0]?.can_status ?? 0;
  }, [testimonialsList]);

  const hasExportPermission = useMemo(() => {
    let permission = testimonialsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [testimonialsList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable({ testimonial_id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result.data.data) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };
  const handleSort = (label) => {
    if (testimonials.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = testimonials.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID({ testimonial_id: ids[0] }))
        .unwrap()
        .then((data) => {
          setShowform(true);
        });
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_id: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
      ),
      status: (feild, data) =>
        hasStatusUpdatePermission === 1 ? (
          <StatusButton data={data} refetch={refetch} />
        ) : (
          <p className="pro-mb-0">{data[feild] ?? ""}</p>
        ),
      marriage_date: (feild, data) => (
        <p className="pro-mb-0">{data[feild].slice() ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.showEditModal = false;
        state.selectedItemsDetails = "";
        state.search = "";
      })
    );
  };

  return {
    testimonialsList,
    testimonials,
    currentPage: testimonials.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    hasViewPermission,
    hasUpdatePermission,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    hasExportPermission,
    hasDeletePermission,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    showform,
    setShowform,
    actionOptions,
    handleActionChange,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    testimonialsDeleteSingle,
    isLoading1,
    testimonialsDeleteMultiple,
    isLoading2,
    hasCreatePermission,
    hasEditPermission,
    handleClearClick,
  };
};

export default useTestimonials;
