const useProfilePreview = ({ profileData }) => {
  const educationLabel = {
    education_cat: "Education Category",
    education: "Education Details",
    institution: "Institution",
  };

  const educationValue = {
    education_cat: `${
      profileData?.data?.data?.education_category?.name ?? "Not specified"
    }`,
    education: `${profileData?.data?.data?.education?.name ?? "Not specified"}`,
    institution: `${
      profileData?.data?.data?.institution_name ?? "Not specified"
    }`,
  };

  const educationDetails = Object.keys(educationValue).map((key) => {
    return {
      label: educationLabel[key],
      value:
        typeof educationValue[key] === "string"
          ? educationValue?.[key]
          : typeof educationValue[key] === "number"
          ? educationValue?.[key]
          : educationValue?.[key]?.name,
    };
  });

  const occupationLabel = {
    occupation_cat: "Occupation Category",
    occupation: "Occupation Details",
    work_location: "Work Location",
    income: "Annual Income",
  };

  const workDistrict = profileData?.data?.data?.work_district?.name ?? "";
  const workState = profileData?.data?.data?.work_state?.name ?? "";
  const workCountry = profileData?.data?.data?.work_country?.name ?? "";

  const workAddressFields = [workDistrict, workState, workCountry];
  const workAddressStr = workAddressFields.filter(Boolean).join(", ");

  const occupationValue = {
    occupation_cat: `${
      profileData?.data?.data?.occupation_category?.name ?? "Not specified"
    }`,
    occupation: `${
      profileData?.data?.data?.occupation?.name ?? "Not specified"
    }`,
    work_location: workAddressStr || "Not specified",
    income: `${
      profileData?.data?.data?.annual_income?.value ?? "Not specified"
    } `,
  };

  const occupationDetails = Object.keys(occupationValue).map((key) => {
    return {
      label: occupationLabel[key],
      value:
        typeof occupationValue[key] === "string"
          ? occupationValue?.[key]
          : typeof occupationValue[key] === "number"
          ? occupationValue?.[key]
          : occupationValue?.[key]?.name,
    };
  });

  const numberOrdinals = { 1: "", 0: "", all: "s" };

  const getOrdinalNumbers = (no) => {
    return `${numberOrdinals?.[no] ?? numberOrdinals["all"]}`;
  };

  const familyLabel = {
    father_name: "Father's Name",
    father_occupation: "Father's Occupation",
    mother_name: "Mother's Name",
    mother_occupation: "Mother's Occupation",
    fam_status: "Family Status",
    fam_type: "Family Type",
    no_of_brothers: `No of Brother${getOrdinalNumbers(
      profileData?.data?.data?.sibling_details?.no_of_brothers ?? 1
    )} `,
    no_of_brothers_married: `Brother${getOrdinalNumbers(
      profileData?.data?.data?.sibling_details?.no_of_brothers ?? 1
    )} Married`,
    no_of_sisters: `No of Sister${getOrdinalNumbers(
      profileData?.data?.data?.sibling_details?.no_of_sisters ?? 1
    )}`,
    no_of_sisters_married: `Sister${getOrdinalNumbers(
      profileData?.data?.data?.sibling_details?.no_of_sisters ?? 1
    )} Married`,
  };

  const familyValue = {
    father_name: `${
      profileData?.data?.data?.father_details?.name ?? "Not specified"
    }`,
    father_occupation: `${
      profileData?.data?.data?.father_details?.occupation?.name ??
      "Not specified"
    }`,
    mother_name: `${
      profileData?.data?.data?.mother_details?.name ?? "Not specified"
    }`,
    mother_occupation: `${
      profileData?.data?.data?.mother_details?.occupation?.name ??
      "Not specified"
    }`,
    fam_status: `${
      profileData?.data?.data?.family_status?.name ?? "Not specified"
    }`,
    fam_type: `${
      profileData?.data?.data?.family_type?.name ?? "Not specified"
    }`,
    no_of_brothers: `${
      profileData?.data?.data?.sibling_details?.no_of_brothers ??
      "Not specified"
    }`,
    no_of_brothers_married: `${
      profileData?.data?.data?.sibling_details?.brothers_married ??
      "Not specified"
    }`,
    no_of_sisters: `${
      profileData?.data?.data?.sibling_details?.no_of_sisters ?? "Not specified"
    }`,
    no_of_sisters_married: `${
      profileData?.data?.data?.sibling_details?.sisters_married ??
      "Not specified"
    }`,
  };

  const familyDetails = Object.keys(familyValue).map((key) => {
    return {
      label: familyLabel[key],
      value:
        typeof familyValue[key] === "string"
          ? familyValue?.[key]
          : typeof familyValue[key] === "number"
          ? familyValue?.[key]
          : familyValue?.[key]?.name,
    };
  });

  const contactLabel = {
    present_address: "Present Address",
    permanent_address: "Permanent Address",
  };

  const presentAddress = profileData?.data?.data?.present_address;
  const permanentAddress = profileData?.data?.data?.permanent_address;

  const presentHouseName = presentAddress?.house_name ?? "";
  const presentStreet = presentAddress?.street ?? "";
  const presentDistrict = presentAddress?.district?.name ?? "";
  const presentPincode = presentAddress?.pincode ?? "";

  const permanentHouseName = permanentAddress?.house_name ?? "";
  const permanentStreet = permanentAddress?.street ?? "";
  const permanentDistrict = permanentAddress?.district?.name ?? "";
  const permanentPincode = permanentAddress?.pincode ?? "";

  const presentAddressFields = [
    presentHouseName,
    presentStreet,
    presentDistrict,
    presentPincode,
  ];
  const permanentAddressFields = [
    permanentHouseName,
    permanentStreet,
    permanentDistrict,
    permanentPincode,
  ];

  const presentAddressStr = presentAddressFields.filter(Boolean).join(", ");
  const permanentAddressStr = permanentAddressFields.filter(Boolean).join(", ");

  const contactValue = {
    present_address: presentAddressStr || "Not specified",

    permanent_address: permanentAddressStr || "Not specified",
  };

  const contactDetails = Object.keys(contactValue).map((key) => {
    return {
      label: contactLabel[key],
      value:
        typeof contactValue[key] === "string"
          ? contactValue?.[key]
          : typeof contactValue[key] === "number"
          ? contactValue?.[key]
          : contactValue?.[key]?.name,
    };
  });

  return {
    educationDetails,
    occupationDetails,
    contactDetails,
    familyDetails,
  };
};

export default useProfilePreview;
