import { useFormik } from "formik/dist";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  useGenerateOtpMutation,
  useGetMemberCreationFormDataQuery,
  useValidateOtpMutation,
} from "../../../store/queries/members";
import { toast } from "react-toastify";
import { useMemo } from "react";
import countryData from "../../../utils/components/countryCode";
import {
  deleteMember,
  getSettingsData,
  updateContactDetails,
  updatePassword,
} from "./api";
import { useSearchParams } from "react-router-dom";
import { emailRegExp, phoneRegExp } from "../../../utils/functions/table";
import { blockUser } from "../../Users/Blocked/BlockModal/api";
import { useGetOverviewDataQuery } from "../../../store/queries/Profile";

const useProfileSettings = () => {
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const [phoneVerified, setPhoneVerified] = useState({
    verified: false,
    status: false,
  });
  const [otp, setOtp] = useState("");
  const [settingsData, setSettingsData] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [verifyPhone] = useGenerateOtpMutation();
  const [validatePhone, validatePhoneStatus] = useValidateOtpMutation();
  const [showPassword, setShowPassword] = useState(true);
  const [showStaffPassword, setShowStaffPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetMemberCreationFormDataQuery({});

  let settingsValidation = Yup.object({
    phone_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(14, "The phone number must be between 7 and 14 digits")
      .required("*Required"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    password: Yup.string()
      .required("*Required")
      .min(8, "Password is too short - should be 8 chars minimum."),
    confirm_password: Yup.string()
      .required("*Required")
      .min(8, "Password is too short - should be 8 chars minimum."),
    reset_reason_id: Yup.string().required("*Required"),
    reason: Yup.string().required("*Required"),
    duration: Yup.number().typeError("period must be a number")
      .max(180, "The period must not be greater than 180")
      .required("*Required"),
    delete_reason_id: Yup.string().required("*Required"),
    staff_password: Yup.string().required("*Required"),
  });

  useEffect(() => {
    if (settingsData !== " ") {
      getSettingsData(activeProfile).then((res) => {
        let fieldData = res?.data?.data;
        setSettingsData(fieldData);
        Object.keys(fieldData?.data || {}).forEach((key) => {
          formik.setFieldValue(
            key,
            fieldData?.data?.[key] || fieldData?.data?.[key]?._id
          );
        });
      });
    }
    // eslint-disable-next-line
  }, [settingsData !== " "]);

  const {
    data: overViewData,
    // refetch,
  } = useGetOverviewDataQuery({
    user_id: activeProfile,
  });

  const hasBanPermission = useMemo(() => {
    let permission = settingsData?.permission?.filter((p) =>
      Object.keys(p).includes("can_ban")
    );

    return permission?.[0]?.can_ban ?? 0;
  }, [settingsData]);
  const hasUpdateContactPermission = useMemo(() => {
    let permission = settingsData?.permission?.filter((p) =>
      Object.keys(p).includes("can_update_contact")
    );

    return permission?.[0]?.can_update_contact ?? 0;
  }, [settingsData]);
  const hasUpdatePasswordPermission = useMemo(() => {
    let permission = settingsData?.permission?.filter((p) =>
      Object.keys(p).includes("can_update_password")
    );

    return permission?.[0]?.can_update_password ?? 0;
  }, [settingsData]);
  const hasDeletePermission = useMemo(() => {
    let permission = settingsData?.permission?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? 0;
  }, [settingsData]);

  const formik = useFormik({
    initialValues: {
      email: "",
      country_code: "+91",
      phone_number: "",
      password: "",
      confirm_password: "",
      reset_reason_id: "",
      reason: "",
      duration: "",
      delete_reason_id: "",
      staff_password: "",
    },

    validationSchema: settingsValidation,

    // onSubmit: (values, { resetForm }) => {

    // },
  });

  const handlePhoneNumberChange = (value) => {
    if (value !== formik.values.phone_number) {
      setPhoneVerified({
        verified: false,
        status: false,
      });
      setOtp("");
      formik.setFieldValue("otp", "");
    }
    formik.setFieldValue("phone_number", value);
  };

  const handleVerifyPhone = () => {
    verifyPhone({
      country_code: formik.values.country_code,
      phone_number: formik.values.phone_number,
    })
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          setPhoneVerified({
            verified: false,
            status: true,
          });
          setOtp(() => response?.data?.data);
          toast.success(
            `OTP sent to ${
              formik.values.country_code + " " + formik.values.phone_number
            } !`
          );
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else if (response?.data?.status_code === 400) {
          toast.error(response?.data?.message);
          formik.setErrors(response?.data?.message);
        } else {
          toast.error("Failed to Sent OTP!");
        }
      })
      .catch(() => {
        toast.error("Failed to Sent OTP!");
      });
  };

  const handleValidatePhone = () => {
    validatePhone({
      country_code: formik.values.country_code,
      phone_number: formik.values.phone_number,
      otp: Number(formik.values.otp),
    })
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          setPhoneVerified({
            verified: true,
            status: false,
          });
          toast.success("Phone Validated Successfully!");
        } else if (response?.error?.data?.status_code === 400) {
          toast.error("Failed to Sent OTP!");
          formik.setErrors(response?.error?.data?.message);
        } else if (response?.error?.data?.status_code === 400) {
          toast.error("Failed to Verify OTP!");
          formik.setErrors(response?.error?.data?.message);
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Verify OTP!");
        }
      })
      .catch(() => {
        toast.error("Failed to Verify OTP!");
      });
  };

  const handleContactUpdateClick = () => {
    let formData = new FormData();

    formData.append("_method", "PUT");
    formData.append("member_id", activeProfile);

    if (formik.values?.email !== settingsData?.data?.email) {
      formData.append("email", formik.values.email);
    } else {
      formData.append("country_code", formik.values.country_code);
      formData.append("phone_number", formik.values.phone_number);
    }

    if (phoneVerified?.verified) {
      updateContactDetails(formData).then((res) => {
        if (res?.data?.status_code === 200) {
          toast.success(res?.data?.message);
        } else {
          toast.error("Failed to update");
        }
      });
    } else if (formik.values?.email !== settingsData?.data?.email) {
      updateContactDetails(formData).then((res) => {
        if (res?.data?.status_code === 200) {
          getSettingsData(activeProfile).then((res) => {
            let fieldData = res?.data?.data;
            setSettingsData(fieldData);
            Object.keys(fieldData?.data || {}).forEach((key) => {
              formik.setFieldValue(
                key,
                fieldData?.data?.[key] || fieldData?.data?.[key]?._id
              );
            });
          });
          toast.success(res?.data?.message);
        } else {
          toast.error("Failed to update");
        }
      });
    } else {
      formik.setFieldError("phone_number", "Verify Phone Number");
      toast.error("Verify Phone Number");
    }
  };

  const handleChangePasswordClick = () => {
    let formData = new FormData();

    formData.append("_method", "PUT");
    formData.append("member_id", activeProfile);
    formData.append("password", formik.values.password);
    formData.append("confirm_password", formik.values.confirm_password);
    formData.append("reset_reason_id", formik.values.reset_reason_id?._id);

    updatePassword(formData).then((res) => {
      if (res?.data?.status_code === 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error("Failed to update");
      }
    });
  };

  const handleBanUserClick = () => {
    let obj = {
      member_id: activeProfile,
      reason: formik.values.reason,
      status: 1,
      duration: formik.values.duration,
    };

    blockUser(obj).then((response) => {
      if (response?.data?.status_code === 200) {
        formik.resetForm();
        toast.success(response?.data?.message);
      } else if (response?.status_code === 400) {
        formik.setErrors(response?.message);
        toast.error("Failed to Block");
      } else {
        toast.error("Failed to Block");
      }
    });
  };

  const handleDeleteUserClick = () => {
    let formData = new FormData();

    formData.append("_method", "PUT");
    formData.append("member_id", activeProfile);
    formData.append("delete_reason_id", formik.values.delete_reason_id?._id);
    formData.append("password", formik.values.staff_password);

    deleteMember(formData).then((response) => {
      if (response?.data?.status_code === 200) {
        formik.resetForm();
        toast.success(response?.data?.message);
      } else {
        formik.setErrors(response?.data?.message);
        toast.error(response?.data?.message);
      }
    });
  };

  const handleMenuOpen = () => {
    setMenuIsOpen(() => true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(() => false);
  };

  const cuntryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${"           "} ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowStaffPassword = (e) => {
    e.preventDefault();
    setShowStaffPassword(!showStaffPassword);
    var x = document.getElementById("staff_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");

    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    handleVerifyPhone,
    handleValidatePhone,
    handleContactUpdateClick,
    handleMenuOpen,
    handleMenuClose,
    handleShowPassword,
    handleShowConfirmPassword,
    handleChangePasswordClick,
    handleBanUserClick,
    handleDeleteUserClick,
    handlePhoneNumberChange,
    otp,
    overViewData,
    hasBanPermission,
    hasDeletePermission,
    hasUpdateContactPermission,
    hasUpdatePasswordPermission,
    validatePhoneStatus,
    isOptionDataSuccess,
    optionsData: optionsData?.data,
    showConfirmPassword,
    showStaffPassword,
    settingsData,
    handleShowStaffPassword,
    showPassword,
    cuntryCodeOptions,
    menuIsOpen,
    phoneVerified,
    formik,
  };
};

export default useProfileSettings;
