import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  contactViewedStatus: "idle",
  contactViewedData: {},
  contactViewedPermissions: [],
  selectedProfiles: [],
  currentPage: 1,
};

const cache = {};

export const getContactViewedDetails = createAsyncThunk(
  "contactViewed/getContactViewedDetails",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }

    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        params.activeFilter === 1 && params.hasviewedByUserPermission === 1
          ? `/admin/members/profile/contacts/others/view?user_id=${params.user_id}&page_size=21&page=${params?.page}`
          : `/admin/members/profile/contacts/user/view?user_id=${params.user_id}&page_size=21&page=${params?.page}`
      );

      cache[cacheKey] = response;

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const contactViewedSlice = createSlice({
  name: "contactViewed",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getContactViewedDetails.pending]: (state, action) => {
      state.contactViewedStatus = "pending";
    },
    [getContactViewedDetails.fulfilled]: (state, action) => {
      state.contactViewedPermissions = action?.payload?.data?.data?.permission;
      let newArry = state.contactViewedData[action.meta?.arg?.user_id]
        ? current(
            state.contactViewedData[action.meta?.arg?.user_id]?.data ?? []
          )
        : [];

      let tempArry = [
        ...newArry,
        ...(action?.payload?.data?.data?.data?.data
          ? action?.payload?.data?.data?.data?.data
          : []),
      ];

      state.selectedProfiles = tempArry
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?._id);

      if (state.contactViewedData?.[action.meta?.arg?.user_id]) {
        state.contactViewedData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data?.data,
          data: [
            ...state.contactViewedData[action.meta?.arg?.user_id].data,
            ...action.payload?.data?.data?.data?.data,
          ],
        };
      } else {
        state.contactViewedData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data?.data,
          page: 1,
        };
      }
      state.contactViewedStatus = "fulfilled";
    },
    [getContactViewedDetails.rejected]: (state) => {
      state.contactViewedStatus = "failed";
    },
  },
});

export const { updateConfig } = contactViewedSlice.actions;

export default contactViewedSlice.reducer;
