import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  PaymentHistory,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useTransactions from "./useTransactions";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import AddPaymentForm from "../AddPaymentForm";
import Invoice from "./Invoice";
import SwitchableNav from "./SwitchableNav";
import DashboardHero from "../../Dashboard/DashboardHero";
import EmptyData from "../../Global/EmptyData";
import Style from "./transactions.module.scss";
import ErrorMessage from "../../Global/ErrorMessage";

const Transactions = ({ dashboard = false }) => {
  const {
    transactions,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    showEditModal,
    tableFields,
    currentPage,
    paginationOptions,
    showInvoiceModal,
    activeTab,
    profilePaymentHistory,
    selectedUser,
    actionOptions,
    TransactionItems,
    hasExportPermission,
    setSkip,
    handleDashboardRedirect,
    handleActionChange,
    setSelectedUser,
    handleActiveTab,
    refetch,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    handlePagination,
    closeEditModal,
    handleDateChange,
    handlePageSize,
    updateTransactionFields,
    closeInvoiceModal,
    handleClearClick,
    TransactionsState,
    date,
  } = useTransactions(dashboard);

  


  return (
    <>
      {!dashboard ? (
        <>
          <HeadingGroup
            title={"Transactions"}
            buttonTitle={hasCreatePermission === 1 ? "Add payment" : ""}
            className={`pro-mb-4`}
            handleClick={handleCreateClick}
          />
          <div className={`pro-w-100 pro-my-5`}>
            <DashboardHero
              data={transactions?.data?.collection_details}
              obj={TransactionItems}
              transactions={true}
              loading={isFetching}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard ? (
          <SearchFilters
            showDateRange
            onDateChange={handleDateChange}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            showClearFilters
            handleClear={handleClearClick}
            initialDateRange={date}
            searchInputProps={{ value: TransactionsState?.search }}
            actionOptions={actionOptions?.filter((item) =>
              hasExportPermission !== 1 ? item.value === 0 : true
            )}
            onActionOptionChange={handleActionChange}
          />
        ) : (
          <></>
        )}

        {transactions?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : Style.dashboardTable}
          >
            <Table
              data={transactions?.data?.data?.data || []}
              uniqueID={"user_unique_id"}
              //fields={adons?.data?.fields}
              fields={tableFields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={TransactionsState?.currentPageSize}
              extraClasssName={"dashboard_no_padding"}
            />
          </div>
        )}

        {transactions?.data?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={transactions?.data?.data?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === TransactionsState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          title={"Payment detail"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <div className="">
            <SwitchableNav
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
            />
            {activeTab === "payment" ? (
              <AddPaymentForm
                refetch={refetch}
                selectedUser={selectedUser}
                setSkip={setSkip}
                setSelectedUser={setSelectedUser}
              />
            ) : (
              profilePaymentHistory?.data?.map((item) => {
                return (
                  <div className="pro-my-4">
                    <PaymentHistory
                      icon={
                        <span className="material-symbols-outlined">
                          package
                        </span>
                      }
                      title={item?.name}
                      date={item?.date}
                      price={item?.amount}
                      paymentMethod={item?.payment_type}
                    />
                  </div>
                );
              })
            )}

            {(profilePaymentHistory?.data?.length === undefined ||
              profilePaymentHistory?.data?.length === 0) &&
            activeTab === "history" ? (
              <div className="pro-mt-5">
                <ErrorMessage />
              </div>
            ) : (
              <></>
            )}
          </div>
        </OffCanvasLayout>
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={transactions?.data?.fields}
              moduleId={transactions?.data?.module_id}
              updateData={updateTransactionFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={showInvoiceModal}
          handleClose={closeInvoiceModal}
          centered={false}
          // title={"Invoice"}
          // closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Invoice />
        </ModalLayout>
      </div>
    </>
  );
};

export default Transactions;
