import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useFamily from "./useFamily";

const Family = ({
  optionsData,
  isOptionDataSuccess,
  isEditProfile,
  profileRefetch,
  className,
  isStickyFooter,
}) => {
  const { formik, handleCloseModal, getFieldError } = useFamily({
    isOptionDataSuccess,
    isEditProfile,
    profileRefetch
  });

  return (
    <div className={className}>
      <Input
        label={"Father’s name"}
        id="father_details[name]"
        type="text"
        name="father_details[name]"
        className={`pro-input lg  ${
          getFieldError("father_details.name") && " error"
        }`}
        {...formik.getFieldProps("father_details[name]")}
        error={getFieldError("father_details.name")}
        errorMessage={getFieldError("father_details.name")}
      />
      <Input
        label={"Father’s House Name"}
        id="father_details[house_name]"
        type="text"
        name="father_details[house_name]"
        className={`pro-input lg  ${
          getFieldError("father_details.house_name") && " error"
        }`}
        {...formik.getFieldProps("father_details[house_name]")}
        error={getFieldError("father_details.house_name.")}
        errorMessage={getFieldError("father_details.house_name")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Father’s Occupation
        </label>

        <Select
          id="father_details[occupation]"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("father_details.occupation") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.occupation}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.occupation?.filter(
            (m) => formik.values.father_details?.occupation?._id === m?._id
          )}
          onBlur={formik.handleBlur("father_details[occupation]")}
          onChange={(value) =>
            formik.setFieldValue("father_details[occupation]", value || null)
          }
        ></Select>
        {getFieldError("father_details.occupation") && (
          <span className="error-text">
            {getFieldError("father_details.occupation")}
          </span>
        )}
      </div>
      <Input
        label={"Father’s Native place"}
        id="father_details[native_place]"
        type="text"
        name="father_details[native_place]"
        className={`pro-input lg  ${
          getFieldError("father_details.native_place") && " error"
        }`}
        {...formik.getFieldProps("father_details[native_place]")}
        error={getFieldError("father_details.native_place")}
        errorMessage={getFieldError("father_details.native_place")}
      />

      <Input
        label={"Mother’s name"}
        id="mother_details[name]"
        type="text"
        name="mother_details[name]"
        className={`pro-input lg ${
          getFieldError("mother_details.name") && " error"
        }`}
        {...formik.getFieldProps("mother_details[name]")}
        error={getFieldError("mother_details.name")}
        errorMessage={getFieldError("mother_details.name")}
      />

      <Input
        label={"Mother’s House Name"}
        id="mother_details[house_name]"
        type="text"
        name="mother_details[house_name]"
        className={`pro-input lg  ${
          getFieldError("mother_details.house_name") && " error"
        }`}
        {...formik.getFieldProps("mother_details[house_name]")}
        error={getFieldError("mother_details.house_name")}
        errorMessage={getFieldError("mother_details.house_name")}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Mother’s Occupation
        </label>
        <Select
          id="mother_details[occupation]"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("mother_details.occupation") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.occupation}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.occupation?.filter(
            (m) => formik.values.mother_details?.occupation?._id === m?._id
          )}
          onBlur={formik.handleBlur("mother_details[occupation]")}
          onChange={(value) =>
            formik.setFieldValue("mother_details[occupation]", value || null)
          }
        ></Select>
        {getFieldError("mother_details.occupation") && (
          <span className="error-text">
            {getFieldError("mother_details.occupation")}
          </span>
        )}
      </div>

      <Input
        label={"Mother’s Native place"}
        id="mother_details[native_place]"
        type="text"
        name="mother_details[native_place]"
        className={`pro-input lg  ${
          getFieldError("mother_details.native_place") && " error"
        }`}
        {...formik.getFieldProps("mother_details[native_place]")}
        error={getFieldError("mother_details.native_place")}
        errorMessage={getFieldError("mother_details.native_place")}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Family Type
        </label>
        <Select
          id="family_type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("family_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.family_type}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.family_type?.filter(
            (m) => formik.values.family_type?.id === m?.id
          )}
          onBlur={formik.handleBlur("family_type")}
          onChange={(value) =>
            formik.setFieldValue("family_type", value || null)
          }
        ></Select>
        {getFieldError("family_type") && (
          <span className="error-text">{getFieldError("family_type")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Family Status
        </label>
        <Select
          id="family_status"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("family_status") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.family_status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.family_status?.filter(
            (m) => formik.values.family_status?.id === m?.id
          )}
          onBlur={formik.handleBlur("family_status")}
          onChange={(value) =>
            formik.setFieldValue("family_status", value || null)
          }
        ></Select>
        {getFieldError("family_status") && (
          <span className="error-text">{getFieldError("family_status")}</span>
        )}
      </div>

      <Input
        label={"No. of Brothers"}
        id="sibling_details[no_of_brothers]"
        type="text"
        name="sibling_details[no_of_brothers]"
        className={`pro-input lg  ${
          getFieldError("sibling_details.no_of_brothers") && " error"
        }`}
        {...formik.getFieldProps("sibling_details[no_of_brothers]")}
        error={getFieldError("sibling_details.no_of_brothers")}
        errorMessage={getFieldError("sibling_details.no_of_brothers")}
      />

      <Input
        label={"No. of Brothers married"}
        id="sibling_details.brothers_married"
        type="text"
        name="sibling_details.brothers_married"
        className={`pro-input lg  ${
          getFieldError("sibling_details.brothers_married") && " error"
        }`}
        {...formik.getFieldProps("sibling_details.brothers_married")}
        error={getFieldError("sibling_details.brothers_married")}
        errorMessage={getFieldError("sibling_details.brothers_married")}
      />

      <Input
        label={"No. of Sisters"}
        id="sibling_details[no_of_sisters]"
        type="text"
        name="sibling_details[no_of_sisters]"
        className={`pro-input lg  ${
          getFieldError("sibling_details.no_of_sisters") && " error"
        }`}
        {...formik.getFieldProps("sibling_details[no_of_sisters]")}
        error={getFieldError("sibling_details.no_of_sisters")}
        errorMessage={getFieldError("sibling_details.no_of_sisters")}
      />

      <Input
        label={"No. of Sisters married"}
        id="sibling_details[sisters_married]"
        type="text"
        name="sibling_details[sisters_married]"
        className={`pro-input lg  ${
          getFieldError("sibling_details.sisters_married") && " error"
        }`}
        {...formik.getFieldProps("sibling_details[sisters_married]")}
        error={getFieldError("sibling_details.sisters_married")}
        errorMessage={getFieldError("sibling_details.sisters_married")}
      />

      <Input
        label={"No. of Priests"}
        id="no_of_priests"
        type="text"
        name="no_of_priests"
        className={`pro-input lg  ${
          getFieldError("no_of_priests") && " error"
        }`}
        {...formik.getFieldProps("no_of_priests")}
        error={getFieldError("no_of_priests")}
        errorMessage={getFieldError("no_of_priests")}
      />

      <Input
        label={"No. of Nuns"}
        id="no_of_nuns"
        type="text"
        name="no_of_nuns"
        className={`pro-input lg  ${getFieldError("no_of_nuns") && " error"}`}
        {...formik.getFieldProps("no_of_nuns")}
        error={getFieldError("no_of_nuns")}
        errorMessage={getFieldError("no_of_nuns")}
      />

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEditProfile ? "Update" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Family;
