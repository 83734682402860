import { getAxiosInstance } from "../../../../api";

export const getMemberCreationData = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get("admin/members/create");

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const createMember = async (formData) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/members/create", formData);

    return response;
  } catch (error) {
    return error.response.data;
  }
};


