import { useDispatch, useSelector } from "react-redux";
import {
  useGetReportedListDataQuery,
  useUpdateReportedListDataMutation,
} from "../../../store/queries/members";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Users/reportedSlice";

import { getFormatedDate } from "../../../utils/functions/table";
import { useMemo, useState } from "react";
import { addDays } from "date-fns";
import { SelectStatusButton } from "../../Global/SelectStatusButton";
import { getExportedData } from "./api";
import moment from "moment";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { useSearchParams } from "react-router-dom";

const useReported = () => {
  const dispatch = useDispatch();
  const [showBlockedModal, setShowBlockedModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const reportedState = useSelector((state) => state.reported);
  const { showEditModal } = useSelector((state) => state.global);

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });
  const [params] = useSearchParams();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const [updateReportedFields] = useUpdateReportedListDataMutation();

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: reportedList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetReportedListDataQuery({
    page_size: reportedState.currentPageSize,
    page: reportedState.currentPage,
    sort_by: reportedState.sortBy,
    sort_order: reportedState.sortOrder,
    start: reportedState.startDate,
    end: reportedState.endDate,
    search: reportedState.search,
  });

  const hasExportPermission = useMemo(() => {
    let permission = reportedList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [reportedList]);

  const hasBlockPermission = useMemo(() => {
    let permission = reportedList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_block")
    );

    return permission?.[0]?.can_block;
  }, [reportedList]);

  const hasDeletePermission = useMemo(() => {
    let permission = reportedList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? 0;
  }, [reportedList]);

  const hasViewPermission = useMemo(() => {
    let permission = reportedList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [reportedList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };
  const handleSort = (label) => {
    if (reportedState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = reportedState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = null;
        state.endDate = null;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
      })
    );
  };

  const options = [
    { value: 1, label: "Block" },
    { value: 0, label: "Delete" },
  ];

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      action: (feild, data) => (
        <SelectStatusButton
          options={options?.filter((item) =>
            hasBlockPermission === 1
              ? item
              : item.value === 1 && hasDeletePermission === 1
              ? item
              : item.value === 0
          )}
          type={"reported"}
          data={data}
          // label1={"Block"}
          // label2={"Delete"}
          setShowDeleteModal={setShowDeleteModal}
          setShowBlockedModal={setShowBlockedModal}
          setSelectedUser={setSelectedUser}
        />
      ),
      reported_by: (_, data) => (
        <ProfileNavigation
          userId={data?.user_id}
          fieldValue={data?.reported_by}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={hasViewPermission === 1 ? true : false}
        />
      ),
      reported_user: (_, data) => (
        <ProfileNavigation
          userId={data?.member_id}
          fieldValue={data?.reported_user}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={hasViewPermission === 1 ? true : false}
        />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(reportedList?.data?.fields),
        filter: reportedState.currentFilter,
        sort_by: reportedState.sortBy,
        sort_order: reportedState.sortOrder,
        start: reportedState.startDate,
        end: reportedState.endDate,
        search: reportedState.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );

        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const closeActionModal = () => {
    setShowBlockedModal(() => false);
  };
  const closeDeleteActionModal = () => {
    setShowDeleteModal(() => false);
  };

  const handleSelectedUser = (id) => {
    setSelectedUser(id?.[0]);
  };

  return {
    reportedList,
    reportedState,
    currentPage: reportedState.currentPage,
    paginationOptions,
    isFetching,
    selectedUser,
    hasExportPermission,
    handleSelectedUser,
    isLoading,
    actionOptions,
    showBlockedModal,
    showDeleteModal,
    closeDeleteActionModal,
    setShowBlockedModal,
    closeActionModal,
    handleActionChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    updateReportedFields,
    handleClearClick,
    date,
  };
};

export default useReported;
