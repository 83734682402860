import { useSearchParams } from "react-router-dom";
import { useGetOverviewDataQuery } from "../../../store/queries/Profile";
import { updateFormData } from "../../../store/slices/Users/createMemberSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { updateConfig } from "../../../store/slices/Users/membersSlice";
import { getVerificationData } from "../../../store/slices/Users/verificationSlice";

const useOverview = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const [showModal, setShowModal] = useState(false);

  const verificationState = useSelector((state) => state.membersVerification);

  const [showImageVerificationModal, setShowImageVerificationModal] =
    useState(false);

  const [showDocumentVerificationModal, setShowDocumentVerificationModal] =
    useState(false);

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetOverviewDataQuery({
    user_id: activeProfile,
  });

  const hasUpdatePermission = useMemo(() => {
    let permission = profileData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [profileData]);

  const hasUploadsViewPermission = useMemo(() => {
    let permission = profileData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_uploads_view")
    );

    return permission?.[0]?.can_uploads_view ?? 0;
  }, [profileData]);

  const hasVerifyPermission = useMemo(() => {
    let permission = profileData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [profileData]);

  const educationLabel = {
    education_cat: "Education Category",
    education: "Education Details",
    institution: "Institution",
  };

  const educationValue = {
    education_cat: `${
      profileData?.data?.data?.education_category?.name ?? "Not specified"
    }`,
    education: `${profileData?.data?.data?.education?.name ?? "Not specified"}`,
    institution: `${
      profileData?.data?.data?.institution_name ?? "Not specified"
    }`,
  };

  const educationDetails = Object.keys(educationValue).map((key) => {
    return {
      label: educationLabel[key],
      value:
        typeof educationValue[key] === "string"
          ? educationValue?.[key]
          : typeof educationValue[key] === "number"
          ? educationValue?.[key]
          : educationValue?.[key]?.name,
    };
  });

  const occupationLabel = {
    occupation_cat: "Occupation Category",
    occupation: "Occupation Details",
    work_location: "Work Location",
    income: "Annual Income",
  };

  const workDistrict = profileData?.data?.data?.work_district?.name ?? "";
  const workState = profileData?.data?.data?.work_state?.name ?? "";
  const workCountry = profileData?.data?.data?.work_country?.name ?? "";

  const workAddressFields = [workDistrict, workState, workCountry];
  const workAddressStr = workAddressFields.filter(Boolean).join(", ");

  const occupationValue = {
    occupation_cat: `${
      profileData?.data?.data?.occupation_category?.name ?? "Not specified"
    }`,
    occupation: `${
      profileData?.data?.data?.occupation?.name ?? "Not specified"
    }`,
    work_location: workAddressStr || "Not specified",
    income: `${
      profileData?.data?.data?.annual_income?.value ?? "Not specified"
    } `,
  };

  const occupationDetails = Object.keys(occupationValue).map((key) => {
    return {
      label: occupationLabel[key],
      value:
        typeof occupationValue[key] === "string"
          ? occupationValue?.[key]
          : typeof occupationValue[key] === "number"
          ? occupationValue?.[key]
          : occupationValue?.[key]?.name,
    };
  });

  const numberOrdinals = { 1: "", 0: "", all: "s" };

  const getOrdinalNumbers = (no) => {
    return `${numberOrdinals?.[no] ?? numberOrdinals["all"]}`;
  };

  const familyLabel = {
    father_name: "Father's Name",
    father_occupation: "Father's Occupation",
    mother_name: "Mother's Name",
    mother_occupation: "Mother's Occupation",
    fam_status: "Family Status",
    fam_type: "Family Type",
    no_of_brothers: `No of Brother${getOrdinalNumbers(
      profileData?.data?.data?.sibling_details?.no_of_brothers ?? 1
    )} `,
    no_of_brothers_married: `Brother${getOrdinalNumbers(
      profileData?.data?.data?.sibling_details?.no_of_brothers ?? 1
    )} Married`,
    no_of_sisters: `No of Sister${getOrdinalNumbers(
      profileData?.data?.data?.sibling_details?.no_of_sisters ?? 1
    )}`,
    no_of_sisters_married: `Sister${getOrdinalNumbers(
      profileData?.data?.data?.sibling_details?.no_of_sisters ?? 1
    )} Married`,
  };

  const familyValue = {
    father_name: `${
      profileData?.data?.data?.father_details?.name ?? "Not specified"
    }`,
    father_occupation: `${
      profileData?.data?.data?.father_details?.occupation?.name ??
      "Not specified"
    }`,
    mother_name: `${
      profileData?.data?.data?.mother_details?.name ?? "Not specified"
    }`,
    mother_occupation: `${
      profileData?.data?.data?.mother_details?.occupation?.name ??
      "Not specified"
    }`,
    fam_status: `${
      profileData?.data?.data?.family_status?.name ?? "Not specified"
    }`,
    fam_type: `${
      profileData?.data?.data?.family_type?.name ?? "Not specified"
    }`,
    no_of_brothers: `${
      profileData?.data?.data?.sibling_details?.no_of_brothers ??
      "Not specified"
    }`,
    no_of_brothers_married: `${
      profileData?.data?.data?.sibling_details?.brothers_married ??
      "Not specified"
    }`,
    no_of_sisters: `${
      profileData?.data?.data?.sibling_details?.no_of_sisters ?? "Not specified"
    }`,
    no_of_sisters_married: `${
      profileData?.data?.data?.sibling_details?.sisters_married ??
      "Not specified"
    }`,
  };

  const familyDetails = Object.keys(familyValue).map((key) => {
    return {
      label: familyLabel[key],
      value:
        typeof familyValue[key] === "string"
          ? familyValue?.[key]
          : typeof familyValue[key] === "number"
          ? familyValue?.[key]
          : familyValue?.[key]?.name,
    };
  });

  const contactLabel = {
    present_address: "Present Address",
    permanent_address: "Permanent Address",
    contact_person_1: "Contact Person 1",
    contact_person_2: "Contact Person 2",
    contact_person_3: "Contact Person 3",
  };

  const presentAddress = profileData?.data?.data?.present_address;
  const permanentAddress = profileData?.data?.data?.permanent_address;
  const communicationDetails = profileData?.data?.data?.communication_details;

  const presentHouseName = presentAddress?.house_name ?? "";
  const presentStreet = presentAddress?.street ?? "";
  const presentDistrict = presentAddress?.district?.name ?? "";
  const presentPincode = presentAddress?.pincode ?? "";

  const permanentHouseName = permanentAddress?.house_name ?? "";
  const permanentStreet = permanentAddress?.street ?? "";
  const permanentDistrict = permanentAddress?.district?.name ?? "";
  const permanentPincode = permanentAddress?.pincode ?? "";

  const communicationName1 = communicationDetails?.[0]?.name ?? "";
  const communicationCode1 = communicationDetails?.[0]?.country_code ?? "";
  const communicationPhone1 = communicationDetails?.[0]?.phone_number ?? "";
  const communicationRelation1 =
    communicationDetails?.[0]?.relationship?.name ?? "";

  const communicationName2 = communicationDetails?.[1]?.name ?? "";
  const communicationCode2 = communicationDetails?.[1]?.country_code ?? "";
  const communicationPhone2 = communicationDetails?.[1]?.phone_number ?? "";
  const communicationRelation2 =
    communicationDetails?.[1]?.relationship?.name ?? "";

  const communicationName3 = communicationDetails?.[2]?.name ?? "";
  const communicationCode3 = communicationDetails?.[2]?.country_code ?? "";
  const communicationPhone3 = communicationDetails?.[2]?.phone_number ?? "";
  const communicationRelation3 =
    communicationDetails?.[2]?.relationship?.name ?? "";

  const presentAddressFields = [
    presentHouseName,
    presentStreet,
    presentDistrict,
    presentPincode,
  ];
  const permanentAddressFields = [
    permanentHouseName,
    permanentStreet,
    permanentDistrict,
    permanentPincode,
  ];

  const contactDetailsFields1 = [
    communicationName1,
    communicationCode1 + "" + communicationPhone1,
    communicationRelation1,
  ];

  const contactDetailsFields2 = [
    communicationName2,
    communicationCode2 + "" + communicationPhone2,
    communicationRelation2,
  ];

  const contactDetailsFields3 = [
    communicationName3,
    communicationCode3 + "" + communicationPhone3,
    communicationRelation3,
  ];

  const presentAddressStr = presentAddressFields.filter(Boolean).join(", ");
  const permanentAddressStr = permanentAddressFields.filter(Boolean).join(", ");
  const contactPerson1Str = contactDetailsFields1.filter(Boolean).join(", ");
  const contactPerson2Str = contactDetailsFields2.filter(Boolean).join(", ");
  const contactPerson3Str = contactDetailsFields3.filter(Boolean).join(", ");

  const contactValue = {
    present_address: presentAddressStr || "Not specified",

    permanent_address: permanentAddressStr || "Not specified",
    contact_person_1: contactPerson1Str || "Not specified",
    contact_person_2: contactPerson2Str || "Not specified",
    contact_person_3: contactPerson3Str || "Not specified",
  };

  const contactDetails = Object.keys(contactValue).map((key) => {
    return {
      label: contactLabel[key],
      value:
        typeof contactValue[key] === "string"
          ? contactValue?.[key]
          : typeof contactValue[key] === "number"
          ? contactValue?.[key]
          : contactValue?.[key]?.name,
    };
  });

  const headerLabel = {
    // profile_views: "Profile Views",
    interest: "Interest Send",
    contact_viewed: "Contact Viewed",
  };

  const HeaderValue = {
    // profile_views: `${profileData?.data?.data?.profile_views?.count ?? ""}/ ${
    //   profileData?.data?.data?.profile_views?.total ?? ""
    // } `,
    interest: `${profileData?.data?.data?.interests?.count ?? "0"} / ${
      profileData?.data?.data?.interests?.total ?? "0"
    }`,
    contact_viewed: `${profileData?.data?.data?.contacts?.count ?? "0"} / ${
      profileData?.data?.data?.contacts?.total ?? "0"
    }`,
  };

  const headerDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });
  const handleEduClick = () => {
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Edu";
        state.isEditProfile = true;
        state.selectedUser = activeProfile;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const handleAboutClick = () => {
    // setShowModal(true);
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Basic";
        state.isEditProfile = true;
        state.selectedUser = activeProfile;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleJobClick = () => {
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Job";
        state.isEditProfile = true;
        state.selectedUser = activeProfile;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const handleFamilyClick = () => {
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Family";
        state.isEditProfile = true;
        state.selectedUser = activeProfile;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const handleContactClick = () => {
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Contact";
        state.isEditProfile = true;
        state.selectedUser = activeProfile;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const onProfileVerifyClick = () => {
    setShowImageVerificationModal(true);
    dispatch(getVerificationData({ user_id: activeProfile, upload_type: 1 }));
  };
  const onProfileIdVerifyClick = () => {
    setShowDocumentVerificationModal(true);
    dispatch(getVerificationData({ user_id: activeProfile, upload_type: 2 }));
  };

  const closeModal = () => {
    setShowImageVerificationModal(false);
  };
  const closeDocumentModal = () => {
    setShowDocumentVerificationModal(false);
  };

  const filterByStatus = (data) => {
    return data?.filter((item) => item.status !== 1 && item.status !== 2);
  };

  const ImageVerifyStatus = filterByStatus(
    profileData?.data?.data?.profile_images
  );
  const IdVerifyStatus = filterByStatus(profileData?.data?.data?.id_proofs);

  return {
    profileData: profileData?.data,
    educationDetails,
    occupationDetails,
    contactDetails,
    familyDetails,
    headerDetails,
    isFetching,
    isSuccess,
    isError,
    showModal,
    activeProfile,
    showImageVerificationModal,
    showDocumentVerificationModal,
    verificationState,
    ImageVerifyStatus,
    IdVerifyStatus,
    hasUpdatePermission,
    hasVerifyPermission,
    hasUploadsViewPermission,
    setShowDocumentVerificationModal,
    setShowImageVerificationModal,
    closeModal,
    closeDocumentModal,
    refetch,
    handleCloseModal,
    handleEduClick,
    handleAboutClick,
    handleJobClick,
    handleFamilyClick,
    handleContactClick,
    onProfileVerifyClick,
    onProfileIdVerifyClick,
  };
};

export default useOverview;
