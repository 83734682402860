import React from "react";
import ProfileVisitedChart from "./Chart";
import useActivity from "./useActivity";
import { ActivityMonitor } from "./ActivityMonitor";
import {
  // HeadingGroup,
  IconText,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import ActivityGraphShimmer from "../../Global/Shimmers/ActivityGraphShimmer";
import FilterDays from "./FilterDays";
import ActivityMonitorShimmer from "../../Global/Shimmers/ActivityMonitorShimmer";
import EmptyData from "../../Global/EmptyData";

const Activity = () => {
  const {
    chartData,
    state,
    activeProfile,
    value,
    showScroll,
    selectedChart,
    setSelectedChart,
    setCurrent,
    status,
    handleChange,
    current,
    profileActivityState,
    lastElement,
    handleScrollToTop,
    setValue,
  } = useActivity();

  return (
    <div className="pro-m-4 pro-mx-5">
      {state?.activityChartStatus === "pending" ? (
        <div className="row row-cols-3">
          <div>
            <ActivityGraphShimmer />
          </div>
          <div>
            <ActivityGraphShimmer />
          </div>
          <div>
            <ActivityGraphShimmer />
          </div>
        </div>
      ) : (
        <div className="row row-cols-3">
          {chartData?.map((data, i) => {
            return (
              <div>
                <ProfileVisitedChart
                  index={i}
                  state={state}
                  title={data?.title}
                  datasets={data?.datasets}
                  activeProfile={activeProfile}
                  value={value}
                  setValue={setValue}
                  setCurrent={setCurrent}
                  current={current}
                  selectedChart={selectedChart}
                  setSelectedChart={setSelectedChart}
                />
              </div>
            );
          })}
        </div>
      )}

      {state?.activityStatus === "pending" && !profileActivityState?.data ? (
        <>
          <ActivityMonitorShimmer />
        </>
      ) : (
        <>
          {showScroll && (
            <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
              <span className="material-symbols-outlined">expand_less</span>
            </button>
          )}
          <div className="pro-d-flex pro-justify-between pro-mt-6">
            <h5 className="pro-ttl pro-mb-0">Activity History</h5>
            <FilterDays status={status} handleChange={handleChange} />
          </div>
          {profileActivityState?.data?.length > 1 ? (
            <>
              {profileActivityState?.data?.map((data, i) => {
                const timestamp = data?.data?.[0]?.date;
                const date = new Date(timestamp);
                const formattedTime = date.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                });
                const formattedDate = date.toLocaleDateString("en-US");

                const uniqueData = data?.data?.filter(
                  (item, index, self) =>
                    index === self.findIndex((t) => t.name === item.name)
                );

                return (
                  <>
                    {data?.type === "signed_in" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            login
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Signed in at ${formattedTime}`}
                      ></ActivityMonitor>
                    ) : data?.type === "profile_views" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        icon={
                          <span className="material-symbols-outlined">
                            {" "}
                            visibility{" "}
                          </span>
                        }
                        title={`Viewed ${
                          uniqueData?.length === 1 ? `a` : uniqueData?.length
                        }  user profile`}
                        children={
                          <>
                            {uniqueData?.slice(0, 4)?.map((item, index) => {
                              return (
                                <>
                                  {index < 3 ? (
                                    <span className="pro-p-1 pro-pe-4">
                                      <IconText
                                        title={`${item?.name}`}
                                        icon={
                                          <>
                                            {item?.profile_image ? (
                                              <div className={`pro-avatar`}>
                                                <img
                                                  src={`${item?.profile_image}`}
                                                  alt={`viewed-${index}`}
                                                />
                                              </div>
                                            ) : (
                                              <span className="material-symbols-outlined x3">
                                                {" "}
                                                account_circle{" "}
                                              </span>
                                            )}
                                          </>
                                        }
                                      ></IconText>
                                    </span>
                                  ) : (
                                    <span className="pro-p-1 pro-pe-4">
                                      +{`${uniqueData?.length - 3} more`}
                                    </span>
                                  )}
                                </>
                              );
                            })}
                          </>
                        }
                      ></ActivityMonitor>
                    ) : data?.type === "reported" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            report
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Reported ${
                          uniqueData?.length === 1 ? `a` : uniqueData?.length
                        }  user profile`}
                      ></ActivityMonitor>
                    ) : data?.type === "photo_requested" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Requested ${
                          uniqueData?.length === 1 ? `a` : uniqueData?.length
                        }  user profile image`}
                      ></ActivityMonitor>
                    ) : data?.type === "profile_images_uploads" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            publish
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Uploaded ${
                          uniqueData?.length === 1 ? `a` : uniqueData?.length
                        } new images to profile`}
                        children={
                          <>
                            {uniqueData?.map((item, index) => {
                              return (
                                <span className="avatar-sqr">
                                  <Image
                                    src={`${item?.profile_image}`}
                                    alt={`uploads-${index}`}
                                    width={24}
                                    height={24}
                                  />
                                </span>
                              );
                            })}
                          </>
                        }
                      ></ActivityMonitor>
                    ) : data?.type === "interest_sent" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Showed interest on ${
                          uniqueData?.length === 1 ? `a` : uniqueData?.length
                        } user profiles`}
                        children={
                          <>
                            {uniqueData?.slice(0, 4)?.map((item, index) => {
                              return (
                                <>
                                  {index < 3 ? (
                                    <span className="pro-p-1 pro-pe-4">
                                      <IconText
                                        title={`${item?.name}`}
                                        icon={
                                          <>
                                            {item?.profile_image ? (
                                              <div className={`pro-avatar`}>
                                                <img
                                                  src={`${item?.profile_image}`}
                                                  alt={`viewed-${index}`}
                                                />
                                              </div>
                                            ) : (
                                              <span className="material-symbols-outlined x3">
                                                {" "}
                                                account_circle{" "}
                                              </span>
                                            )}
                                          </>
                                        }
                                      ></IconText>
                                    </span>
                                  ) : (
                                    <span className="pro-p-1 pro-pe-4">
                                      +{`${uniqueData?.length - 3} more`}
                                    </span>
                                  )}
                                </>
                              );
                            })}
                          </>
                        }
                      ></ActivityMonitor>
                    ) : data?.type === "interest_rejected" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Interest Rejected on ${
                          uniqueData?.length === 1 ? `a` : uniqueData?.length
                        } user profiles`}
                        children={
                          <>
                            {uniqueData?.slice(0, 4)?.map((item, index) => {
                              return (
                                <>
                                  {index < 3 ? (
                                    <span className="pro-p-1 pro-pe-4">
                                      <IconText
                                        title={`${item?.name}`}
                                        icon={
                                          <>
                                            {item?.profile_image ? (
                                              <div className={`pro-avatar`}>
                                                <img
                                                  src={`${item?.profile_image}`}
                                                  alt={`viewed-${index}`}
                                                />
                                              </div>
                                            ) : (
                                              <span className="material-symbols-outlined x3">
                                                {" "}
                                                account_circle{" "}
                                              </span>
                                            )}
                                          </>
                                        }
                                      ></IconText>
                                    </span>
                                  ) : (
                                    <span className="pro-p-1 pro-pe-4">
                                      +{`${uniqueData?.length - 3} more`}
                                    </span>
                                  )}
                                </>
                              );
                            })}
                          </>
                        }
                      ></ActivityMonitor>
                    ) : data?.type === "favourite" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Favorited ${
                          uniqueData?.length === 1 ? `a` : uniqueData?.length
                        } user profiles`}
                        children={
                          <>
                            {uniqueData?.slice(0, 4)?.map((item, index) => {
                              return (
                                <>
                                  {index < 3 ? (
                                    <span className="pro-p-1 pro-pe-4">
                                      <IconText
                                        title={`${item?.name}`}
                                        icon={
                                          <>
                                            {item?.profile_image ? (
                                              <div className={`pro-avatar`}>
                                                <img
                                                  src={`${item?.profile_image}`}
                                                  alt={`viewed-${index}`}
                                                />
                                              </div>
                                            ) : (
                                              <span className="material-symbols-outlined x3">
                                                {" "}
                                                account_circle{" "}
                                              </span>
                                            )}
                                          </>
                                        }
                                      ></IconText>
                                    </span>
                                  ) : (
                                    <span className="pro-p-1 pro-pe-4">
                                      +{`${uniqueData?.length - 3} more`}
                                    </span>
                                  )}
                                </>
                              );
                            })}
                          </>
                        }
                      ></ActivityMonitor>
                    ) : data?.type === "contact_viewed" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Viewed ${
                          uniqueData?.length === 1
                            ? `a contact`
                            : uniqueData?.length + " contacts"
                        }  `}
                      ></ActivityMonitor>
                    ) : data?.type === "member_blocked" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            block
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Blocked ${
                          uniqueData?.length === 1
                            ? `a profile`
                            : uniqueData?.length + " profiles"
                        }  `}
                      ></ActivityMonitor>
                    ) : data?.type === "add_ons" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            box_add
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Purchased an add on package to the existing ‘Gold’ package`}
                      ></ActivityMonitor>
                    ) : data?.type === "packages" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            package
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`Purchased a new  package`}
                      ></ActivityMonitor>
                    ) : data?.type === "package_expiry" ? (
                      <ActivityMonitor
                        className={`pro-py-4`}
                        icon={
                          <span className="material-symbols-outlined">
                            work_history
                          </span>
                        }
                        index={i}
                        lastElement={
                          i === profileActivityState?.data?.length - 5
                            ? lastElement
                            : false
                        }
                        title={`package  expired on ${formattedDate} `}
                      ></ActivityMonitor>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <EmptyData />
          )}
        </>
      )}

      {state?.activityStatus === "pending" && profileActivityState?.data && (
        <ActivityMonitorShimmer idle={false} />
      )}
    </div>
  );
};

export default Activity;
