import { forwardRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCrop = forwardRef(({ image, crop, setCrop }, ref) => {

  return (
    <>
      <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
        <img ref={ref} src={image} alt="crop" crossOrigin="anonymous" />
      </ReactCrop>
    </>
  );
});

export default ImageCrop;
