const usePreferenceForm = ({ formik, optionsData }) => {
  const selectedCountryIds = formik.values.country?.map(
    (country) => country.unique_country_id
  );

  const filteredStateOptions = optionsData?.state?.filter((state) =>
    selectedCountryIds.includes(state.country_id)
  );

  filteredStateOptions?.map((state) => ({
    ...state,
    selectedCountryIds: selectedCountryIds?.filter(
      (id) => id === state.state_id
    ),
  }));

  const selectedStateIds = formik.values.state.map(
    (country) => country.unique_state_id
  );

  const filteredDistrictOptions = optionsData?.district?.filter((state) =>
    selectedStateIds.includes(state.state_id)
  );

  filteredDistrictOptions?.map((state) => ({
    ...state,
    selectedStateIds: selectedStateIds?.filter((id) => id === state.state_id),
  }));

  const selectedCommunities = formik.values.community || []; // Array of selected community IDs

  const filteredDioceses = optionsData?.community?.flatMap((community) => {
    if (selectedCommunities.includes(community?._id)) {
      return community.dioceses;
    }
    return [];
  });

  return { filteredStateOptions, filteredDistrictOptions, filteredDioceses };
};

export default usePreferenceForm;
