import {
  MultiColumnTable,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "./profilePreview.module.scss";
import profileStyle from "../../../../pages/Layouts/ProfileLayout/profileLayout.module.scss";
import useProfilePreview from "./useProfilePreview";
import Assets from "../../../../assets/Assets";

const ProfilePreview = ({ profileData }) => {
  const { educationDetails, occupationDetails, familyDetails, contactDetails } =
    useProfilePreview({ profileData });


  return (
    <>
      <div className={`${Style.root} pro-pb-4`}>
        <div className={Style.img_wrap}>
          <figure className="pro-mb-0">
            <img
              src={
                profileData?.data?.data?.profile_images?.length > 0
                  ? profileData?.data?.data?.profile_images?.filter(
                      (item) => item?.is_primary === 1
                    )?.[0]?.url ??
                    profileData?.data?.data?.profile_images?.[0]?.url
                  : profileData?.data?.data?.gender?.name === "Female"
                  ? Assets.FEMALEPROFILE
                  : Assets.MALEPROFILE
              }
              alt={`pic-${profileData?.data?.data?.first_name}`}
            />
          </figure>
        </div>
        <div className="pro-ps-4 pro-d-flex pro-flex-column">
          <span
            className={`${Style.isPremium} pro-py-1 pro-px-3 pro-font-sm`}
            style={{ fontVariationSettings: ` 'FILL' 1` }}
          >
            <span className="material-symbols-outlined">star</span>
            <span>Gold</span>
          </span>
          {profileData?.first_name && (
            <h4 className="pro-ttl h4 pro-mb-0">
              {profileData?.first_name + " " + profileData?.last_name}
            </h4>
          )}
          {/* {<p className="pro-mb-0">{role}</p>} */}
          {/* <ul className={`pro-d-flex pro-align-center ${Style.list}`}>
            <li>{profile_id}</li>
            <li>{branch}</li>
          </ul> */}
          <SimpleTextDetails
            isOnline={true}
            title={`${profileData?.data?.data?.first_name ?? ""} ${
              profileData?.data?.data?.last_name ?? ""
            } ${
              profileData?.data?.data?.age
                ? `(${profileData?.data?.data?.age})`
                : ""
            }`}
            uId={profileData?.data?.data?.profile_id ?? ""}
            lastOnline={
              profileData?.data?.data?.last_login &&
              `Last login: ${profileData?.data?.data?.last_login ?? ""}`
            }
            extraClassNames={`pro-mt-1 pro-d-flex pro-flex-column pro-gap-2 ${profileStyle.profile_name_wrap}`}
          />
        </div>
      </div>

      <div className={`${Style.overview_wrap_list_item} pro-my-5`}>
        <MultiColumnTable
          title={"About"}
          data={[
            {
              label: `${profileData?.data?.data?.about_me ?? "Not specified"}`,
              value: "",
            },
          ]}
          // extraClassNames={`$Style.multi_column_table`}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>

      <div className={`col-12 pro-py-4 ${Style.overview_wrap_list_item}`}>
        <MultiColumnTable
          minWidth={`30%`}
          title={"Education Details"}
          data={educationDetails}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>

      <div className={`col-12 pro-py-4 ${Style.overview_wrap_list_item}`}>
        <MultiColumnTable
          minWidth={`30%`}
          title={"Job Details"}
          data={occupationDetails}
          // extraClassNames={Style.multi_column_table}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>

      <div className={`col-12 pro-py-4 ${Style.overview_wrap_list_item}`}>
        <MultiColumnTable
          minWidth={`30%`}
          title={"Family Details"}
          data={familyDetails}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>

      <div className={`col-12 pro-py-4 ${Style.overview_wrap_list_item}`}>
        <MultiColumnTable
          minWidth={`30%`}
          title={"Contact Details"}
          data={contactDetails}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>
    </>
  );
};

export default ProfilePreview;
