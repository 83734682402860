import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBasic from "./useBasic";
import RowOne from "./RowOne";
import RowTwo from "./RowTwo";
import RowThree from "./RowThree";

const Basic = ({
  optionsData,
  isOptionDataSuccess,
  isUpdateDataSuccess,
  isEditProfile,
  className,
  profileRefetch,
  isStickyFooter,
  selectedUser,
}) => {
  const {
    currentFormData,
    profilefileInputRef,
    formik,
    phoneVerified,
    cuntryCodeOptions,
    handlePhoneNumberChange,
    handleCloseModal,
    activeImageIndex,
    handleThumbClick,
    showPassword,
    handleShowPassword,
    dobRef,
    otp,
    parishRef,
    dioceseRef,
    imagePreview,
    handleProfileImage,
    getFieldError,
    handleVerifyPhone,
    handleValidatePhone,
  } = useBasic({
    optionsData,
    isOptionDataSuccess,
    isUpdateDataSuccess,
    isEditProfile,
    profileRefetch,
    selectedUser,
  });

 

  return (
    <div className={className}>
      {/**
       * First name
       * Last name
       * Mobile number
       * OTP
       * Email address
       * Password
       * Gender
       */}
      <RowOne
        dobRef={dobRef}
        formik={formik}
        otp={otp}
        handlePhoneNumberChange={handlePhoneNumberChange}
        getFieldError={getFieldError}
        optionsData={optionsData}
        phoneVerified={phoneVerified}
        isEditProfile={isEditProfile}
        cuntryCodeOptions={cuntryCodeOptions}
        handleVerifyPhone={handleVerifyPhone}
        handleValidatePhone={handleValidatePhone}
        showPassword={showPassword}
        handleShowPassword={handleShowPassword}
      />
      {/**
       * Date of Birth
       * Height
       * Weight
       * Marital Status
       * Number of Children
       * Physical Status
       * Body Type
       * Complexion
       */}
      <RowTwo
        dobRef={dobRef}
        formik={formik}
        getFieldError={getFieldError}
        optionsData={optionsData}
        currentFormData={currentFormData}
      />
      {/**
         * Community
         * Diocese
         * Parish
         * Mother Tongue
         * About myself
         * Photo
         * Primary image
    
         */}
      <RowThree
        parishRef={parishRef}
        dioceseRef={dioceseRef}
        formik={formik}
        getFieldError={getFieldError}
        isEditProfile={isEditProfile}
        optionsData={optionsData}
        currentFormData={currentFormData}
        profilefileInputRef={profilefileInputRef}
        handleProfileImage={handleProfileImage}
        imagePreview={imagePreview}
        activeImageIndex={activeImageIndex}
        handleThumbClick={handleThumbClick}
      />

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          id="button-next"
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEditProfile ? "Update" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Basic;
