import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  phoneVerified: {
    verified: false,
    status: false,
  },
};

const blockedSlice = createSlice({
  name: "blocked",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = blockedSlice.actions;

export default blockedSlice.reducer;
