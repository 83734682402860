import {
  Button,
  Input,
  IconText,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import Style from "./staffCreation.module.scss";
import useStaffCreationForm from "./useStaffCreationForm";
import { limitStrLength } from "../../../../utils/functions/table";

const StaffCreationForm = ({
  refetch,
  hasUpdatePermission,
  isStickyFooter,
}) => {
  const {
    formik,
    optionsData,
    imagePreview,
    genderOptionsData,
    handleProfileImage,
    branchOptionsData,
    selectedItemsDetails,
    // isOptionDataSuccess,
    cuntryCodeOptions,
    isLoading,
    profilefileInputRef,
    handleCloseModal,
  } = useStaffCreationForm({ refetch });

  if (selectedItemsDetails !== "") {
    var pathname = new URL(imagePreview)?.pathname;
    var filename = pathname?.split("/")?.pop();
  }

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"First Name*"}
        type="text"
        id="first_name"
        name="first_name"
        className={`pro-input lg ${
          formik.errors.first_name && formik.touched.first_name && " error"
        }`}
        {...formik.getFieldProps("first_name")}
        error={formik.touched.first_name && formik.errors.first_name}
        errorMessage={formik.errors.first_name}
      />
      <Input
        label={"Last Name*"}
        type="text"
        id="last_name"
        name="last_name"
        className={`pro-input lg ${
          formik.errors.last_name && formik.touched.last_name && " error"
        }`}
        {...formik.getFieldProps("last_name")}
        error={formik.touched.last_name && formik.errors.last_name}
        errorMessage={formik.errors.last_name}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Gender*
        </label>
        <Select
          id="gender"
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.errors.gender && formik.touched.gender && " error"
          }`}
          classNamePrefix="pro-input"
          options={genderOptionsData?.data?.gender}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={genderOptionsData?.data?.gender?.filter(
            (m) => formik.values.gender === m?.id
          )}
          onBlur={formik.handleBlur("gender")}
          onChange={(value) => {
            formik.setFieldValue("gender", value?.id || null);
          }}
        />

        {formik.errors.gender && formik.touched.gender && (
          <span className="error-text">{formik.errors.gender}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <div className="input-country-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Phone Number*
          </label>
          <div className="input-main-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="country_code"
                placeholder="91+"
                isDisabled={true}
                options={cuntryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                className={`country-select multi-select ${
                  formik.touched.country_code &&
                  formik.errors.country_code &&
                  " error"
                }`}
                value={cuntryCodeOptions?.filter(
                  (p) =>
                    p.value?.toString() ===
                    formik.values.country_code?.toString()
                )}
                classNamePrefix="pro-input"
                onBlur={() => formik.handleBlur("country_code")}
                onChange={(value) =>
                  formik.setFieldValue("country_code", value?.value)
                }
              ></Select>
            </div>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              className={`pro-input lg ${
                formik.errors.phone_number &&
                formik.touched.phone_number &&
                " error"
              }`}
              {...formik.getFieldProps("phone_number")}
            />
          </div>
          {formik.touched.phone_number && formik.errors.phone_number && (
            <span className="error-text ">{formik.errors.phone_number}</span>
          )}
          {formik.touched.country_code && formik.errors.country_code && (
            <span className="error-text">{formik.errors.country_code}</span>
          )}
        </div>
      </div>
      <Input
        label={"Email*"}
        type="email"
        id="email"
        name="email"
        placeholder="name@example.com"
        className={`pro-input lg ${
          formik.errors.email && formik.touched.email && " error"
        }`}
        {...formik.getFieldProps("email")}
        onClick={() => formik.setFieldTouched("email", true)}
        error={formik.touched.email && formik.errors.email}
        errorMessage={formik.errors.email}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Role*
        </label>

        <Select
          id="role_id"
          options={optionsData?.data}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => `${option["_id"]}`}
          className={`pro-input lg multi-select ${
            formik.touched.role_id && formik.errors.role_id && " error"
          }`}
          value={optionsData?.data?.filter(
            (p) => p._id?.toString() === formik.values.role_id?._id?.toString()
          )}
          classNamePrefix="pro-input"
          onBlur={() => formik.handleBlur("role_id")}
          onChange={(value) => formik.setFieldValue("role_id", value)}
        ></Select>
        {formik.touched.role_id && formik.errors.role_id && (
          <span className="error-text">{formik.errors.role_id}</span>
        )}
      </div>
      {formik.values.role_id?.name === "Staff Admin" ||
      formik.values.role_id?.name === "Staff" ? (
        <>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Branch
            </label>
            <Select
              id="branch"
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik.touched.branch && formik.errors.branch && " error"
              }`}
              classNamePrefix="pro-input"
              options={branchOptionsData?.data?.branch}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              value={branchOptionsData?.data?.branch?.filter(
                (m) => formik.values.branch?._id === m?._id
              )}
              onBlur={formik.handleBlur("branch")}
              onChange={(value) => {
                formik.setFieldValue("branch", value || null);
              }}
            />
            {formik.touched.branch && formik.errors.branch && (
              <span className="error-text">{formik.errors.branch}</span>
            )}{" "}
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Photo*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.errors.profile && formik.touched.profile && " error"
            }`}
            id="profile"
            name="profile"
            onBlur={formik.handleBlur("profile")}
            onChange={(e) => handleProfileImage(e)}
          />

          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.profile?.name !== undefined
                  ? limitStrLength(formik?.values?.profile?.name, 30)
                  : selectedItemsDetails !== ""
                  ? filename
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.profile && formik.errors.profile && (
            <span className="error-text">{formik.errors.profile}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imagePreview}
            alt={`image - 01`}
          />
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${
            isLoading ? "loading" : ""
          }`}
          disabled={hasUpdatePermission === 1 ? false : true}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default StaffCreationForm;
