import React from "react";
import Select from "react-select";

const SecondPart = ({
  formik,
  optionsData,
  filteredStateOptions,
  filteredDistrictOptions,
}) => {


  
  return (
    <>
      <div className="col-12">
        <div className="row row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4">
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="employed_in"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Employed in
              </label>
              <Select
                id="employment_sector"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.employment_sector}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.employment_sector?.filter((m) =>
                  formik.values.employment_sector?.includes?.(m.id)
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "employment_sector",
                    value.map((v) => v.id)
                  )
                }
              />
            </div>
          </div>
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="physical_status"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Physical Status
              </label>
              <Select
                id="physical_status"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.physical_status}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.physical_status?.filter((m) =>
                  formik.values.physical_status?.includes?.(m.id)
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "physical_status",
                    value.map((v) => v.id)
                  )
                }
              />
            </div>
          </div>

          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="body_type"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Body type
              </label>
              <Select
                id="body_type"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.body_type}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.body_type?.filter((m) =>
                  formik.values.body_type?.includes?.(m?.id)
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "body_type",
                    value.map((v) => v?.id)
                  )
                }
              />
            </div>
          </div>
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="family_status"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Family status
              </label>
              <Select
                id="family_status"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.family_status}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.family_status?.filter((m) =>
                  formik.values.family_status?.includes?.(m.id)
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "family_status",
                    value.map((v) => v.id)
                  )
                }
              />
            </div>
          </div>


          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="current_location"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Country
              </label>
              <Select
                id="country"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.country}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.country?.filter((m) => {
                  return formik.values.country?.[0]?._id
                    ? formik.values.country?.includes?.(m)
                    : formik.values.country?.includes?.(m?._id);
                })}
                onChange={(value) =>
                  formik.setFieldValue(
                    "country",
                    value.map((v) => v)
                  )
                }
              />
            </div>
          </div>

          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="current_location"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                State
              </label>
              <Select
                id="state"
                isDisabled={formik.values?.country?.length > 0 ? false : true}
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={filteredStateOptions}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.state?.filter((m) => {
                  return formik.values.state?.[0]?._id
                    ? formik.values.state?.includes?.(m)
                    : formik.values.state?.includes?.(m?._id);
                })}
                onChange={(value) =>
                  formik.setFieldValue(
                    "state",
                    value.map((v) => v)
                  )
                }
              />
            </div>
          </div>

          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="current_location"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                District
              </label>
              <Select
                id="district"
                isDisabled={formik.values?.state?.length > 0 ? false : true}
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={filteredDistrictOptions}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.district?.filter((m) =>
                  formik.values.district?.includes?.(m?._id)
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "district",
                    value.map((v) => v?._id)
                  )
                }
              />
            </div>
          </div>

          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="occupation"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Occupation
              </label>
              <Select
                id="occupation"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.occupation}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.occupation?.filter((m) =>
                  formik.values.occupation?.includes?.(m._id?.toString?.())
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "occupation",
                    value.map((v) => v._id?.toString?.())
                  )
                }
              />
            </div>
          </div>
          
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="education"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Education
              </label>
              <Select
                id="education"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.education}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.education?.filter((m) =>
                  formik.values.education?.includes?.(m._id?.toString?.())
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "education",
                    value.map((v) => v._id?.toString?.())
                  )
                }
              />
            </div>
          </div>
          {/* <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="citizenship"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Citizenship
              </label>
              <Select
                id="citizenship"
                placeholder={"Select"}
                isClearable={true}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                // options={optionsData.heightList?.filter(
                //   (p) => p?.height_value > formik.values.height_from
                // )}
                // getOptionValue={(option) => option.height_value}
                // getOptionLabel={(option) => option.height}
                // value={optionsData.heightList?.filter(
                //   (m) =>
                //     formik.values.height_to?.toString?.() ===
                //     m.height_value?.toString?.()
                // )}
                // onChange={(value) =>
                //   formik.setFieldValue("height_to", value?.height_value || null)
                // }
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SecondPart;
