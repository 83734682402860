import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import PackageCreationForm from "../PackageCreationForm";
import useAdons from "./useAdons";
import EmptyData from "../../Global/EmptyData";

const Adons = () => {
  const {
    adons,
    showCreateModal,
    hasCreatePermission,
    hasEditPermission,
    hasUpdatePermission,
    isFetching,
    isLoading,
    actionOptions,
    hasExportPermission,
    handleActionChange,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    handleEditAddonField,
    closeEditModal,
    showEditModal,
    tableFields,
    updateAdonsFields,
    adonsState,
    refetch,
    currentPage,
    paginationOptions,
    handlePagination,
    handlePageSize,
    handleClearClick,
  } = useAdons();

  return (
    <>
      <HeadingGroup
        title={"Add-ons"}
        buttonTitle={hasCreatePermission === 1 ? "Create" : ""}
        className={`pro-mb-4`}
        handleClick={handleCreateClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: adonsState?.search }}
          actionOptions={actionOptions?.filter((item) =>
            hasExportPermission !== 1 ? item.value === 0 : true
          )}
          onActionOptionChange={handleActionChange}
        />
        {adons?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={adons?.data?.data?.data || []}
            uniqueID={"_id"}
            //fields={adons?.data?.fields}
            clear={adonsState.clearSelection}
            deletable={false}
            assignable={false}
            showCheckBox={hasEditPermission === 1 ? true : false}
            multiSelect={false}
            fields={tableFields}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            handleEdit={handleEditAddonField}
            getRow={getRow}
            loading={isFetching}
          />
        )}

        {adons?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={adons?.data?.data?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === adonsState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          centered={false}
          show={showCreateModal}
          handleClose={closeModal}
          title={
            adonsState?.selectedItemsDetails === null
              ? "Create add-on package"
              : "Edit add-on package"
          }
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <PackageCreationForm
            refetch={refetch}
            hasUpdatePermission={hasUpdatePermission}
          />
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              //refetchData={refetch}
              tableFields={adons?.data?.fields}
              moduleId={adons?.data?.module_id}
              updateData={updateAdonsFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Adons;
